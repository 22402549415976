import * as _ from 'lodash';

import {
  Schema,
  ValidationError
} from 'jsonschema';
import {
  checkAgainstSchema,
  nonEmptyStringSchema,
  booleanSchema
} from '../util/schema';



const firebaseSchema: Schema = {
  type: 'object',
  properties: {
    apiKey: nonEmptyStringSchema,
    authDomain: nonEmptyStringSchema,
    databaseURL: nonEmptyStringSchema,
    projectId: nonEmptyStringSchema,
    storageBucket: nonEmptyStringSchema,
    messagingSenderId: nonEmptyStringSchema,
    appId: nonEmptyStringSchema
  },
  required: [
    'apiKey',
    'authDomain',
    'databaseURL',
    'projectId',
    'storageBucket',
    'messagingSenderId',
    'appId'
  ]
};

const apiSchema: Schema = {
  type: 'object',
  properties: {
    url: nonEmptyStringSchema,
    graphqlPath: nonEmptyStringSchema
  },
  required: [
    'url',
    'graphqlPath'
  ]
};

const stripeSchema: Schema = {
  type: 'object',
  properties: {publishableKey: nonEmptyStringSchema},
  required: ['publishableKey']
};

const environmentSchema: Schema = {
  type: 'object',
  properties: {
    isProd: booleanSchema,
    markerIODestination: nonEmptyStringSchema,
    environmentName: nonEmptyStringSchema,
    firebase: firebaseSchema,
    api: apiSchema,
    stripe: stripeSchema
  },
  required: [
    'isProd',
    'markerIODestination',
    'environmentName',
    'firebase',
    'api',
    'stripe'
  ]
};

export interface IEnvironment {
  isProd: boolean;
  markerIODestination: string;
  environmentName: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  api: {
    url: string;
    graphqlPath: string;
  };
  stripe: {
    publishableKey: string;
  }
}

let __environment: IEnvironment;



export function init(): Promise<IEnvironment> {

  return new Promise<IEnvironment>((resolve, reject) => {
    const env = process.env.REACT_APP_APP_ENV;
    if (!env) {
      throw new Error('Error finding REACT_APP_APP_ENV.');
    }

    const environment = JSON.parse(env);
    const validationErrors: ValidationError[] = [];
    if (!checkAgainstSchema(environment, environmentSchema, validationErrors)) {
      console.error(_.repeat('*', 80));
      console.error("ENVIRONMENT CONFIG FILE CONTENTS ARE INVALID!");

      const errors = _.reduce(validationErrors, (accumulator, validationError) => {
        accumulator[validationError.property] = validationError.message;

        return accumulator;
      }, {} as _.Dictionary<string>);
      console.table(errors);
      console.error(_.repeat('*', 80));

      reject(new Error(JSON.stringify(errors)));
    }

    __environment = environment;

    resolve(environment);
  });
}



export function getConfig() {
  if (!__environment) {
    throw new Error('ENVIRONMENT_NOT_INITIALIZED');
  }

  return __environment;
}
