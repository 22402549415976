import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { Select } from 'components/shared/form/Select/Select';
import { CreatableSelect } from 'components/shared/form/Select/CreatableSelect';
import { ImageInput } from 'components/shared/form/ImageInput/ImageInput';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { NumberInput } from 'components/shared/form/TextInput/NumberInput';
import { Checkbox } from 'components/shared/form/Checkbox/Checkbox';
import { MultipleImageInput } from 'components/shared/form/MultipleImageInput/MultipleImageInput';
import { IBrandFormSectionProps } from '../IBrandFormSectionProps';
import * as optionsUtil from 'util/options';

import styles from './BrandInfoFormSection.module.css';



export interface IBrandInfoFormSectionProps extends IBrandFormSectionProps {
  availableVolumeTypes: optionsUtil.IOption[];
  availableCategories: optionsUtil.IOption[];

  onCreateCategory: (name: string) => void;
}

export const BrandInfoFormSection: React.FC<IBrandInfoFormSectionProps> = props => {
  function handleCreateCategory(value: string) {
    props.onCreateCategory(value);
  }

  return (
    <>
      <SectionTitle>
        About the Brand
      </SectionTitle>

      <div className={styles.threeColumnRow}>
        <TextInput
          required
          ref={_.partial(Form.captureElement, props.refForm, 'name')}
          value={props.formValues.name}
          onChangeValue={name => props.onChangeFormValue({name})}
          label="BRAND NAME" />

        <TextInput
          required
          ref={_.partial(Form.captureElement, props.refForm, 'style')}
          value={props.formValues.style}
          onChangeValue={style => props.onChangeFormValue({style})}
          label="BRAND STYLE" />

        <CreatableSelect
          required
          isMulti
          ref={_.partial(Form.captureElement, props.refForm, 'category')}
          options={props.availableCategories}
          value={optionsUtil.getOptionsFromValues(props.availableCategories, props.formValues.category)}
          onChange={options => props.onChangeFormValue({category: optionsUtil.getValuesFromOptions(options)})}
          onCreateOption={handleCreateCategory}
          label="BRAND CATEGORY" />
      </div>

      <div className={styles.threeColumnRow}>
        <NumberInput
          required
          step={1}
          ref={_.partial(Form.captureElement, props.refForm, 'ibu')}
          value={props.formValues.ibu.toString()}
          onChangeValue={ibu => props.onChangeFormValue({ibu: _.toNumber(ibu)})}
          label="IBU" />

        <NumberInput
          required
          step={0.01}
          max={100}
          ref={_.partial(Form.captureElement, props.refForm, 'abv')}
          value={props.formValues.abv.toString()}
          onChangeValue={abv => props.onChangeFormValue({abv: _.toNumber(abv)})}
          suffix="%"
          label="ABV" />

        <TextInput
          ref={_.partial(Form.captureElement, props.refForm, 'untappdId')}
          value={props.formValues.untappdId}
          onChangeValue={untappdId => props.onChangeFormValue({untappdId})}
          label="UNTAPPD ID" />
      </div>

      <div className={styles.threeColumnRow}>
        <Select
          required
          ref={_.partial(Form.captureElement, props.refForm, 'volumeAvailability')}
          options={props.availableVolumeTypes}
          value={optionsUtil.getOptionFromValue(props.availableVolumeTypes, props.formValues.volumeAvailability)}
          onChange={option => props.onChangeFormValue({volumeAvailability: optionsUtil.getValueFromOption(option)})}
          label="VOLUME AVAILABILITY" />

        <div
          className={styles.uniqueContainer}
          style={{
            flexGrow: 0,
            flexBasis: 180
          }}>
          <label className={styles.label}>BEVNOMAD BRAND</label>

          <div className={styles.unique}>
            <Checkbox
              checked={props.formValues.uniqueStyle}
              onChangeValue={uniqueStyle => props.onChangeFormValue({uniqueStyle})} />
          </div>
        </div>
      </div>

      <div className={styles.threeColumnRow}>
        <ImageInput
          required
          size={150}
          label="BRAND PROFILE IMAGE (RECOMMENDED SIZE: 150 X 150)"
          imageUrl={props.formValues.brandImage}
          onChange={brandImage => props.onChangeFormValue({brandImage})} />

        <ImageInput
          required
          size={[375, 150]}
          fit="cover"
          label="BRAND BANNER IMAGE (RECOMMENDED SIZE: 1200 X 600)"
          imageUrl={props.formValues.bannerImage}
          onChange={bannerImage => props.onChangeFormValue({bannerImage})} />
      </div>
      <div className={styles.threeColumnRow}>

        <MultipleImageInput
          label="BRAND IMAGES & GRAPHICS (RECOMMENDED SIZE: 500 X 500, MAXIMUM 6 FILES)"
          max={6}
          imageUrls={props.formValues.graphics}
          onChange={graphics => props.onChangeFormValue({graphics})} />

        <TextInput
          required
          ref={_.partial(Form.captureElement, props.refForm, 'description')}
          value={props.formValues.description}
          maxLength={160}
          onChangeValue={description => props.onChangeFormValue({description})}
          label="DESCRIPTION (160 CHARACTER LIMIT)" />
      </div>
    </>
  );
};
