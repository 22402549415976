import React from 'react';
import _ from 'lodash';

import { Table } from 'components/shared/Table/Table';
import { Button } from 'components/shared/Button/Button';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { Select } from 'components/shared/form/Select/Select';
import { Pagination } from 'components/shared/Table/Pagination/Pagination';
import { Switch } from 'components/shared/form/Switch/Switch';
import * as optionsUtil from 'util/options';
import { IOrder } from '../dataService';

import styles from './OrdersList.module.css';
import { DateRangeSelectField } from 'components/shared/DateRangeSelectField/DateRangeSelectField';
import { DebouncedSearchBox } from 'components/shared/DebouncedSearchBox/DebouncedSearchBox';



export interface OrderFilter {
  searchText?: string;
  pageNumber?: number;
  pageSize?: number;
}

export interface IOrdersListProps {
  data: IOrder[];
  pageCount: number;
  currentPage: number;

  defaultSearchText?: string;
  onSetPageNumber: (page: number) => void;
  onSetSearchText: (text: string) => void;
  onSetDateFilterOption: (value: string) => void;
  onSetDateTuple: (value: [Date | null, Date | null]) => void;
  onViewDetails: (orderId: string) => void;
  onToggleViewAll: (toggledValue: boolean) => void;
}

export const OrdersList: React.FC<IOrdersListProps> = props => {
  const [sortOption, setSortOption] = React.useState('');
  const [toggledViewAll, setToggledViewAll] = React.useState(false);

  const displayingData = React.useMemo(() => {
    return _.orderBy(props.data, [sortOption], [sortOption === 'orderDate' ? 'desc' : 'asc']);
  }, [props.data, sortOption]);

  return (
    <>
      <TableHeader>
        <DebouncedSearchBox
          defaultSearchText={props.defaultSearchText}
          onSetSearchText={props.onSetSearchText}
          placeholder="Search by brand, retailer or distributor name"
          debounceMillis={600} />

        <div style={{ width: 12 }} />

        <DateRangeSelectField
          onSetDateTuple={props.onSetDateTuple}
          onSetDateFilterOption={props.onSetDateFilterOption} />

        <div
          className={styles.filter}
          style={{width: 180}}>
          <Select
            placeholder="Sort Orders"
            options={__sortOptions}
            value={optionsUtil.getOptionFromValue(__sortOptions, sortOption)}
            onChange={option => setSortOption(optionsUtil.getValueFromOption(option))} />
        </div>

      </TableHeader>

      <Table
        data={displayingData}
        columnDefs={[
          {
            label: "Order Id",
            fnCellContent: d => (
              <div className={styles.brandOrder}>
                <span className={styles.brandOrderId}>{d.readableId}</span>
                {/* {d.isNew && (
                  <div className={styles.newTag}>NEW</div>
                )} */}
              </div>
            )
          },
          {
            label: 'Order Date',
            fnCellContent: d => d.orderDate
          },
          {
            label: 'Retailer',
            fnCellContent: d => d.retailerName
          },
          {
            label: 'Retailer State',
            fnCellContent: d => d.retailerState
          },
          {
            label: 'Order Total',
            fnCellContent: d => (
              <div className={styles.amount}>{d.orderTotal}</div>
            )
          },
          {
            label: 'Distributor',
            fnCellContent: d => d.distributorName
          },
          {
            label: 'View Details',
            width: '80px',
            centered: true,
            fnCellContent: d => (
              <Button
                style={{width: 35}}
                sizeStyle="small"
                colorStyle="secondary"
                onClick={() => props.onViewDetails(d.id)}
                leftIcon="bracketRight">
              </Button>
            )
          }
        ]} />
      <div className={styles.pagination}>
        <Switch
          value={toggledViewAll}
          onChangeValue={value => {
            setToggledViewAll(value);
            props.onToggleViewAll(value);
          }}>
          View All
        </Switch>
        <Pagination
          pageCount={props.pageCount}
          currentPage={props.currentPage}
          goToPage={pageIndex => {
            props.onSetPageNumber(pageIndex);
          }} />
      </div>
    </>
  );
};

const __sortOptions: optionsUtil.IOption[] = [
  {
    label: 'Sort By Order Date',
    value: 'orderDate'
  },
  {
    label: 'Sort By Order Value',
    value: 'value'
  },
  {
    label: 'Sort By Order ID',
    value: 'readableId'
  },
  {
    label: 'Sort By Order Status',
    value: 'statusName'
  }
];
