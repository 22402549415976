import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import styles from './Checkbox.module.css';



export type ICheckboxProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  onChangeValue: (value: boolean) => void;
};

export const Checkbox: React.FC<ICheckboxProps> = props => {
  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(evt);
    }
    props.onChangeValue(evt.target.checked);
  }

  return (
    <div className={classnames([styles.container, props.className])}>
      <label
        className={styles.platform}>
        <input
          {..._.omit(props, [
            'children',
            'onChangeValue'
          ])}
          className={styles.checkbox}
          onChange={handleChange}
          type='checkbox' />

        <span>{props.checked}</span>

        <span className={classnames([styles.checkmark, {[styles.disabled]: props.disabled}])} />

        <span className={styles.label}>
          {props.children}
        </span>
      </label>
    </div>
  );
};
