import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import {
  ModalTemplate,
  IModalTemplateProps
} from 'components/shared/templates/ModalTemplate/ModalTemplate';
import {
  routes,
  getUrlWithQueryString
} from 'routes';

import { useHistory } from 'react-router';
import { SectionTitleWarning } from 'components/shared/SectionTitleWarning/SectionTitleWarning';
import { Button } from 'components/shared/Button/Button';

import * as dataService from '../dataService';

import styles from './ViewNotificationsModal.module.css';



export interface IViewNotificationsModal extends IModalTemplateProps {
  actingWarning: boolean;
  notifications: dataService.INotification[];
}

export const ViewNotificationsModal: React.FC<IViewNotificationsModal> = props => {
  const history = useHistory();

  function handleClick(notification: dataService.INotification) {
    props.onRequestClose();

    if (notification.type === 'newOrder') {
      history.push(`${routes.home.path()}`);
    }
    else if (notification.type === 'orderLineStatusChange') {
      history.push(getUrlWithQueryString(routes.home.path(), {orderId:  notification.orderId ?? ''}));
    }
  }

  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      modalTitle="Notifications">
      <div>
        <div className={styles.notifications}>
          {_.map(props.notifications, (notification, index) => (
            <div
              className={styles.notification}
              key={index}>
              <div className={classnames(styles.notificationText, {[styles.read]: notification.isRead})}>{notification.title}</div>

              <Button
                style={{width: 80}}
                sizeStyle="small"
                colorStyle="secondary"
                onClick={() => handleClick(notification)}>
                View
              </Button>
            </div>
          ))}
        </div>

        {props.actingWarning && (
          <>
            <hr />
            <SectionTitleWarning>As you are acting as a brewery, their notifications will remain unread.</SectionTitleWarning>
          </>
        )}
      </div>
    </ModalTemplate>
  );
};
