import _ from 'lodash';



export function readCsv(data: string, hasHeader?: boolean) {
  if (!_.indexOf(data, 'data:text/csv;base64,')) {
    return [];
  }

  const base64Encoded = _.replace(data, 'data:text/csv;base64,', '');
  const csvString = atob(base64Encoded);
  const lines = _.split(csvString, "\n");
  const headers = _.split(lines[0], ",");

  if (hasHeader) {
    return _.map(_.drop(lines), line => {
      const currentLine = _.split(line, ",");
      return _.fromPairs(_.map(headers, (header, index) => [header, currentLine[index]]));
    });
  }

  return _.map(lines, line => _.split(line, ","));
}



export interface IExportColumnDef<T> {
  label: string;
  value: (datum: T) => string | number;
}

export function makeCsv<TData>(columnDefs: IExportColumnDef<TData>[], data: TData[] | undefined, filename: string) {
  if (_.isNil(data) || _.isEmpty(data)) {
    return;
  }

  const labels = _.join(_.map(columnDefs, def => def.label), ',');
  const values = _.map(data, datum => _.join(_.map(columnDefs, def => {
    const value = def.value(datum);
    return _.isString(value) ? _.replace(value, /[,]/, '') : value;
  }), ','));

  const csvData = "data:text/csv;charset=utf-8," + _.join(_.concat([labels], values), '\r\n');
  const link = document.createElement('a');
  link.setAttribute('href', encodeURI(csvData));
  link.setAttribute('download', `${filename}.csv`);
  link.click();

  return csvData;
}
