import React from 'react';
import _ from 'lodash';

import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Table } from 'components/shared/Table/Table';
import { ConfirmModal } from 'components/shared/modals/ConfirmModal/ConfirmModal';
import { TableActions } from 'components/shared/Table/TableActions/TableActions';
import { Button } from 'components/shared/Button/Button';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { EditAccountModal } from './EditAccountModal/EditAccountModal';

import * as dataService from './dataService';
import {
  availableRetailerChainTypes, availableRetailerTypes
} from '../../../../util/enums';



export const RetailersPage: React.FC = () => {
  const [searchText, setSearchText] = React.useState('');
  const [isDisableModal, setIsDisableModal] = React.useState(false);
  const queryTuple = dataService.useSuperAdminRetailersPageQuery(searchText);
  const [fnToggleLoginPermitted, togglePermittedResult] = dataService.useToggleLoginPermittedMutation();
  const [fnUpdateRetailer, updateRetailerResult] = dataService.useUpdateRetailerInformationMutation();
  const [selectedRetailer, setSelectedRetailer] = React.useState<dataService.IRetailer | null>(null);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showEditAccountModal, setShowEditAccountModal] = React.useState(false);

  const loading = _.some([
    queryTuple,
    togglePermittedResult,
    updateRetailerResult
  ], tuple => tuple.loading);

  function handleConfirmLoginToggle() {
    if (selectedRetailer) {
      fnToggleLoginPermitted(selectedRetailer?.id);
    }
    setShowConfirmModal(false);
  }

  function handleCloseModal() {
    setShowConfirmModal(false);
    setShowEditAccountModal(false);
    setSelectedRetailer(null);
  }

  return (
    <PageTemplate loading={loading}>
      <TableHeader>
        <TableTitle>
          Retailers
        </TableTitle>

        <TextInput
          onChangeValue={setSearchText}
          value={searchText}
          placeholder="Filter by name" />
      </TableHeader>

      <Table
        columnDefs={[
          {
            label: 'Name',
            fnCellContent: d => d.retailerName
          },
          {
            label: 'Email',
            fnCellContent: d => d.emailAddress
          },
          {
            label: 'Mobile Number',
            fnCellContent: d => d.cellNumber
          },
          {
            label: 'Chain Type',
            fnCellContent: d => _.find(availableRetailerChainTypes, type => type.value === d.chainType)?.label ?? d.chainType
          },
          {
            label: 'Address / Purchase States',
            fnCellContent: d => {
              if (_.isEmpty(d.address)) {
                return (d.purchaseStates);
              }
              return (d.address);
            }
          },
          {
            label: 'Type',
            fnCellContent: d => _.find(availableRetailerTypes, type => type.value === d.type)?.label ?? d.type
          },
          {
            label: 'Website',
            fnCellContent: d => d.website
          },
          {
            label: 'Actions',
            width: '100px',
            fnCellContent: d => (
              <TableActions>
                <Button
                  sizeStyle="small"
                  colorStyle="secondary"
                  onClick={() => {
                    setIsDisableModal(d.loginPermitted);
                    setSelectedRetailer(d);
                    setShowConfirmModal(true);
                  }}>
                  {d.loginPermitted && (<>Disable Retailer</>)}
                  {!d.loginPermitted && (<>Enable Retailer</>)}
                </Button>

                <Button
                  sizeStyle="small"
                  colorStyle="secondary"
                  onClick={() => {
                    setSelectedRetailer(d);
                    setShowEditAccountModal(true);
                  }}>
                Edit Account
                </Button>
              </TableActions>
            )
          }
        ]}
        data={queryTuple.data.retailers} />


      <EditAccountModal
        emailAddress={selectedRetailer?.emailAddress ?? null}
        retailer={selectedRetailer?.updateInput ?? null}
        onUpdateRetailer={fnUpdateRetailer}
        isOpen={showEditAccountModal}
        onRequestClose={handleCloseModal} />

      <ConfirmModal
        title="Are you sure?"
        content={isDisableModal ? "Do you wish to disable this account?" : "Do you wish to enable this account?"}
        onConfirmed={handleConfirmLoginToggle}
        isOpen={showConfirmModal && !!selectedRetailer}
        onRequestClose={handleCloseModal} />
    </PageTemplate>
  );
};
