import React from 'react';

import _ from 'lodash';

import imgPlus from 'assets/icons/plus.svg';
import imgBoiler from 'assets/icons/boiler.svg';
import imgBoilerOrange from 'assets/icons/boiler-orange.svg';
import imgBeer from 'assets/icons/beer.svg';
import imgBeerOrange from 'assets/icons/beer-orange.svg';
import imgRetail from 'assets/icons/retail.svg';
import imgRetailOrange from 'assets/icons/retail-orange.svg';
import imgProfile from 'assets/icons/profile.svg';
import imgProfileOrange from 'assets/icons/profile-orange.svg';
import imgCog from 'assets/icons/cog.svg';
import imgCogOrange from 'assets/icons/cog-orange.svg';
import imgQuestionCircle from 'assets/icons/question-circle.svg';
import imgThreeDots from 'assets/icons/three-dots.svg';
import imgThreeDotsH from 'assets/icons/horizontal-three-dots.svg';
import imgFolder from 'assets/icons/folder.svg';
import imgBluePlus from 'assets/icons/blue-plus.svg';
import imgSearch from 'assets/icons/search.svg';
import imgBracketDown from 'assets/icons/bracket-down.svg';
import imgBracketUp from 'assets/icons/bracket-up.svg';
import imgBracketLeft from 'assets/icons/bracket-left.svg';
import imgBracketRight from 'assets/icons/bracket-right.svg';
import imgBracketRightWhite from 'assets/icons/bracket-right-white.svg';



export interface IIconProps {
  type: IIconType;
  className?: string;
  alt?: string;
  size?: number;
}

export const Icon: React.FC<IIconProps> = props => (
  <img
    alt={props.alt}
    className={props.className}
    src={_.get(__iconMap, props.type)}
    style={{
      width: props.size ?? 16,
      height: props.size ?? 16
    }} />
);



const __iconMap = {
  boiler: imgBoiler,
  boilerOrange: imgBoilerOrange,
  beer: imgBeer,
  beerOrange: imgBeerOrange,
  retail: imgRetail,
  retailOrange: imgRetailOrange,
  profile: imgProfile,
  profileOrange: imgProfileOrange,
  cog: imgCog,
  cogOrange: imgCogOrange,
  questionCircle: imgQuestionCircle,
  plus: imgPlus,
  threeDots: imgThreeDots,
  threeDotsH: imgThreeDotsH,
  folder: imgFolder,
  bluePlus: imgBluePlus,
  search: imgSearch,
  bracketDown: imgBracketDown,
  bracketUp: imgBracketUp,
  bracketLeft: imgBracketLeft,
  bracketRight: imgBracketRight,
  bracketRightWhite: imgBracketRightWhite
};

export type IIconType = keyof typeof __iconMap;
