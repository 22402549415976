import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';
import {
  IReportMissingDistributorFormType,
  reportMissingDistributorFormSchema
} from '../dataService';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { validator } from 'util/validation';
import { useAlert } from 'util/hooks';

import styles from './CreateDistributorFormModal.module.css';



export interface ICreateDistributorFormModalProps extends IModalTemplateProps{
  onSubmit: (data: IReportMissingDistributorFormType) => void;
}

export const CreateDistributorFormModal: React.FC<ICreateDistributorFormModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, alertOnError
  } = useAlert();
  const [name, setName] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');

  function handleValidateForm() {
    try {
      validator(reportMissingDistributorFormSchema, {
        name,
        emailAddress
      });
      return true;
    } catch (err) {
      alertOnError(err);
      return false;
    }
  }

  function handleSubmit() {
    props.onSubmit({
      name,
      emailAddress
    });
  }

  return (
    <ModalTemplate
      modalTitle="Report a Missing Distributor"
      onRequestClose={props.onRequestClose}
      isOpen={props.isOpen}>
      <Form
        ref={refForm}
        fnValidateForm={handleValidateForm}
        onSuccessfulSubmit={handleSubmit}>
        <TextInput
          label="DISTRIBUTOR NAME"
          ref={_.partial(Form.captureElement, refForm, 'name')}
          value={name}
          onChangeValue={setName} />

        <EmailInput
          label="DISTRIBUTOR EMAIL"
          ref={_.partial(Form.captureElement, refForm, 'emailAddress')}
          value={emailAddress}
          onChangeValue={setEmailAddress} />

        <Button className={styles.button}>
          Submit
        </Button>
      </Form>

      <AlertModal {...AlertModalProps} />
    </ModalTemplate>
  );
};
