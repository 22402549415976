import _ from 'lodash';

import { PageInfo } from 'generated/apiTypes';



export interface IPagination {
  totalRecords: number;
  pageSize: number;
  offset: number;
  numberOfPages: number;
}



export function pageInfo2Pagination(pageInfo?: Partial<PageInfo>, overridePageSize?: number): IPagination {
  if (_.isNil(pageInfo)) {
    return {
      totalRecords: 0,
      pageSize: 0,
      offset: 0,
      numberOfPages: 0
    };
  }

  const totalRecords = pageInfo.total ?? 0;
  let pageSize = pageInfo.pageSize ?? 5;

  if (overridePageSize) {
    pageSize = overridePageSize;
  }

  if (pageSize <= 0){
    pageSize = Infinity;
  }

  const numberOfPages = _.ceil(totalRecords / pageSize);

  return {
    totalRecords,
    pageSize,
    offset: pageInfo.offset ?? 0,
    numberOfPages//: totalRecords / (pageSize || 1)
  };
}
