import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { Checkbox } from 'components/shared/form/Checkbox/Checkbox';

import styles from './Table.module.css';



export interface ITableColumnDef<TData> {
  label: string;
  width?: string;
  centered?: boolean;

  fnCellContent: (data: TData) => React.ReactNode;
}

export interface ITableProps<TData extends Record<string, unknown>> {
  data: TData[];
  columnDefs: ITableColumnDef<TData>[];
  styleType?: 'normal' | 'noHeader';
  className?: string;
  fnFilter?: (datum: TData) => boolean;
  onUpdateSelection?: (selection: TData[]) => void;
}

export const Table: React.FC<ITableProps<any>> = props => {
  const {
    data, fnFilter
  } = props;
  const refData = React.useRef<unknown[]>();
  const [selection, setSelection] = React.useState<unknown[]>([]);
  const isSelectable = !_.isNil(props.onUpdateSelection);

  const rows = React.useMemo(() => fnFilter ? _.filter(data, fnFilter) : data, [data, fnFilter]);

  const updateSelection = React.useCallback((selected: unknown[]) => {
    if (props.onUpdateSelection) {
      props.onUpdateSelection(selected);
    }
    setSelection(selected);
  }, [props.onUpdateSelection, setSelection]);

  function handleSelectAll() {
    if (_.isEmpty(selection)) {
      updateSelection(rows);
    } else {
      updateSelection([]);
    }
  }

  function handleSelect(rowData: any) {
    updateSelection(_.xorWith(selection, [rowData], _.isEqual));
  }

  React.useEffect(() => {
    refData.current = data;
  });

  React.useEffect(() => {
    if (!_.isEqual(data, refData.current)) {
      updateSelection([]);
      refData.current = data;
    }
  }, [data, refData, updateSelection]);

  return (
    <table className={classnames(styles.table, props.className)}>
      {props.styleType === 'normal' && (
        <thead>
          <tr className={styles.tr}>
            {isSelectable && (
              <th className={classnames(styles.th, styles.selectable)}>
                <Checkbox
                  onChangeValue={() => handleSelectAll()}
                  checked={selection.length === rows.length} />
              </th>
            )}

            {_.map(props.columnDefs, (def, index) => (
              <th
                key={index}
                className={styles.th}
                style={{
                  width: def.width ?? 'auto',
                  textAlign: def.centered ? 'center' : 'left'
                }}>
                {def.label}
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>
        {_.map(rows, (rowData, rowIndex) => (
          <tr
            className={styles.tr}
            key={rowIndex}>
            {isSelectable && (
              <td className={classnames(styles.td, styles.selectable)}>
                <Checkbox
                  onChangeValue={() => handleSelect(rowData)}
                  checked={includesInArray(selection, [rowData])} />
              </td>
            )}

            {_.map(props.columnDefs, (def, colIndex) => (
              <td
                className={styles.td}
                style={{textAlign: def.centered ? 'center' : 'left'}}
                key={colIndex}>
                {def.fnCellContent(rowData)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};



Table.defaultProps = {styleType: 'normal'};



function includesInArray(x: any[], y: any[]) {
  return _.intersectionWith(x, y, _.isEqual).length > 0;
}
