import React from 'react';
import _ from 'lodash';

import {
  GetCurrentUserHook,
  useAlert
} from 'util/hooks';

import { useHistory } from 'react-router';
import { routes } from 'routes';
import { Table } from 'components/shared/Table/Table';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { Button } from 'components/shared/Button/Button';
import { InviteRetailersModal } from './InviteRetailersModal/InviteRetailersModal';
import { RetailerDetailModal } from './RetailerDetailModal/RetailerDetailModal';
import { makeCsv } from 'util/csv';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';

import * as dataService from './dataService';

import styles from './RetailerListPage.module.css';



export const RetailerListPage: React.FC = () => {
  const history = useHistory();
  const currentUserQueryTuple = GetCurrentUserHook();
  const queryTuple = dataService.useMyRetailersPageQuery(currentUserQueryTuple.data.currentUser.breweryIdForQueries);
  const [fnInviteRetailer, inviteRetailerResult] = dataService.useInviteRetailerMutation();
  const [showInviteRetailersModal, setShowInviteRetailersModal] = React.useState(false);
  const [showRetailerDetailsModal, setShowRetailerDetailsModal] = React.useState(false);
  const [selectedRetailerId, setSelectedRetailerId] = React.useState<string | null>(null);
  const {
    AlertModalProps, setAlert
  } = useAlert();

  const selectedRetailer = React.useMemo(() => _.find(queryTuple.data.retailers, retailer => retailer.id === selectedRetailerId), [selectedRetailerId]);

  const loading = _.some([
    currentUserQueryTuple,
    queryTuple,
    inviteRetailerResult
  ], tuple => tuple.loading);

  function handleCloseModal() {
    setSelectedRetailerId(null);
    setShowInviteRetailersModal(false);
    setShowRetailerDetailsModal(false);
  }

  function handleEditRetailer() {
    setShowRetailerDetailsModal(false);
  }

  function handleDeleteRetailer() {
    if (selectedRetailer) {
      console.debug({retailer: selectedRetailer});
    }
    handleCloseModal();
  }

  const handleViewOrders = React.useCallback((retailerName: string) => {
    const searchParams = new URLSearchParams({retailerName});
    history.push(`${routes.home.path()}?${searchParams.toString()}`);
  }, [history]);

  const handleViewDetails = React.useCallback((retailer: dataService.IRetailer) => {
    setSelectedRetailerId(retailer.id);
    setShowRetailerDetailsModal(true);
  }, []);

  function handleExportToCsv() {
    makeCsv(dataService.exportCsvColumnDefs, queryTuple.data.retailers, 'retailers');
  }

  return (
    <PageTemplate
      loading={loading}
      headerTitle={(
        <HeaderTitle
          title="Your Retailers"
          icon="retail">
          <div className={styles.addRetailer}>
            <Button onClick={() => setShowInviteRetailersModal(true)}>
              Invite Retailers
            </Button>

            <Button
              colorStyle="secondary"
              onClick={handleExportToCsv}>
              Download CSV File
            </Button>
          </div>
        </HeaderTitle>
      )}>
      <Table
        data={queryTuple.data.retailers}
        columnDefs={[
          {
            label: 'Retailer Name',
            fnCellContent: d => (
              <div className={styles.retailerName}>
                {d.name}

                {d.hasNewOrder && (
                  <div className={styles.newTag}>NEW ORDER</div>
                )}
              </div>
            )
          },
          {
            label: 'Total Orders',
            fnCellContent: d => (
              <span className={styles.amount}>
                {d.totalOrderValue}
              </span>
            )
          },
          {
            label: 'Last Order Date',
            fnCellContent: d => d.lastOrderDate
          },
          {
            label: 'Last Brands Ordered',
            fnCellContent: d => d.lastBrandsOrdered
          },
          {
            label: 'Last Order Value',
            fnCellContent: d => d.lastOrderValue
          },
          {
            label: 'Purchase States',
            fnCellContent: d => d.purchaseStates
          },
          {
            label: 'View Orders',
            width: '80px',
            centered: true,
            fnCellContent: d => (
              <Button
                style={{width: 35}}
                sizeStyle="small"
                colorStyle="secondary"
                onClick={() => handleViewOrders(d.name)}
                leftIcon="bracketRight" />
            )
          },
          {
            label: 'View Details',
            width: '80px',
            centered: true,
            fnCellContent: d => (
              <Button
                style={{width: 35}}
                sizeStyle="small"
                onClick={() => handleViewDetails(d)}
                leftIcon="bracketRightWhite" />
            )
          }
        ]} />

      <InviteRetailersModal
        retailer={selectedRetailer}
        isOpen={showInviteRetailersModal}
        onRequestClose={() => setShowInviteRetailersModal(false)}
        onSendInvite={(retailerEmails: string[]) => {
          fnInviteRetailer({
            breweryId: currentUserQueryTuple.data.currentUser.breweryIdForQueries,
            retailerEmails
          });
          setShowInviteRetailersModal(false);
          setAlert('Invites have been sent.');
        }} />

      <RetailerDetailModal
        retailer={selectedRetailer}
        isOpen={showRetailerDetailsModal}
        onEditRetailer={handleEditRetailer}
        onDeleteRetailer={handleDeleteRetailer}
        onRequestClose={handleCloseModal} />

      <AlertModal {...AlertModalProps} />
    </PageTemplate>
  );
};
