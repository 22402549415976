import React from 'react';

import styles from './TableHeader.module.css';



export const TableHeader: React.FC = props => (
  <div className={styles.container}>
    {props.children}
  </div>
);
