import {
  useAcceptEmployeeInviteMutation as useBaseAcceptEmployeeInviteMutation,
  useGetInviteQuery as useBaseGetInviteQuery,
  AcceptEmployeeInviteInput
} from 'generated/apiTypes';



export function useGetInviteQuery(inviteId: string) {
  const tuple = useBaseGetInviteQuery({variables: {inviteId}});

  return {
    ...tuple,
    data: {
      inviteId: tuple.data?.invite?.id ?? '',
      email: tuple.data?.invite?.email ?? '',
      firstName: tuple.data?.invite?.firstName ?? '',
      lastName: tuple.data?.invite?.lastName ?? ''
    }
  };
}



export function useAcceptEmployeeInviteMutation() {
  const [
    fn,
    tuple
  ] = useBaseAcceptEmployeeInviteMutation();

  const fnWrappedMutation = (input: IAcceptEmployeeInviteInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}

export type IAcceptEmployeeInviteInput = AcceptEmployeeInviteInput;
