import React from 'react';
import _ from 'lodash';
import * as yup from 'yup';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { Select } from 'components/shared/form/Select/Select';
import { MultipleTextInput } from 'components/shared/form/MultipleTextInput/MultipleTextInput';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { Button } from 'components/shared/Button/Button';
import { CreateDistributorFormModal } from './CreateDistributorFormModal/CreateDistributorFormModal';
import { getStateNameOptions } from 'util/stateNames';
import { validator } from 'util/validation';
import * as optionsUtil from 'util/options';
import * as hooksUtil from 'util/hooks';
import { useToasts } from 'react-toast-notifications';

import * as dataService from './dataService';

import styles from './DistributorsListPage.module.css';



export const DistributorsListPage: React.FC = () => {
  const { addToast } = useToasts();
  const currentUserQueryTuple = hooksUtil.GetCurrentUserHook();
  const {
    AlertModalProps, alertOnError
  } = hooksUtil.useAlert();
  const refForm = React.useRef<Form>(null);
  const distributorsQueryTuple = hooksUtil.useAllDistributorsQuery(currentUserQueryTuple.data.currentUser.breweryIdForQueries);
  const queryTuple = dataService.useMyDistributorsQuery(currentUserQueryTuple.data.currentUser.breweryIdForQueries);
  const [fnUpdateDistros, updateDistroTuple] = dataService.useUpdateMyDistributorsMutation(currentUserQueryTuple.data.currentUser.breweryIdForQueries);
  const [fnSendMissingDistro, sendMissingDistroTuple] = dataService.useSendMissingDistributorMutation(currentUserQueryTuple.data.currentUser.breweryIdForQueries, {
    firstName: currentUserQueryTuple.data?.currentUser.firstName ?? '',
    lastName: currentUserQueryTuple.data?.currentUser.lastName ?? '',
    cellNumber: currentUserQueryTuple.data?.currentUser.cellNumber ?? '',
    emailAddress: currentUserQueryTuple.data?.currentUser.emailAddress ?? '',
    breweryName: currentUserQueryTuple.data?.currentUser.breweryName ?? '',
    breweryState: currentUserQueryTuple.data?.currentUser.breweryState ?? ''
  });
  const [showNewDistributorModal, setShowNewDistributorModal] = React.useState(false);
  const [selfDistributionStates, setSelfDistributionStates] = React.useState<string[]>([]);
  const [distributors, setDistributors] = React.useState<dataService.IDistributorType[]>([
    {
      id: '',
      contactEmails: [],
      states: []
    }
  ]);

  const isLoading = _.some([
    queryTuple,
    updateDistroTuple,
    sendMissingDistroTuple,
    distributorsQueryTuple
  ], tuple => tuple.loading);

  function handleChangeEmails(index: number, value: string[]) {
    const cloned = _.cloneDeep(distributors);
    cloned[index] = {
      ...distributors[index],
      contactEmails: value
    };
    setDistributors(cloned);
  }

  function handleUpdateState(index: number, state: string) {
    const cloned = _.cloneDeep(distributors);
    cloned[index] = {
      states: [state],
      id: '',
      contactEmails: []
    };
    setDistributors(cloned);
  }

  function handleChangeDistributorId(index: number, id: string) {
    const cloned = _.cloneDeep(distributors);
    cloned[index] = {
      ...distributors[index],
      id,
      contactEmails: _.find(distributorsQueryTuple.data.availableDistributorInfo, distro => distro.id === id)?.contactEmails ?? []
    };
    setDistributors(cloned);
  }

  async function handleUpdateDistros() {
    try{
      await fnUpdateDistros({
        breweryId: currentUserQueryTuple.data.currentUser.actingAs.breweryId ?? currentUserQueryTuple.data.currentUser.breweryId,
        distributors: _.map(__filterEmptyDistributors(distributors), distro => ({
          id: distro.id,
          contactEmails: distro.contactEmails,
          states: distro.states
        })),
        selfDistributionStates
      });

      addToast('Distributors saved!', {appearance: 'success'});
    }
    catch(err) {
      addToast(err, {appearance: 'error'});
    }
  }

  async function handleReportMissingDistributor(data: dataService.IReportMissingDistributorFormType) {
    await fnSendMissingDistro(data);
    setShowNewDistributorModal(false);
  }

  function getDistributorsByState(stateShortCode: string) {
    const distroInState = _.filter(distributorsQueryTuple.data.availableDistributorInfo, info => _.includes(info.distributionStates, stateShortCode));
    return _.map(distroInState, distro => {
      return {
        value: distro.id,
        label: distro.name
      };
    });
  }

  function handleValidateForm() {
    try {
      const filteredDistributors = __filterEmptyDistributors(distributors);

      validator(dataService.distributorsFormSchema, {
        selfDistributionStates,
        distributors: filteredDistributors
      });
      setDistributors(filteredDistributors);
      return true;
    } catch (err) {
      if ('errors' in err) {
        alertOnError(err);
      }
      return false;
    }
  }

  function handleAddDistributor() {
    setDistributors(state => _.concat(state, [
      {
        id: '',
        states: [],
        contactEmails: []
      }
    ]));
  }

  function handleRemoveDistributor(index: number) {
    setDistributors(state => _.filter(state, (item, i) => i !== index));
  }

  hooksUtil.useEffectUntil(() => {
    if ((_.isEmpty(distributors) && !_.isEmpty(queryTuple.data.distributors)) || (_.isEmpty(_.first(distributors)?.id) && !_.isEmpty(queryTuple.data.distributors))) {
      setDistributors(queryTuple.data.distributors);
    }

    if ((_.isEmpty(selfDistributionStates) && !_.isEmpty(queryTuple.data.selfDistributionStates))) {
      setSelfDistributionStates(queryTuple.data.selfDistributionStates);
    }
  }, [
    queryTuple,
    distributors,
    setDistributors,
    selfDistributionStates,
    setSelfDistributionStates
  ], () => queryTuple.called && !queryTuple.loading);

  return (
    <PageTemplate
      loading={isLoading}
      headerTitle={(
        <HeaderTitle
          title="My Distributors"
          icon="profile" />
      )}>

      <Form
        ref={refForm}
        extraClassName={styles.form}
        fnValidateForm={handleValidateForm}
        onSuccessfulSubmit={handleUpdateDistros}>

        <div className={styles.selfDistributingStates}>
          <Select
            isMulti
            label="WE SELF-DISTRIBUTE TO THESE STATES"
            placeholder="We do not self distribute"
            options={getStateNameOptions()}
            value={optionsUtil.getOptionsFromValues(getStateNameOptions(), selfDistributionStates) as any}
            onChange={options => setSelfDistributionStates(optionsUtil.getValuesFromOptions(options))} />
        </div>

        {_.map(distributors, (distributor, index) => (
          <div
            className={styles.inputRow}
            key={index}>
            <Select
              key={`distributor-state-${index}`}
              label="DISTRIBUTOR STATE"
              options={getStateNameOptions()}
              value={optionsUtil.getOptionFromValue(getStateNameOptions(), _.first(distributor.states) ?? '')}
              onChange={option => handleUpdateState(index, optionsUtil.getValueFromOption(option))} />

            {!_.isEmpty(distributor.states) && (
              <Select
                key={`distributor-name-${index}`}
                label="DISTRIBUTOR NAME"
                value={optionsUtil.getOptionFromValue(getDistributorsByState(_.first(distributor.states) ?? ''), distributor.id)}
                options={getDistributorsByState(_.first(distributor.states) ?? '')}
                onChange={option => handleChangeDistributorId(index, optionsUtil.getValueFromOption(option))} />
            )}

            <MultipleTextInput
              disabled
              label="CONTACT EMAILS"
              fnValidateEach={value => yup.string().email().required().validateSync(value)}
              onChangeValues={values => handleChangeEmails(index, values)}
              values={distributor.contactEmails} />

            <Button
              type='button'
              className={styles.removeButton}
              colorStyle='form'
              onClick={() => handleRemoveDistributor(index)}>
              Remove
            </Button>
          </div>
        ))}

        <div className={styles.buttons}>
          <Button type="submit">
            Save
          </Button>

          <Button
            type='button'
            colorStyle='form'
            leftIcon='plus'
            onClick={() => setShowNewDistributorModal(true)}>
            REPORT A MISSING DISTRIBUTOR
          </Button>

          <Button
            type='button'
            colorStyle='form'
            leftIcon='plus'
            onClick={handleAddDistributor}>
            ADD DISTRIBUTOR
          </Button>
        </div>

      </Form>

      <AlertModal {...AlertModalProps} />

      <CreateDistributorFormModal
        onSubmit={handleReportMissingDistributor}
        isOpen={showNewDistributorModal}
        onRequestClose={() => setShowNewDistributorModal(false)} />
    </PageTemplate>
  );
};



function __filterEmptyDistributors(distributors: dataService.IDistributorType[]) {
  return _.filter(distributors, distributor => !(_.isEmpty(distributor.states) || _.isEmpty(distributor.id)));
}
