import React from 'react';
import _ from 'lodash';

import { StatusLabel } from 'components/shared/StatusLabel/StatusLabel';
import { IBrand } from '../dataService';

import styles from './BrandCard.module.css';



export interface IBrandCardProps {
  brand: IBrand;

  styleType: 'normal' | 'sub';
  onClick: () => void;
  onEditBrand: () => void;
}

export const BrandCard: React.FC<IBrandCardProps> = props => {
  return (
    <div
      className={styles.container}
      onClick={props.onClick}>
      <img
        width={320}
        height={180}
        className={styles.image}
        src={props.brand.imageUri}
        alt={`Brand ${props.brand.name}`} />

      <div className={styles.content}>
        <div className={styles.title}>
          {props.brand.name}
        </div>

        {props.styleType === 'normal' && (<StatusLabel listed={props.brand.listed} />)}
        {props.styleType === 'sub' && (
          <div className={styles.subtitle}>
            Click here to subscribe
          </div>
        )}

        <div className={styles.subtitle}>
          {_.head(props.brand.categories)}
        </div>

        {!_.isEmpty(props.brand.untappdId) && (
          <div className={styles.untappd}>
          Untappd Id: {props.brand.untappdId}
          </div>
        )}

      </div>
    </div>
  );
};
