import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { StatusLabel } from 'components/shared/StatusLabel/StatusLabel';
import { Button } from 'components/shared/Button/Button';
import { StatusTag } from 'components/shared/StatusTag/StatusTag';
import { Switch } from 'components/shared/form/Switch/Switch';
import { IBrand } from '../dataService';

import styles from './BrandInfoModal.module.css';



export interface IBrandInfoModalProps extends IModalTemplateProps {
  brand: IBrand | undefined;

  canAlterListingStatus: boolean;
  onViewOrders: () => void;
  onChangeListedStatus: (listed: boolean) => void;
  onEditBrand: () => void;
  onRemoveBrand: () => void;
}

export const BrandInfoModal: React.FC<IBrandInfoModalProps> = props => {
  return props.brand ? (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={{content: {padding: 0}}}>
      <div
        className={styles.header}
        style={{backgroundImage: `url(${props.brand.imageUri})`}}>
        <div className={styles.categories}>
          {_.map(props.brand.categories, (category, index) => (
            <StatusTag key={index}>
              {category}
            </StatusTag>
          ))}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.title}>
          {props.brand.name}
        </div>

        <div className={styles.subtitle}>
          {/* Last Ordered: {props.brand.lastOrdered} */}
        </div>

        <Switch
          disabled={!(props.canAlterListingStatus && props.brand.canListOnMarketplace)}
          value={props.brand.listed}
          onChangeValue={value => props.onChangeListedStatus(value)}>
          Make Available on Marketplace
        </Switch>

        <div className={styles.status}>
          <StatusLabel listed={props.brand.listed} />
        </div>
      </div>

      <div className={styles.buttons}>
        <Button onClick={props.onViewOrders}>
          View Orders
        </Button>

        <Button onClick={props.onEditBrand}>
          Edit Brand
        </Button>

        <Button onClick={props.onRemoveBrand}>
          Remove Brand
        </Button>
      </div>

      {!_.isEmpty(props.brand.untappdId) && (
        <div className={styles.untappd}>
          Untappd Id: {props.brand.untappdId}
        </div>
      )}
    </ModalTemplate>
  ) : null;
};
