import React from 'react';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Button } from 'components/shared/Button/Button';
import styles from './ImportUntappdProfileModal.module.css';

import imgUntappdIcon from 'assets/untappd-icon.png';



export interface IImportUntappdProfileModalProps extends IModalTemplateProps {
  onStartImport: () => void;
}

export const ImportUntappdProfileModal: React.FC<IImportUntappdProfileModalProps> = props => {
  const refForm = React.useRef<Form>(null);

  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}>
      <div className={styles.modalHeader}>
        <img
          className={styles.untappdIcon}
          src={imgUntappdIcon}
          alt="untappd icon" />

        <h2 className={styles.title}>Import from Untappd</h2>
      </div>

      <p className={styles.description}>
        When you import brands from your Untappd Account, they will be created on your BevNomad account.
        These brands will be unlisted.
        <br />
        <br />
        Notes:
        <ul>
          <li>Brands that have been imported in the past will have their Untappd score updated.</li>
          <li>Brands cannot be merged.</li>
          <li>Importing from untappd might take a while, please do not leave this page.</li>
        </ul>
      </p>

      <Form
        extraClassName={styles.form}
        ref={refForm}
        onSuccessfulSubmit={props.onStartImport}>
        <Button
          className={styles.saveButton}
          type="submit">
            Import from Untappd
        </Button>
      </Form>
    </ModalTemplate>
  );
};
