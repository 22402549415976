import * as yup from 'yup';

import {
  useRegisterBreweryMutation as useBaseRegisterBreweryMutation,
  RegisterBreweryInput
} from 'generated/apiTypes';
import {
  phoneNumberSchema,
  stateNameSchema,
  confirmPasswordSchema
} from 'util/validation';



export function useRegisterBreweryMutation() {
  const [
    fn,
    tuple
  ] = useBaseRegisterBreweryMutation();

  const fnWrappedMutation = (args: IRegisterBreweryInput) => {
    const input: RegisterBreweryInput = {
      firstName: args.firstName,
      lastName: args.lastName,
      emailAddress: args.emailAddress,
      cellNumber: args.cellNumber,
      breweryName: args.breweryName,
      breweryState: args.breweryState,
      untappd: args.untappd ?? '',
      password: args.password
    };

    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export const missingDistributorSchema = yup.object({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  emailAddress: yup.string().email().required().label("Email Address"),
  cellNumber: phoneNumberSchema.required().label("Cell Number"),
  breweryName: yup.string().required().label("Brewery Name"),
  breweryState: stateNameSchema.required().label("Brewery State"),
  distributorName: yup.string().required().label("Brewery Name"),
  distributorEmail: yup.string().email().required().label("Email Address")
}).defined();



export const createAccountFormSchema = yup.object({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  emailAddress: yup.string().email().required().label("Email Address"),
  password: yup.string().required().label("Password"),
  confirmPassword: confirmPasswordSchema(yup.ref('password')).required().label("Confirm password"),
  untappd: yup.string().optional().label("Untappd account"),
  cellNumber: phoneNumberSchema.required().label("User mobile number"),
  breweryName: yup.string().required().label("Brewery name"),
  breweryState: stateNameSchema.required().label("Brewery state")
}).defined();



export interface IRegisterBreweryInput {
  firstName: string;
  lastName: string;
  emailAddress: string;
  cellNumber: string;
  breweryName: string;
  breweryState: string;
  untappd?: string;
  password: string;
  confirmPassword: string;
}



export interface IDistributorFormType {
  id: string;
  state: string;
  contactEmails: string[];
}
