import React from 'react';
import classnames from 'classnames';

import styles from './TableCollapsibleSection.module.css';



export interface ITableCollapsibleSectionProps {
  className?: string;

  openLabel?: string;
  closeLabel?: string;
}

export const TableCollapsibleSection: React.FC<ITableCollapsibleSectionProps> = props => {
  const [isCollapsed, setCollapsed] = React.useState(true);

  return (
    <div className={styles.container}>
      <div className={classnames([styles.collapseContainer, props.className, {[styles.collapsed]: isCollapsed}])}>
        {props.children}
      </div>

      <button
        className={styles.toggleButton}
        onClick={() => setCollapsed(!isCollapsed)}>
        {isCollapsed ?
          props.openLabel ?? 'OPEN' :
          props.closeLabel ?? 'CLOSE'}
      </button>
    </div>
  );
};
