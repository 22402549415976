import _ from 'lodash';

import { OrderStatusKey, OrderStatusValue } from 'components/shared/Types/Order';

export function getOrderStatus(lineStatuses: OrderStatusKey[]): OrderStatusValue {

  const newOrders = _.filter(lineStatuses, status => status === 'newOrder').length;
  const shippedOrders = _.filter(lineStatuses, status => status === 'shipped').length;
  const partlyReceivedOrders = _.filter(lineStatuses, status => status === 'partlyReceived').length;
  const receivedOrders = _.filter(lineStatuses, status => status === 'received').length;

  if (newOrders !== 0 && shippedOrders === 0 && partlyReceivedOrders === 0 && receivedOrders === 0) {
    return "New Order";
  }

  if (partlyReceivedOrders !== 0 || (receivedOrders !== 0 && (newOrders !== 0 || shippedOrders !== 0))) {
    return "Partly Received";
  }

  if (receivedOrders !== 0 && partlyReceivedOrders === 0 && shippedOrders === 0 && newOrders === 0) {
    return "Received";
  }

  if (shippedOrders !== 0) {
    if (newOrders === 0 && partlyReceivedOrders === 0 && receivedOrders === 0) {
      return "Shipped";
    }
    else {
      return "Partly Shipped";
    }
  }

  return "Unknown";
}


export function getOrderStatusEnum(lineStatuses: OrderStatusKey[]): OrderStatusKey {

  const newOrders = _.filter(lineStatuses, status => status === 'newOrder').length;
  const shippedOrders = _.filter(lineStatuses, status => status === 'shipped').length;
  const partlyReceivedOrders = _.filter(lineStatuses, status => status === 'partlyReceived').length;
  const receivedOrders = _.filter(lineStatuses, status => status === 'received').length;

  if (newOrders !== 0 && shippedOrders === 0 && partlyReceivedOrders === 0 && receivedOrders === 0) {
    return 'newOrder';
  }

  if (partlyReceivedOrders !== 0 || (receivedOrders !== 0 && (newOrders !== 0 || shippedOrders !== 0))) {
    return 'partlyReceived';
  }

  if (receivedOrders !== 0 && partlyReceivedOrders === 0 && shippedOrders === 0 && newOrders === 0) {
    return 'received';
  }

  if (shippedOrders !== 0) {
    if (newOrders === 0 && partlyReceivedOrders === 0 && receivedOrders === 0) {
      return 'shipped';
    }
    else {
      return 'shipped';
    }
  }

  return 'unknown';
}
