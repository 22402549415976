/**
 * Usage Example
 * arr1 = [2, 3, 4];
 * arr2 = [1, 3, 4, 5];
 * diff(arr1, arr2) = [2];
 * diff(arr2, arr1) = [1, 5];
 */

export function diff<T extends number | string>(arr1: T[], arr2: T[]): T[] {
  return arr1.filter(t => !arr2.includes(t));
}
