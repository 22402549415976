import React from 'react';
import classnames from 'classnames';
import currency from 'currency.js';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';
import {
  CardElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  ISubscriptionFormType,
  ISubscriptionInfo,
  subscriptionFormSchema
} from '../../dataService';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Select } from 'components/shared/form/Select/Select';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { useAlert } from 'util/hooks';
import { getStateNameOptions } from 'util/stateNames';
import { validator } from 'util/validation';
import * as optionsUtil from 'util/options';

import styles from './SubscriptionModal.module.css';



export interface ISubscriptionModalProps extends IModalTemplateProps {
  subscription: ISubscriptionInfo | undefined;

  onSaveAndAgree: (states: string[], stripePayload: string) => void;
}

export const SubscriptionModal: React.FC<ISubscriptionModalProps> = props => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    AlertModalProps, setAlert, alertOnError
  } = useAlert();
  const refForm = React.useRef<Form>(null);
  const [formValues, setFormValues] = React.useState<ISubscriptionFormType>({distributingStates: []});

  const {
    additionalCost, totalCost
  } = React.useMemo(() => {
    const additional = currency(19, { precision: 0 }).multiply(formValues.distributingStates.length - 1);
    const total = currency(49, { precision: 0 }).add(additional);

    return {
      additionalCost: additional.format(),
      totalCost: total.format()
    };
  }, [formValues.distributingStates]);

  function handleChangeFormValues(values: Partial<ISubscriptionFormType>) {
    setFormValues({
      ...formValues,
      ...values
    });
  }

  function handleFormValidation() {
    try {
      validator(subscriptionFormSchema, formValues);
      return true;
    } catch (err) {
      alertOnError(err.errors);
      return false;
    }
  }

  async function handleSubmit() {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    try {
      const stripeCardElement = elements.getElement(CardElement);

      if (stripeCardElement) {
        const payload = await stripe.createPaymentMethod({
          type: "card",
          card: stripeCardElement
        });

        console.log("[PaymentMethod]", payload);

        if (payload.error) {
          throw payload.error.message;
        }
        else {
          //Send payload
          if (payload.paymentMethod) {
            await props.onSaveAndAgree(formValues.distributingStates, payload.paymentMethod.id);
          }
          else {
            throw new Error("Unable to complete payment. Please try again in a few minutes.");
          }
        }
      }
      else {
        throw new Error("Unable to complete payment. Please reload the page and try again.");
      }
    }
    catch (err) {
      setAlert(_.toString(err), 'error');
      console.error(err);
    }
  }

  React.useEffect(() => {
    if (!_.isNil(props.subscription)) {
      setFormValues(state => ({
        ...state,
        distributingStates: props.subscription?.distributingStates ?? state.distributingStates
      }));
    }
  }, [props.subscription, setFormValues]);

  return (
    <>
      <ModalTemplate
        modalTitle={`${props.subscription ? 'Update' : 'New'} Subscription`}
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}>
        <p className={styles.paragraph}>
          Although you are agreeing to the subscription today, you will only be charged for each month you receive an order. In the event you do not receive an order for a month your card will not be charged.
        </p>

        <hr />

        <Form
          ref={refForm}
          fnValidateForm={handleFormValidation}
          onSuccessfulSubmit={handleSubmit}>
          <Select
            required
            isMulti
            options={getStateNameOptions()}
            label="SELECT THE STATES YOU WISH TO PROCESS ORDERS FROM"
            value={optionsUtil.getOptionsFromValues(getStateNameOptions(), formValues.distributingStates) as any}
            onChange={options => handleChangeFormValues({distributingStates: optionsUtil.getValuesFromOptions(options)})} />

          <hr />

          <p className={`${styles.paragraph} ${styles.strong}`}>
            How we work out your subscription cost
          </p>

          {!_.isEmpty(formValues.distributingStates) && (
            <div className={styles.cost}>
              <div className={styles.costBreakdown}>
                <span>First Distribution State</span>

                <span className={styles.priceTag}>$49</span>
              </div>

              {formValues.distributingStates.length > 1 && (
                <div className={styles.costBreakdown}>
                  <span>Additional Distribution States x{formValues.distributingStates.length - 1}</span>

                  <span className={styles.priceTag}>{additionalCost}</span>
                </div>
              )}

              <div className={classnames([styles.costBreakdown, styles.totalCost])}>
                <span>Total Monthly Cost</span>

                <span className={styles.priceTag}>{totalCost}</span>
              </div>
            </div>
          )}

          <hr />

          <p className={`${styles.paragraph} ${styles.strong}`}>
          Please provide your payment card information below
          </p>

          <CardElement options={CARD_ELEMENT_OPTIONS} />

          <Button
            disabled={!stripe}
            className={styles.button}
            type="submit">
            Save &amp; Agree
          </Button>
        </Form>
      </ModalTemplate>

      <AlertModal {...AlertModalProps} />
    </>
  );
};



const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      "color": "#32325d",
      "fontFamily": '"Helvetica Neue", Helvetica, sans-serif',
      "fontSmoothing": "antialiased",
      "fontSize": "16px",
      ":focus": {color: '#424770'},
      "::placeholder": {color: "#aab7c4"}
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }

  }
};
