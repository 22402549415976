import _ from 'lodash';


import {
  useApprovedBreweriesPageQuery as useBaseApprovedBreweriesPageQuery,
  useActAsBreweryOwnerMutation as useBaseActAsBreweryOwnerMutation,
  useToggleLoginPermittedMutation as useBaseToggleLoginPermittedMutation,
  ActAsBreweryOwnerInput
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';



export function useApprovedBreweriesPageQuery() {
  const tuple = useBaseApprovedBreweriesPageQuery();

  const approvedBreweries = tuple.data?.approvedBreweries;

  return {
    ...tuple,
    data: {
      approvedBreweries: _.map(approvedBreweries, approvedBrewery => {
        return {
          id: approvedBrewery.id ?? '',
          breweryName: approvedBrewery.name ?? '',
          breweryState: approvedBrewery.state ?? '',
          ownerId: approvedBrewery.ownedBy?.id ?? '',
          ownerFirstName: approvedBrewery.ownedBy?.firstName ?? '',
          ownerLastName: approvedBrewery.ownedBy?.lastName ?? '',
          ownerEmail: approvedBrewery.ownedBy?.email ?? '',
          ownerCell: approvedBrewery.ownedBy?.cell ?? '',
          loginPermitted: approvedBrewery.ownedBy?.loginPermitted ?? false
        };
      })
    }
  };
}



export function useActAsBreweryOwnerMutation() {
  const [
    fn,
    tuple
  ] = useBaseActAsBreweryOwnerMutation();


  const fnWrappedMutation = (input: ActAsBreweryOwnerInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useToggleLoginPermittedMutation() {
  const [
    fn,
    tuple
  ] = useBaseToggleLoginPermittedMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.ApprovedBreweriesPageRefetchQuery({})]
  });


  const fnWrappedMutation = (siteUserId: string) => {
    return fn({variables: {siteUserId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}




export interface IBreweryAccount {
  id: string;
  breweryName: string;
  breweryState: string;
  ownerId: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  ownerCell: string;
  loginPermitted: boolean;
}
