import {
  extractErrorMessageFromValidation,
  phoneNumberSchema
} from 'util/validation';

import { genInputComponent } from '../GenericInput/GenericInput';



export const PhoneNumberInput = genInputComponent('text', (value, required) => {
  let schema = phoneNumberSchema;
  if (required) {
    schema = schema.required();
  }
  return extractErrorMessageFromValidation(schema, value);
});
