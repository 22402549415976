import React from 'react';

import styles from './TableTitle.module.css';



export const TableTitle: React.FC = props => (
  <h3 className={styles.title}>
    {props.children}
  </h3>
);
