import React from 'react';
import _ from 'lodash';
import { routes } from 'routes';
import { RouteComponentProps } from 'react-router';

import { OrderDetailModal } from 'components/shared/modals/OrderDetailModal/OrderDetailModal';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { GetCurrentUserHook } from 'util/hooks';

import { CallToActions } from './CallToActions/CallToActions';
import { OrdersList } from './OrdersList/OrdersList';
import { SummaryCard } from './SummaryCard/SummaryCard';

import * as dataService from './dataService';
import styles from './HomePage.module.css';



export type IHomePageProps = RouteComponentProps

export const HomePage: React.FC<IHomePageProps> = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const queryParams = routes.home.useQueryParams();
  const [searchFilter, setSearchFilter] = React.useState('');
  const [summaryMessage, setSummaryMessage] = React.useState('');
  const [dateTuple, setDateTuple] = React.useState<[Date | null, Date | null]>([null, null]);
  const currentUserQueryTuple = GetCurrentUserHook();
  const [fnRunQuery, queryTuple] = dataService.useMyBrewPipelinePageLazyQuery(currentUserQueryTuple.data.currentUser.breweryIdForQueries);
  const [selectedOrderId, setSelectOrderId] = React.useState<string | null>(null);
  const [isFreshPageLoad, setIsFreshPageLoad] = React.useState(true);
  const [showViewDetailsModal, setShowViewDetailsModal] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(dataService.TABLE_PAGE_SIZE);

  const loading = _.some([currentUserQueryTuple, queryTuple], tuple => tuple.loading);

  const selectedOrder = React.useMemo(() =>
    _.find(queryTuple.data.orders, order => order.id === selectedOrderId),
  [queryTuple.data.orders, selectedOrderId]);

  function handleViewDetails(orderId: string) {
    setSelectOrderId(orderId);
    setShowViewDetailsModal(true);
  }

  function handleCloseModal(clearSelection?: boolean) {
    setShowViewDetailsModal(false);
    if (clearSelection) {
      setSelectOrderId(null);
    }
  }

  React.useEffect(() => {
    fnRunQuery({
      keyword: searchFilter,
      fromDate: dateTuple[0]?.toISOString(),
      toDate: dateTuple[1]?.toISOString()
    }, {
      pageSize: pageSize,
      currentPage: currentPage
    });
  }, [searchFilter, dateTuple, pageSize, currentPage]);

  const handleSetFilterOption = React.useCallback((value: string) => {
    let message = 'Total Revenue';
    if (value === 'MTD') {
      message = 'Revenue in This Month';
    } else if (value === 'YTD') {
      message = 'Revenue in This Year';
    } else if (value === 'LM') {
      message = 'Revenue of Last Month';
    } else if (value === 'custom') {
      message = 'Revenue Between Custom Dates';
    }
    setSummaryMessage(message);
  }, []);

  function handleToggleViewAll(toggledValue: boolean) {
    const newPageSize = toggledValue ? 0 : dataService.TABLE_PAGE_SIZE;
    setPageSize(newPageSize);
  }

  React.useEffect(() => {
    if (queryParams.orderId) {
      handleViewDetails(queryParams.orderId);
    }
  }, [queryParams.orderId]);

  React.useEffect(() => {
    setSearchFilter(queryParams.retailerName ?? '');
  }, [queryParams.retailerName]);

  React.useEffect(() => {
    if (isFreshPageLoad) {
      fnRunQuery({ keyword: searchFilter }, {
        pageSize: pageSize,
        currentPage
      });
      setIsFreshPageLoad(false);
    }
  }, [isFreshPageLoad, fnRunQuery, setIsFreshPageLoad]);

  return (
    <PageTemplate
      loading={loading}
      headerTitle={(
        <HeaderTitle
          title="My BevNomad"
          icon="boiler" />
      )}>

      <CallToActions actions={queryTuple.data.callToActions} />

      <div className={styles.summaries}>
        <SummaryCard
          large
          value={queryTuple.data.summary.minPtr}>
          {summaryMessage}
          <br />
          Min PTR - Max PTR across all states
        </SummaryCard>

        <SummaryCard
          value={queryTuple.data.summary.numberOfOrders}>
          Total number of orders in
          <br />
          this date range across all states
        </SummaryCard>
      </div>

      <OrdersList
        data={queryTuple.data.orders}
        pageCount={queryTuple.data.pagination.numberOfPages}
        currentPage={currentPage}
        defaultSearchText={searchFilter}
        onSetPageNumber={setCurrentPage}
        onViewDetails={handleViewDetails}
        onSetSearchText={setSearchFilter}
        onSetDateFilterOption={handleSetFilterOption}
        onSetDateTuple={setDateTuple}
        onToggleViewAll={handleToggleViewAll}/>

      <OrderDetailModal
        isSuperAdmin={false}
        order={selectedOrder}
        isOpen={showViewDetailsModal}
        onRequestClose={() => handleCloseModal(true)} />

    </PageTemplate>
  );
};
