import React from 'react';
import _ from 'lodash';
import ReactModal from 'react-modal';

import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';

import { useAnimationFrame } from 'util/hooks';

import styles from './ModalTemplate.module.css';

import imgModalClose from 'assets/icons/modal-close.png';



export type IModalTemplateProps = ReactModal.Props & {
  modalTitle?: string | React.ReactNode;
  maxHeight?: number;
  isLoading?: boolean;

  onRequestClose: () => void;
};

export const ModalTemplate: React.FC<IModalTemplateProps> = props => {
  const [contentHeight, setContentHeight] = React.useState(300);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const refContent = React.useRef<HTMLDivElement>(null);

  const mergedStyles = React.useMemo(() => _.merge({}, __styles, {content: {height: contentHeight}}, props.style ?? {}), [contentHeight, props.style]);

  useAnimationFrame(() => {
    if (refContainer.current && refContent.current) {
      setContentHeight(_.clamp(refContent.current.scrollHeight + 45, 300, props.maxHeight ?? 800));
    }
  }, [refContainer, refContent, setContentHeight, props.maxHeight]);

  return (
    <ReactModal
      {...props}
      style={mergedStyles}>
      <div ref={refContainer}>
        {props.isLoading && (
          <LoadingOverlay />
        )}
        {props.modalTitle && (
          <div className={styles.header}>
            {_.isString(props.modalTitle) ? (
              <h3 className={styles.title}>
                {props.modalTitle}
              </h3>
            ) : props.modalTitle}
          </div>
        )}

        <button
          type='button'
          className={styles.closeButton}
          onClick={props.onRequestClose}>
          <img
            src={imgModalClose}
            width={84}
            alt="close modal" />
        </button>

        <div ref={refContent}>
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
};



const __styles: ReactModal.Styles = {
  overlay: {zIndex: 100},
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transitionProperty: "height",
    transitionDuration: "100ms",
    width: 660,
    borderRadius: 4,
    boxShadow: '10px 8px 40px 0 rgba(0, 0, 0, 0.45)',
    borderWidth: 1,
    borderColor: '#f4f5f7',
    backgroundColor: '#ffffff',
    padding: '43px 46px'
  }
};
