import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import styles from './Pagination.module.css';



export interface IPaginationProps {
  pageCount: number;
  currentPage: number;

  className?: string;

  goToPage: (pageIndex: number) => void;
}

export const Pagination: React.FC<IPaginationProps> = props => {
  const goToPageButtonSet = React.useMemo(() => {
    if (props.pageCount <= 6) {
      return __getRange(1, props.pageCount);
    }

    const startPage = _.max([
      2,
      props.currentPage - 1
    ]) || 0;
    const endPage = _.min([
      props.pageCount - 1,
      props.currentPage + 1
    ]) || 0;
    let pages: number[] = __getRange(startPage, endPage);

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2;
    const hasRightSpill = (props.pageCount - endPage) > 1;
    const spillOffset = 5 - (pages.length + 1);

    if (hasLeftSpill && !hasRightSpill) {
      const extraPages = __getRange(startPage - spillOffset, startPage - 1);
      pages = [
        ...extraPages,
        ...pages
      ];

    } else if (!hasLeftSpill && hasRightSpill) {
      const extraPages = __getRange(endPage + 1, endPage + spillOffset);
      pages = [
        ...pages,
        ...extraPages
      ];
    }

    return [
      1,
      ...pages,
      props.pageCount
    ];
  }, [
    props.currentPage,
    props.pageCount
  ]);

  return (
    <div className={classnames(styles.container, props.className)}>
      {(props.currentPage > 1 && props.pageCount > 0) && (
        <button
          className={styles.pageButton}
          style={{marginRight: 6}}
          onClick={() => props.goToPage(1)}>
          {"<"}
        </button>
      )}

      <div className={styles.pages}>
        {_.map(goToPageButtonSet, button => (
          <button
            key={button}
            onClick={() => props.goToPage(button)}
            className={classnames(styles.pageButton, {[styles.activePageButton]: props.currentPage === button})}>
            {button}
          </button>
        ))}
      </div>

      {props.currentPage < props.pageCount && (
        <button
          className={styles.pageButton}
          style={{marginLeft: 6}}
          onClick={() => props.goToPage(props.pageCount)}>
          {">"}
        </button>
      )}
    </div>
  );
};



function __getRange(from: number, to: number, step = 1) {
  let i = from;
  const range: number[] = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}
