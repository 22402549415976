import React from 'react';
import _ from 'lodash';

import { Table } from 'components/shared/Table/Table';
import { Button } from 'components/shared/Button/Button';
import { TableActions } from 'components/shared/Table/TableActions/TableActions';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { InviteBrewerFormSection } from './InviteBrewerFormSection/InviteBrewerFormSection';

import * as dataService from './dataService';



export const ApplicationsPage: React.FC = () => {
  const queryTuple = dataService.usePendingApplicationsPageQuery();
  const [fnAcceptApplication, acceptApplicationTuple] = dataService.useApproveApplicationMutation();
  const [fnRejectApplication, rejectApplicationTuple] = dataService.useRejectApplicationMutation();
  const [fnInviteBrewery, inviteBreweryTuple] = dataService.useInviteBreweryMutation();

  const isLoading = _.some([
    queryTuple,
    acceptApplicationTuple,
    rejectApplicationTuple,
    inviteBreweryTuple
  ], tuple => tuple.loading);

  return (
    <PageTemplate loading={isLoading}>
      <TableHeader>
        <TableTitle>
          Applications
        </TableTitle>
      </TableHeader>

      <Table
        columnDefs={[
          {
            label: 'First Name',
            fnCellContent: d => d.firstName
          },
          {
            label: 'Last Name',
            fnCellContent: d => d.lastName
          },
          {
            label: 'Cell',
            fnCellContent: d => d.cellNumber
          },
          {
            label: 'Brewery',
            fnCellContent: d => d.name
          },
          {
            label: 'Untappd',
            fnCellContent: d => d.untappd
          },
          {
            label: 'Actions',
            width: '70px',
            fnCellContent: d => (
              <TableActions>
                <Button
                  sizeStyle="small"
                  colorStyle="secondary"
                  onClick={() => fnAcceptApplication(d.id)}>
                Accept
                </Button>

                <Button
                  sizeStyle="small"
                  colorStyle="secondary"
                  onClick={() => fnRejectApplication(d.id)}>
                Reject
                </Button>
              </TableActions>
            )
          }
        ]}
        data={queryTuple.data.pendingApplications} />

      <InviteBrewerFormSection onSendInvite={fnInviteBrewery} />
    </PageTemplate>
  );
};
