import React from 'react';
import _ from 'lodash';

import { Button } from 'components/shared/Button/Button';
import { DebouncedSearchBox } from 'components/shared/DebouncedSearchBox/DebouncedSearchBox';
import { OrderDetailModal } from 'components/shared/modals/OrderDetailModal/OrderDetailModal';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { Table } from 'components/shared/Table/Table';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';

import * as dataService from './dataService';
import { DateRangeSelectField } from 'components/shared/DateRangeSelectField/DateRangeSelectField';



export const OrdersPage: React.FC = () => {
  const [fnRunQuery, queryTuple] = dataService.useOrdersSuperAdminPageLazyQuery();

  const [searchText, setSearchText] = React.useState('');
  const [dateTuple, setDateTuple] = React.useState<[Date | null, Date | null]>([null, null]);
  const [selectedOrderId, setSelectOrderId] = React.useState<string | null>(null);
  const [isFreshPageLoad, setIsFreshPageLoad] = React.useState(true);
  const [showViewDetailsModal, setShowViewDetailsModal] = React.useState(false);

  const isLoading = queryTuple.loading;

  const selectedOrder = React.useMemo(() => _.find(queryTuple.data.orders, order => order.id === selectedOrderId), [queryTuple.data.orders, selectedOrderId]);

  React.useEffect(() => {
    if (isFreshPageLoad) {
      fnRunQuery({});
      setIsFreshPageLoad(false);
    }
  }, [isFreshPageLoad, fnRunQuery]);

  function handleViewDetails(orderId: string) {
    setSelectOrderId(orderId);
    setShowViewDetailsModal(true);
  }

  function handleCloseModal(clearSelection?: boolean) {
    setShowViewDetailsModal(false);
    if (clearSelection) {
      setSelectOrderId(null);
    }
  }

  React.useEffect(() => {
    fnRunQuery({
      keyword: searchText,
      fromDate: dateTuple[0]?.toISOString(),
      toDate: dateTuple[1]?.toISOString()
    });
  }, [searchText, dateTuple]);

  return (
    <PageTemplate loading={isLoading}>
      <TableHeader>
        <TableTitle>
          Orders
        </TableTitle>

        <div style={{ display: 'flex' }}>
          <DateRangeSelectField onSetDateTuple={setDateTuple} />

          <div style={{ width: 12 }} />

          <DebouncedSearchBox
            debounceMillis={600}
            onSetSearchText={setSearchText}
            placeholder="Filter by Name"
            defaultSearchText={searchText}
          />
        </div>
      </TableHeader>

      <Table
        columnDefs={[
          {
            label: 'Order Id',
            fnCellContent: d => d.readableId
          },
          {
            label: 'Order Date',
            fnCellContent: d => d.orderDate
          },
          {
            label: 'Retailer',
            fnCellContent: d => d.retailerName
          },
          {
            label: 'Retailer State',
            fnCellContent: d => d.retailerState
          },
          {
            label: 'Brewery',
            fnCellContent: d => d.breweryName
          },
          {
            label: 'Brewery State',
            fnCellContent: d => d.breweryState
          },
          {
            label: 'Order Total',
            fnCellContent: d => d.orderTotal
          },
          {
            label: 'View Details',
            width: '80px',
            centered: true,
            fnCellContent: d => (
              <Button
                style={{width: 35}}
                sizeStyle="small"
                colorStyle="secondary"
                onClick={() => {
                  handleViewDetails(d.id);
                }}
                leftIcon="bracketRight">
              </Button>
            )
          }
        ]}
        data={queryTuple.data.orders} />

      <OrderDetailModal
        isSuperAdmin
        order={selectedOrder}
        isOpen={showViewDetailsModal}
        onRequestClose={() => handleCloseModal(true)} />
    </PageTemplate>
  );
};
