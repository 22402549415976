import React from 'react';
import _ from 'lodash';

import { useHistory } from 'react-router';
import { ICallToAction } from '../dataService';

import styles from './CallToActions.module.css';



export interface ICallToActionsProps {
  actions: ICallToAction[];
}

export const CallToActions: React.FC<ICallToActionsProps> = props => {
  const history = useHistory();

  function handleClickAction(action: ICallToAction) {
    history.push(action.url);
  }

  return (
    <div className={styles.container}>
      {_.map(props.actions, (action, index) => (
        <div
          key={index}
          className={styles.action}
          onClick={() => handleClickAction(action)}>
          <h5>{action.title}</h5>

          <p>{action.description}</p>
        </div>
      ))}
    </div>
  );
};
