import React from 'react';
import _ from 'lodash';

import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { ContactInfoForm } from './formSections/ContactInfoForm';
import { ChangePasswordForm } from './formSections/ChangePasswordForm';
import { SubscriptionSection } from './SubscriptionSection/SubscriptionSection';
import { GetCurrentUserHook } from 'util/hooks';
import {useToasts} from 'react-toast-notifications';

import * as dataService from './dataService';

import styles from './SettingsPage.module.css';




export const SettingsPage: React.FC = () => {
  const { addToast } = useToasts();
  const queryTuple = GetCurrentUserHook();
  const subscriptionQueryTuple = dataService.useGetSubscriptionQuery(queryTuple.data.currentUser.breweryIdForQueries);
  const [fnUpdateUserInformation, mutationTuple] = dataService.useUpdateUserInformationMutation();
  const [fnUpsertSubscription, upsertSubscriptionResult] = dataService.useUpsertSubscriptionMutation(queryTuple.data.currentUser.breweryIdForQueries);
  const [fnCancelSubscription, cancelSubscriptionResult] = dataService.useCancelSubscriptionMutation(queryTuple.data.currentUser.breweryIdForQueries);

  const loading = _.some([queryTuple, subscriptionQueryTuple, mutationTuple, upsertSubscriptionResult, cancelSubscriptionResult], tuple => tuple.loading);

  async function handleOnSaveAndAgree(states: string[], stripePayload: string) {
    try {
      await fnUpsertSubscription({
        states,
        stripePayload
      });

      addToast('Subscription saved!', {appearance: 'success'});
    }
    catch (error) {
      addToast(error, {appearance: 'error'});
    }
  }

  async function handleOnCancelSubscription() {
    try {

      if (subscriptionQueryTuple.data?.id) {
        await fnCancelSubscription(subscriptionQueryTuple.data.id);
      }

      addToast('Subscription Cancelled!', {appearance: 'success'});
    }
    catch(error) {
      addToast(error, {appearance: 'error'});
    }
  }

  return (
    <PageTemplate
      loading={loading}
      headerTitle={(
        <HeaderTitle
          title="Settings"
          icon="cog" />
      )}>
      <div className={styles.formSections}>
        <ContactInfoForm
          isActing={queryTuple.data.currentUser.isActing}
          formData={{
            firstName: queryTuple.data.currentUser.actingAs.firstName ?? queryTuple.data.currentUser.firstName,
            lastName: queryTuple.data.currentUser.actingAs.lastName ?? queryTuple.data.currentUser.lastName,
            emailAddress: queryTuple.data.currentUser.actingAs.emailAddress ?? queryTuple.data.currentUser.emailAddress,
            originalEmail: queryTuple.data.currentUser.actingAs.emailAddress ?? queryTuple.data.currentUser.emailAddress,
            cellNumber: queryTuple.data.currentUser.actingAs.cellNumber ?? queryTuple.data.currentUser.cellNumber
          }}
          onSaveContactInfo={async formData => {
            try {
              await fnUpdateUserInformation({
                siteUserId: queryTuple.data.currentUser.idForQueries,
                firstName: formData.firstName,
                lastName: formData.lastName,
                emailAddress: formData.emailAddress,
                cellNumber: formData.cellNumber
              });

              addToast('Contact information updated!', {appearance: 'success'});
            }
            catch (error) {
              addToast(error, {appearance: 'error'});
            }
          }} />

        <ChangePasswordForm
          isActing={queryTuple.data.currentUser.isActing} />

        <SubscriptionSection
          isActing={queryTuple.data.currentUser.isActing}
          subscription={subscriptionQueryTuple.data}
          onSaveAndAgree={handleOnSaveAndAgree}
          onCancel={handleOnCancelSubscription} />
      </div>
    </PageTemplate>
  );
};
