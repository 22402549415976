import React from 'react';

import * as dataService from './dataService';
import { getConfig } from 'services/environment';

import styles from './VersionIndicator.module.css';



export const VersionIndicator: React.FC = () => {
  const queryTuple = dataService.useVersionIndicatorQuery();
  const config = getConfig();

  return !config.isProd ? (
    <div className={styles.root}>
      <p>Environment: {config.environmentName}</p>
      <p>Client Version: {process.env.REACT_APP_VERSION ?? "N/A"}</p>
      <p>API Version: {queryTuple.data ? queryTuple.data?.version : 'Loading'}</p>
      <p>Marker.IO: {config?.markerIODestination}</p>
    </div>
  ) : null;
};

