import React from 'react';
import _ from 'lodash';

import {
  GetCurrentUserHook,
  GetValidSubscriptionHook,
  useAlert
} from 'util/hooks';

import { useToasts } from 'react-toast-notifications';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { BrandInfoFormSection } from './formSections/BrandInfoFormSection/BrandInfoFormSection';
import { PackagingFormSection } from './formSections/PackagingFormSection/PackagingFormSection';
import { BrandAccoladesFormSection } from './formSections/BrandAccoladesFormSection/BrandAccoladesFormSection';
import { Button } from 'components/shared/Button/Button';
import { routes } from 'routes';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { Switch } from 'components/shared/form/Switch/Switch';
import { validator } from 'util/validation';

import * as dataService from './dataService';

import styles from './BrandFormPage.module.css';
import { ValidationError } from 'yup';



export const BrandFormPage: React.FC = () => {
  const { addToast } = useToasts();
  const { brandId } = routes.brands.routes.edit.usePathParams();
  const currentUserTuple = GetCurrentUserHook();
  const {
    AlertModalProps, alertOnError
  } = useAlert();
  const subscriptionQueryTuple = GetValidSubscriptionHook(currentUserTuple.data.currentUser.breweryIdForQueries);
  const brandTuple = dataService.useLoadBrandQuery(brandId);
  const queryTuple = dataService.useBrandFormPageOptionsQuery(currentUserTuple.data.currentUser.breweryIdForQueries);
  const [fnUpsertBrand, upsertBandResult] = dataService.useUpsertBrandMutation(currentUserTuple.data.currentUser.breweryIdForQueries);
  const [fnCreateCategory, createCategoryTuple] = dataService.useCreateBrandCategoryMutation(currentUserTuple.data.currentUser.breweryIdForQueries);

  const refForm = React.useRef<Form>(null);
  const [brandIsLoaded, setBrandIsLoaded] = React.useState(false);
  const [formValues, setFormValues] = React.useState<dataService.IBrandFormData>({...dataService.brandInitialState});
  const canActivateOnMarketplace = React.useMemo(() =>
    subscriptionQueryTuple.isValidSubscription &&
    !_.isEmpty(formValues.formats) &&
    _.some(formValues.formats, format => format.listed),
  [subscriptionQueryTuple.isValidSubscription, formValues.formats]);

  const isLoading = _.some([
    subscriptionQueryTuple,
    currentUserTuple,
    brandTuple,
    queryTuple,
    upsertBandResult,
    createCategoryTuple
  ], tuple => tuple.loading);

  function handleChangeFormValues(values: Partial<dataService.IBrandFormData>) {
    setFormValues({
      ...formValues,
      ...values
    });
  }

  function handleValidateForm() {
    try {
      validator(dataService.brandSchema, dataService.trimBrandFormValues(formValues));
      return true;
    } catch(err) {
      console.error(err);

      if (err instanceof ValidationError) {
        alertOnError(err);
      }
      return false;
    }
  }

  async function handleSubmit() {
    try {
      await fnUpsertBrand({
        breweryId: currentUserTuple.data.currentUser.actingAs.breweryId ?? currentUserTuple.data.currentUser.breweryId,
        brandId,
        ...dataService.trimBrandFormValues(formValues)
      });

      addToast('Brand saved!', {appearance: 'success'});
    } catch (error) {
      addToast(error as any, {appearance: 'error'});
    }

  }

  React.useEffect(() => {
    if (!canActivateOnMarketplace && formValues.listed) {
      handleChangeFormValues({listed: false});
    }
  }, [canActivateOnMarketplace, formValues.listed]);

  React.useEffect(() => {
    if (brandId && !brandTuple.loading && !brandIsLoaded) {
      setBrandIsLoaded(true);
      setFormValues({...brandTuple.data});
    }
  }, [brandTuple]);

  return (
    <PageTemplate
      loading={isLoading}
      headerTitle={(
        <HeaderTitle
          title={brandId ? `Edit Brand ${formValues.name}` : 'Add New Brand'}
          icon="beer">
          <Switch
            disabled={!canActivateOnMarketplace}
            className={styles.availableSwitch}
            value={formValues.listed}
            onChangeValue={listed => handleChangeFormValues({listed})}>
            Make Available on Marketplace
          </Switch>
        </HeaderTitle>
      )}>
      <Form
        ref={refForm}
        extraClassName={styles.form}
        fnValidateForm={handleValidateForm}
        onSuccessfulSubmit={handleSubmit}>
        <div className={styles.formSection}>
          <BrandInfoFormSection
            availableCategories={queryTuple.data.brandCategories}
            availableVolumeTypes={queryTuple.data.volumeAvailabilities}
            formValues={formValues}
            onCreateCategory={name => {
              fnCreateCategory({
                breweryId: currentUserTuple.data.currentUser.actingAs.breweryId ?? currentUserTuple.data.currentUser.breweryId,
                name
              });
            }}
            onChangeFormValue={handleChangeFormValues}
            refForm={refForm} />
        </div>
        <div className={styles.formSection}>
          <PackagingFormSection
            canAlterListingStatus={subscriptionQueryTuple.isValidSubscription}
            availablePackingFormats={queryTuple.data.packingFormats}
            refForm={refForm}
            formValues={formValues}
            onChangeFormValue={handleChangeFormValues} />
        </div>


        <div className={styles.formSection}>
          <BrandAccoladesFormSection
            refForm={refForm}
            formValues={formValues}
            onChangeFormValue={handleChangeFormValues} />
        </div>

        <Button
          style={{width: 260}}
          type="submit"
          colorStyle="primary">
          Save
        </Button>
      </Form>

      <AlertModal {...AlertModalProps} />
    </PageTemplate>
  );
};
