import React from 'react';
import _ from 'lodash';

import {
  Link,
  NavLink
} from 'react-router-dom';
import {
  useLocation,
  matchPath
} from 'react-router';
import {
  routes,
  superAdminRoutes
} from 'routes';

import {Icon} from 'components/shared/Icon/Icon';
import {Logo} from 'components/shared/Logo/Logo';

import styles from './SideNav.module.css';



export interface ISideNavProps {
  hasSuperAccess?: boolean;
  isActing?: boolean;
}

export const SideNav: React.FC<ISideNavProps> = props => {
  const location = useLocation();

  function match(path: string, exact?: boolean): boolean {
    return !_.isNil(matchPath(location.pathname, {
      path,
      exact
    }));
  }

  return (
    <aside className={styles.container}>
      <div className={styles.logoContainer}>
        <Link to={routes.home.path()}>
          <Logo width={225} />
        </Link>
      </div>

      <nav className={styles.navContainer}>
        {((props.hasSuperAccess && props.isActing) || !props.hasSuperAccess) && (
          <>
            <NavLink
              exact
              className={styles.navItem}
              to={routes.home.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="my BevNomad"
                className={styles.navIcon}
                type={match(routes.home.rawPath, true) ? 'boilerOrange' : 'boiler'} />

              <span className={styles.navItemLabel}>My BevNomad</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={routes.brands.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="brands"
                className={styles.navIcon}
                type={match(routes.brands.rawPath) ? 'beerOrange' : 'beer'} />

              <span className={styles.navItemLabel}>Brands</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={routes.retailers.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="retailers"
                className={styles.navIcon}
                type={match(routes.retailers.rawPath) ? 'retailOrange' : 'retail'} />

              <span className={styles.navItemLabel}>Retailers</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={routes.profile.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="profile"
                className={styles.navIcon}
                type={match(routes.profile.rawPath) ? 'profileOrange' : 'profile'} />

              <span className={styles.navItemLabel}>Brewer Profile</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={routes.distributors.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="distributors"
                className={styles.navIcon}
                type={match(routes.distributors.rawPath) ? 'profileOrange' : 'profile'} />

              <span className={styles.navItemLabel}>My Distributors</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={routes.settings.rawPath}
              activeClassName={styles.navItemActive}>
              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(routes.settings.rawPath) ? 'cogOrange' : 'cog'} />

              <span className={styles.navItemLabel}>Settings</span>
            </NavLink>
          </>
        )}
        {(props.hasSuperAccess && !props.isActing) &&  (
          <>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.accounts.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.accounts.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Brew Employees</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.applications.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.applications.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Brewery Applications</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.breweries.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.breweries.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Approved Breweries</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.distributors.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.distributors.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Distributors</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.retailers.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.retailers.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Retailers</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.reports.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.reports.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Reports</span>
            </NavLink>

            <NavLink
              className={styles.navItem}
              to={superAdminRoutes.orders.rawPath}
              activeClassName={styles.navItemActive}>

              <Icon
                size={35}
                alt="settings"
                className={styles.navIcon}
                type={match(superAdminRoutes.orders.rawPath) ? 'cogOrange' : 'cog'} />
              <span className={styles.navItemLabel}>Orders</span>
            </NavLink>
          </>
        )}
      </nav>
    </aside>
  );
};
