import React from 'react';
import _ from 'lodash';

import {
  getStateNameById,
  getStateNameOptions
} from 'util/stateNames';

import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { Table } from 'components/shared/Table/Table';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { Select } from 'components/shared/form/Select/Select';
import { TableActions } from 'components/shared/Table/TableActions/TableActions';
import { Button } from 'components/shared/Button/Button';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';
import { ConfirmModal } from 'components/shared/modals/ConfirmModal/ConfirmModal';
import { DistributorFormModal } from './DistributorFormModal/DistributorFormModal';
import { useAllDistributorsQuery } from 'util/hooks';
import * as optionsUtil from 'util/options';

import * as dataService from './dataService';



export const DistributorsPage: React.FC = () => {
  const distributorsQueryTuple = useAllDistributorsQuery(undefined);
  const [searchStates, setSearchStates] = React.useState<string[]>([]);
  const [showDistributorFormModal, setShowDistributorFormModal] = React.useState(false);
  const [showRemoveDistributorModal, setShowRemoveDistributorModal] = React.useState(false);
  const [selectedDistributor, setSelectedDistributor] = React.useState<dataService.IDistributor | null>(null);
  const [fnUpsertDistro, upsertDistroTuple] = dataService.useUpsertDistributorMutation();
  const [fnRemoveDistro, removeDistroTuple] = dataService.useRemoveDistributorMutation();

  const isLoading = _.some([
    distributorsQueryTuple,
    upsertDistroTuple,
    removeDistroTuple
  ], tuple => tuple.loading);

  function handleEditDistributor(distributor: dataService.IDistributor) {
    setShowDistributorFormModal(true);
    setSelectedDistributor(distributor);
  }

  function handleDeleteDistributor(distributor: dataService.IDistributor) {
    setShowRemoveDistributorModal(true);
    setSelectedDistributor(distributor);
  }

  function handleFilter(datum: dataService.IDistributor) {
    return _.isEmpty(searchStates) || _.intersection(searchStates, datum.distributionStates).length > 0;
  }

  function handleCloseModal() {
    setSelectedDistributor(null);
    setShowDistributorFormModal(false);
    setShowRemoveDistributorModal(false);
  }

  async function handleOnDistributorSubmit(input: dataService.IDistributor) {
    try {
      const alteredInput: dataService.IDistributor = {
        id: input.id,
        name: input.name,
        contactEmails: input.contactEmails,
        distributionStates: input.distributionStates
      };

      await fnUpsertDistro(alteredInput);
      handleCloseModal();
    } catch (err) {
      // TODO: Show an error message
    }
  }

  return (
    <PageTemplate loading={isLoading}>
      <TableHeader>
        <TableTitle>
          Distributors
        </TableTitle>

        <div style={{marginRight: 18}}>
          <Button onClick={() => setShowDistributorFormModal(true)}>
            Add Distributor
          </Button>
        </div>

        <div style={{width: 280}}>
          <Select
            isMulti
            placeholder="Filter by States"
            options={getStateNameOptions()}
            value={optionsUtil.getOptionsFromValues(getStateNameOptions(), searchStates) as any}
            onChange={options => setSearchStates(optionsUtil.getValuesFromOptions(options))} />
        </div>
      </TableHeader>

      <Table
        fnFilter={handleFilter}
        columnDefs={[
          {
            label: 'Name',
            fnCellContent: d => d.name
          },
          {
            label: 'States',
            fnCellContent: d => _.join(_.map(d.distributionStates, getStateNameById), ', ')
          },
          {
            label: 'Contact Emails',
            fnCellContent: d => _.join(d.contactEmails, ', ')
          },
          {
            label: '',
            width: '70px',
            fnCellContent: d => {
              return (
                <TableActions>
                  <Button
                    sizeStyle="small"
                    colorStyle="secondary"
                    onClick={() => handleEditDistributor(d)}>
                    Edit
                  </Button>

                  {!d.isASelfDistributor && (
                    <Button
                      sizeStyle="small"
                      colorStyle="secondary"
                      onClick={() => handleDeleteDistributor(d)}>
                      Delete
                    </Button>
                  )
                  }
                </TableActions>
              );
            }
          }
        ]}
        data={distributorsQueryTuple.data.availableDistributorInfo} />

      <DistributorFormModal
        isOpen={showDistributorFormModal}
        onRequestClose={handleCloseModal}
        distributor={selectedDistributor}
        onSubmit={handleOnDistributorSubmit} />

      <ConfirmModal
        title="Remove Distributor"
        content={`Are you sure you want to remove ${selectedDistributor?.name} distributor?`}
        onRequestClose={handleCloseModal}
        onConfirmed={() => {
          selectedDistributor && fnRemoveDistro(selectedDistributor.id);
          handleCloseModal();
        }}
        isOpen={showRemoveDistributorModal} />
    </PageTemplate>
  );
};
