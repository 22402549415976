
import { BehaviorSubject } from 'rxjs';
import * as env from './environment';
import * as apolloService from './apolloService';
import firebase from 'firebase';
import { getApp } from './firebaseService';



export type IAuthStatus =
  'noAttemptMade' |
  'loggedOut' |
  'loggedIn';

export interface IAuthState {
  status: IAuthStatus;
}

let __authState: IAuthState = {status: 'noAttemptMade'};

const __authStateBehaviorSubject = new BehaviorSubject<IAuthState>(__authState);

const __allowAuthStateChanges = true;



export function init() {
  __addAuthStateListener();
}



function __addAuthStateListener() {
  getApp().auth().onAuthStateChanged(
    user => __refreshSiteUser(user)
  );
}



async function __refreshSiteUser(user: firebase.User | null) {
  if (!__allowAuthStateChanges) {
    return;
  }

  if (user === null) {
    __authState = {status: 'loggedOut'};
    __authStateBehaviorSubject.next(__authState);
    console.log("Refresh site user due to change to null user");
    apolloService.refreshClient();
    return;

  } else {
    if (!env.getConfig().isProd) {
      __printAuthToken(user);
    }

    //const authToken = await user.getIdToken();
    //console.log("authToken: " + authToken);

    __authState = {status: 'loggedIn'};

    __authStateBehaviorSubject.next(__authState);
    console.log("Refresh site user due to change to active user");
    apolloService.refreshClient();
  }
}



function __printAuthToken(user: firebase.User | null) {
  if (user) {
    user.getIdToken()
      .then(token => {
        console.log("Auth Token: " + token);
      });
  }
}



export function getAuthStateObservable() {
  return __authStateBehaviorSubject.asObservable();
}



export function logOut() {
  return getApp().auth().signOut();
}



export function getFirebaseUser() {
  return getApp().auth().currentUser;
}



export function logInWithEmail(email: string, password: string) {
  return getApp().auth().signInWithEmailAndPassword(email, password);
}



export async function updateEmail(email: string) {
  const user = __dangerouslyGetCurrentUser();

  return await user.updateEmail(email);
}



export async function updatePassword(password: string) {
  const user = __dangerouslyGetCurrentUser();
  return await user.updatePassword(password);
}



function __dangerouslyGetCurrentUser() {
  const user = getApp().auth().currentUser;
  if (!user) {
    throw new Error('NOT_LOGGED_IN');
  }

  return user;
}



export async function reauthenticateWithEmail(email: string, password: string) {
  const user = __dangerouslyGetCurrentUser();

  const credential = firebase.auth.EmailAuthProvider.credential(email, password);
  return await user.reauthenticateWithCredential(credential);
}



export async function sendPasswordResetEmail(email: string) {
  await getApp().auth().sendPasswordResetEmail(email);
}
