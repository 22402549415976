import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Button } from 'components/shared/Button/Button';

import styles from './AlertModal.module.css';

import imgCancel from 'assets/icons/cancel.svg';
import imgSuccess from 'assets/icons/success.svg';



export interface IAlertModalProps extends IModalTemplateProps {
  type: IAlertContentType;
}

export const AlertModal: React.FC<IAlertModalProps> = props => {
  const {
    image, style
  } = _.get(__contentMap, props.type);

  return (
    <ModalTemplate {..._.omit(props, 'type')}>
      <div className={styles.container}>
        <img
          className={styles.image}
          src={image}
          alt="alert type" />

        <div className={classnames(styles.content, style)}>
          {props.children}
        </div>

        <div className={styles.button}>
          <Button
            colorStyle="secondary"
            onClick={props.onRequestClose}>
            OK
          </Button>
        </div>
      </div>
    </ModalTemplate>
  );
};



const __contentMap = {
  success: {
    image: imgSuccess,
    style: styles.typeSuccess
  },
  error: {
    image: imgCancel,
    style: styles.typeError
  }
};

export type IAlertContentType = keyof typeof __contentMap;
