import * as yup from 'yup';

import {
  useUpsertDistributorMutation as useBaseUpsertDistributorMutation,
  UpsertDistributorInput,
  useRemoveDistrubtorMutation as useBaseRemoveDistributorMutation
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';
import { stateNameSchema } from 'util/validation';



export interface IDistributor {
  id: string;
  name: string;
  distributionStates: string[];
  contactEmails: string[];
}



export const distributorSchema = yup.object({
  name: yup.string().required(),
  distributionStates: yup.array(stateNameSchema.required()).required(),
  contactEmails: yup.array(yup.string().email().required()).required()
}).defined();


export type DistributorInput = Omit<UpsertDistributorInput, 'sASelfDistributor'>

export function useUpsertDistributorMutation() {
  const [
    fn,
    tuple
  ] = useBaseUpsertDistributorMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetDistributorsRefetchQuery({})]
  });

  const fnWrappedMutation = (input: DistributorInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useRemoveDistributorMutation() {
  const [fn, tuple] = useBaseRemoveDistributorMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetDistributorsRefetchQuery({})]
  });

  const fnWrappedMutation = (distributorId: string) => fn({variables: {distributorId}});

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}
