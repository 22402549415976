import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { useAlert } from 'util/hooks';

import styles from './CreateAccountFormSection.module.css';



export interface ICreateAccountFormSectionProps {
  onInviteNewAccount: (email: string, firstName: string, lastName: string) => void;
}

export const CreateAccountFormSection: React.FC<ICreateAccountFormSectionProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const {
    AlertModalProps, setAlert
  } = useAlert();

  function handleCreateAccount() {
    props.onInviteNewAccount(email, firstName, lastName);

    setFirstName('');
    setLastName('');
    setEmail('');

    setAlert('An invite will be sent the the provided email address shortly.');
  }

  return (
    <div className={styles.formSection}>
      <SectionTitle>
        Invite New Employee Account
      </SectionTitle>

      <Form
        ref={refForm}
        onSuccessfulSubmit={handleCreateAccount}
        extraClassName={styles.form}>
        <div className={styles.inputs}>
          <TextInput
            required
            value={firstName}
            onChangeValue={setFirstName}
            ref={_.partial(Form.captureElement, refForm, 'firstName')}
            label="FIRST NAME" />

          <TextInput
            required
            value={lastName}
            onChangeValue={setLastName}
            ref={_.partial(Form.captureElement, refForm, 'lastName')}
            label="LAST NAME" />

          <EmailInput
            required
            value={email}
            onChangeValue={setEmail}
            ref={_.partial(Form.captureElement, refForm, 'email')}
            label="EMAIL" />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            colorStyle='form'
            type='submit'>
            Submit
          </Button>
        </div>
      </Form>

      <AlertModal {...AlertModalProps} />
    </div>
  );
};
