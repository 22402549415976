import _ from 'lodash';

import {
  usePendingApplicationsPageQuery as useBasePendingApplicationsPageQuery,
  useRejectApplicationMutation as useBaseRejectApplicationMutation,
  useApproveApplicationMutation as useBaseApproveApplicationMutation,
  useInviteBreweryMutation as useBaseInviteBreweryMutation,
  InviteBreweryInput
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';



export function usePendingApplicationsPageQuery() {
  const tuple = useBasePendingApplicationsPageQuery();

  const pendingApplications = tuple.data?.pendingApplications;

  return {
    ...tuple,
    data: {
      pendingApplications: _.compact(_.map(pendingApplications, pendingApplication => {
        if (pendingApplication.accountType.name === 'breweryOwner') {
          return {
            id: pendingApplication.id ?? '',
            firstName: pendingApplication.firstName ?? '',
            lastName: pendingApplication.lastName ?? '',
            state: pendingApplication.linkedBrewery?.state ?? '',
            untappd: pendingApplication.linkedBrewery?.untappd ?? '',
            cellNumber: pendingApplication.cell ?? '',
            name: pendingApplication.linkedBrewery?.name ?? ''
          };
        }
        return null;
      }))
    }
  };
}



export function useRejectApplicationMutation() {
  const [
    fn,
    tuple
  ] = useBaseRejectApplicationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.PendingApplicationsPageRefetchQuery({})]
  });


  const fnWrappedMutation = (siteUserId: string) => {
    return fn({variables: {siteUserId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useApproveApplicationMutation() {
  const [
    fn,
    tuple
  ] = useBaseApproveApplicationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.PendingApplicationsPageRefetchQuery({})]
  });


  const fnWrappedMutation = (siteUserId: string) => {
    return fn({variables: {siteUserId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useInviteBreweryMutation() {
  const [
    fn,
    tuple
  ] = useBaseInviteBreweryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.PendingApplicationsPageRefetchQuery({})]
  });


  const fnWrappedMutation = (input: InviteBreweryInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}

