import React from 'react';
import _ from 'lodash';

import { IBrandFormSectionProps } from '../IBrandFormSectionProps';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { Button } from 'components/shared/Button/Button';
import { NumberInput } from 'components/shared/form/TextInput/NumberInput';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import * as dataService from '../../dataService';

import styles from './BrandAccoladesFormSection.module.css';

import imgUntappd from 'assets/untappd-icon.png';
import imgRateBeer from 'assets/ratebeer-icon.png';



export const BrandAccoladesFormSection: React.FC<IBrandFormSectionProps> = props => {

  function handleChangeAward(fieldName: keyof dataService.IAwardFormData, value: any, index: number) {
    const awards = _.cloneDeep(props.formValues.awards);
    awards[index] = {
      ...awards[index],
      [fieldName]: value
    };

    props.onChangeFormValue({awards});
  }

  function addAward() {
    props.onChangeFormValue({
      awards: _.concat(props.formValues.awards, [
        {
          name: '',
          organization: '',
          year: ''
        }
      ])
    });
  }

  return (
    <>
      <SectionTitle>
        Brand Accolades
      </SectionTitle>

      <div className={styles.ratings}>
        <div className={styles.rating}>
          <img
            className={styles.ratingIcon}
            src={imgRateBeer}
            alt='RATEBEER' />

          <NumberInput
            label={`RATEBEER RATING`}
            value={_.toString(props.formValues.ratebeerRating)}
            min={0}
            max={100}
            className={styles.ratingInput}
            onChangeValue={ratebeerRating => props.onChangeFormValue({ratebeerRating: _.toNumber(ratebeerRating)})}
            suffix={`/100`} />
        </div>

        <div className={styles.rating}>
          <img
            className={styles.ratingIcon}
            src={imgUntappd}
            alt='UNTAPPD' />

          <NumberInput
            label={`UNTAPPD RATING`}
            value={_.toString(props.formValues.untappdRating)}
            min={0.00}
            max={5.00}
            step={0.01}
            className={styles.ratingInput}
            onChangeValue={untappdRating => props.onChangeFormValue({untappdRating: _.toNumber(untappdRating)})}
            suffix={`/5`} />
        </div>
      </div>

      <div className={styles.awards}>
        {_.map(props.formValues.awards, (award, index) => (
          <div
            className={styles.award}
            key={index}>
            <TextInput
              onChangeValue={value => handleChangeAward('name', value, index)}
              label="AWARD (E.G. GOLD MEDAL FOR IPA CATEGORY)"
              value={award.name} />

            <TextInput
              onChangeValue={value => handleChangeAward('organization', value, index)}
              label="AWARDING ORGANIZATION"
              value={award.organization} />

            <TextInput
              onChangeValue={value => handleChangeAward('year', value, index)}
              label="AWARD YEAR (E.G 2020)"
              value={award.year} />
          </div>
        ))}

        <Button
          type='button'
          colorStyle='form'
          leftIcon='plus'
          style={{width: 400}}
          onClick={addAward}>
          ADD ANOTHER AWARD
        </Button>
      </div>
    </>
  );
};
