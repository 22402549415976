import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { IFormSectionProps } from '../IFormSectionProps';

import styles from '../FormSection.module.css';



export interface IAccountFormSectionProps extends IFormSectionProps {
  password: string;
  confirmPassword: string;
  untappd: string;

  setPassword: (password: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  setUntappd: (untappd: string) => void;
}

export const AccountFormSection: React.FC<IAccountFormSectionProps> = props => (
  <div className={styles.formSection}>
    <SectionTitle>
      Account
    </SectionTitle>

    <PasswordInput
      required
      label="PASSWORD"
      value={props.password}
      onChangeValue={props.setPassword}
      ref={_.partial(Form.captureElement, props.refForm, 'password')} />

    <PasswordInput
      required
      label="CONFIRM PASSWORD"
      value={props.confirmPassword}
      onChangeValue={props.setConfirmPassword}
      ref={_.partial(Form.captureElement, props.refForm, 'confirmPassword')} />

    <TextInput
      label="UNTAPPD ACCOUNT *"
      value={props.untappd}
      onChangeValue={props.setUntappd}
      ref={_.partial(Form.captureElement, props.refForm, 'untappd')} />
  </div>
);
