import _ from 'lodash';
import moment from 'moment';

import {
  NotificationTypeName,
  useMyNotificationsQuery as useBaseMyNotificationsQuery,
  useMarkNotificationsMutation as useBaseMarkNotificationsMutation
} from 'generated/apiTypes';



export function useMyNotificationsQuery(siteUserId: string) {
  const tuple = useBaseMyNotificationsQuery({variables: {siteUserId}});

  return {
    ...tuple,
    notifications: _.map(tuple.data?.notifications, notif => {

      let text = '';

      if (notif.type.name === 'newOrder') {
        text = `A new order has been placed on ${moment(notif.createdAt).format("MM-DD-YYYY")}`;
      }
      else if (notif.type.name === 'orderLineStatusChange') {
        text = `Order #${notif.placedOrderLine?.order?.readableId}-${notif.placedOrderLine?.readableId} has now been ${notif.orderStatus}.`;
      }

      return {
        id: notif.id,
        isRead: notif.seen,
        title: text,
        type: notif.type.name,
        orderId: notif.placedOrderLine?.order.id ?? undefined
      } as INotification;
    })
  };
}


export function useMarkNotificationsMutation() {
  const [
    fn,
    tuple
  ] = useBaseMarkNotificationsMutation({});
  // Not refetching because we are calling this many times. Will refetch at the end.


  const fnWrappedMutation = (notificationId: string) => {
    return fn({variables: {notificationId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}




export interface INotification {
  id: string;
  title: string;
  isRead: boolean;
  type: NotificationTypeName;

  orderId?: string;
}


