import React from 'react';
import _ from 'lodash';

import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { SectionTitleWarning } from 'components/shared/SectionTitleWarning/SectionTitleWarning';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Button } from 'components/shared/Button/Button';
import { ReauthenticateModal } from './ReauthenticateModal/ReauthenticateModal';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { PhoneNumberInput } from 'components/shared/form/TextInput/PhoneNumberInput';
import { useAlert } from 'util/hooks';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';

import styles from './FormSections.module.css';



export interface IFormData {
  emailAddress: string;
  firstName: string;
  lastName: string;
  cellNumber: string;

  originalEmail: string;
}

export interface IContactInfoFormProps {
  isActing: boolean;
  formData: IFormData;

  onSaveContactInfo: (data: IFormData) => void;
}

export const ContactInfoForm: React.FC<IContactInfoFormProps> = props => {
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, setAlert
  } = useAlert();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');
  const [cellNumber, setCellNumber] = React.useState('');
  const [showReauthenticateModal, setShowReauthenticateModal] = React.useState(false);
  const [originalEmail, setOriginalEmail] = React.useState('');

  React.useEffect(() => {
    setFirstName(props.formData.firstName);
    setLastName(props.formData.lastName);
    setEmailAddress(props.formData.emailAddress);
    setCellNumber(props.formData.cellNumber);

    setOriginalEmail(props.formData.emailAddress);
  }, [props.formData]);


  async function handlePossibleReAuth() {
    try {
      if (props.formData.originalEmail !== emailAddress) {
        setShowReauthenticateModal(true);
      }
      else {
        await handleUpdateContactInfo();
      }
    } catch (err) {
      setAlert("There was a problem updating your contact information", 'error');
    }
  }

  async function handleUpdateContactInfo() {
    await props.onSaveContactInfo({
      firstName,
      lastName,
      emailAddress,
      cellNumber,
      originalEmail
    });

    setShowReauthenticateModal(false);
  }


  return (
    <div className={styles.formSection}>
      <SectionTitle>
        My Contact Info {props.isActing && (<SectionTitleWarning>Note: You cannot change the email on behalf of the user</SectionTitleWarning>)}
      </SectionTitle>

      <Form
        ref={refForm}
        extraClassName={styles.form}
        onSuccessfulSubmit={handlePossibleReAuth}>
        <div className={styles.inputContainer}>
          <TextInput
            value={firstName}
            onChangeValue={setFirstName}
            ref={_.partial(Form.captureElement, refForm, 'firstName')}
            label="FIRST NAME" />

          <TextInput
            value={lastName}
            onChangeValue={setLastName}
            ref={_.partial(Form.captureElement, refForm, 'lastName')}
            label="LAST NAME" />
        </div>

        <div className={styles.inputContainer}>
          <EmailInput
            disabled={props.isActing}
            value={emailAddress}
            onChangeValue={setEmailAddress}
            ref={_.partial(Form.captureElement, refForm, 'emailAddress')}
            label="YOUR EMAIL" />

          <PhoneNumberInput
            value={cellNumber}
            onChangeValue={setCellNumber}
            ref={_.partial(Form.captureElement, refForm, 'cellNumber')}
            label="YOUR CELL NUMBER" />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            colorStyle="form">
            SAVE
          </Button>
        </div>
      </Form>

      <ReauthenticateModal
        isOpen={showReauthenticateModal}
        oldEmailAddress={originalEmail}
        newEmailAddress={emailAddress}
        onAuthenticated={handleUpdateContactInfo}
        onRequestClose={() => setShowReauthenticateModal(false)} />

      <AlertModal {...AlertModalProps} />
    </div>
  );
};
