import * as yup from 'yup';
import _ from 'lodash';

import {
  useBrandFormPageOptionsQuery as useBaseBrandFormPageOptionsQuery,
  useUpsertBrandMutation as useBaseUpsertBrandMutation,
  UpsertBrandInput,
  PackingFormatInput,
  AwardInput,
  useLoadBrandQuery as useBaseLoadBrandQuery,
  useCreateBrandCategoryMutation as useBaseCreateBrandCategoryMutation,
  CreateBrandCategoryInput,
  PackingFormatName
} from 'generated/apiTypes';

import { cents2EditableDollarsAndCents } from 'util/currency';
import { stateNameSchema } from 'util/validation';
import * as refetchQueries from 'util/refetchQueries';



export function useBrandFormPageOptionsQuery(id: string) {
  const tuple = useBaseBrandFormPageOptionsQuery({variables: {id}});

  return {
    ...tuple,
    data: {
      disableListing: tuple.data?.brewery?.ownedBy.status.name !== 'approved' ?? true,
      brandCategories: _.map(tuple.data?.brewery?.brandCategories ?? [], category => {
        return ({
          value: category.id,
          label: category.name
        });
      }) ?? [],
      volumeAvailabilities: _.map(tuple.data?.appData.volumeAvailabilities, vol => {
        return {
          value: vol.name,
          label: vol.displayName
        };
      }) ?? [],
      packingFormats: _.map(tuple.data?.appData.packingFormats, vol => {
        return {
          value: vol.name,
          label: vol.displayName
        };
      }) ?? []
    }
  };
}


export function useLoadBrandQuery(id: string) {
  const tuple = useBaseLoadBrandQuery({variables: {id}});

  return {
    ...tuple,
    data: {
      listed: tuple.data?.brand?.listed ?? false,
      name: tuple.data?.brand?.name ?? '',
      style: tuple.data?.brand?.style ?? '',
      category: _.map(tuple.data?.brand?.category ?? [], category => category.id),
      ibu: tuple.data?.brand?.ibu ?? 0,
      abv: tuple.data?.brand?.abv ?? 0,
      uniqueStyle: tuple.data?.brand?.uniqueStyle ?? false,
      volumeAvailability: tuple.data?.brand?.volumeAvailability.name ?? 'unknown',
      brandImage: tuple.data?.brand?.brandImage ?? '',
      bannerImage: tuple.data?.brand?.bannerImage ?? '',
      ratebeerRating: tuple.data?.brand?.ratebeerRating ?? 0,
      untappdRating: tuple.data?.brand?.untappdRating ?? 0,
      graphics: _.map(tuple.data?.brand?.graphics, graphic => graphic.url) ?? [],
      untappdId: tuple.data?.brand?.untappdId ?? '',
      description: tuple.data?.brand?.description ?? '',
      formats: _.map(tuple.data?.brand?.formats, format => {
        return {
          name: format.packingFormat.name,
          lowTaxMinPtr: _.toNumber(cents2EditableDollarsAndCents(format.lowTaxMinPtr)),
          listed: format.listed,
          state: format.state
        };
      }) ?? [],
      awards: _.map(tuple.data?.brand?.awards, award => {
        return {
          name: award.name,
          organization: award.organization,
          year: award.year
        };
      }) ?? []
    }
  };
}



export function useUpsertBrandMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseUpsertBrandMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.BrandsPageRefetchQuery({id})]
  });

  const fnWrappedMutation = (input: UpsertBrandInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useCreateBrandCategoryMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseCreateBrandCategoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.BrandFormPageOptionsRefetchQuery({id})]
  });

  const fnWrappedMutation = (input: CreateBrandCategoryInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}




export const brandPackageFormatSchema = yup.object({
  name: yup.string().required().label('Packaging Format Name'),
  lowTaxMinPtr: yup.number().min(0).required().label('Packaging Format Low Tax Minimum PTR'),
  listed: yup.boolean().required().label('Packaging Format Listing Status'),
  state: stateNameSchema.required().label('Packaging Format Available States')
}).defined();

export const brandAwardSchema = yup.object({
  name: yup.string().required(),
  organization: yup.string().required(),
  year: yup.string().length(4).required()
}).defined();

export const brandSchema = yup.object({
  listed: yup.boolean().required().label('Brand Listing Status'),
  name: yup.string().required().label('Brand Name'),
  description: yup.string().required().label('Brand Description'),
  style: yup.string().required().label('Brand Style'),
  category: yup.string().required().label('Brand Category'),
  ibu: yup.number().required().label('Brand IBU'),
  abv: yup.number().required().label('Brand ABV'),
  uniqueStyle: yup.boolean().required().label('Brand Unique Style'),
  volumeAvailability: yup.string().oneOf([
    'unknown',
    'limitedSupply',
    'brewToOrder',
    'noLimit'
  ]).required().label('Brand Volume Availability'),
  brandImage: yup.string().optional().label('Brand Image'),
  bannerImage: yup.string().optional().label('Banner Image'),
  graphics: yup.array(yup.string()).defined().label('Brand Images'),
  formats: yup.array(brandPackageFormatSchema).defined().label('Packaging Format'),
  ratebeerRating: yup.number().required().label('Brand RateBeer Rating'),
  untappdRating: yup.number().required().label('Brand Untappd Rating'),
  awards: yup.array(brandAwardSchema).defined().label('Brand Awards'),
  untappdId: yup.string().optional().label('Untappd Brand ID')
}).defined();

export type IBrandFormData = Omit<UpsertBrandInput, 'breweryId' | 'brandId'>;
export type IPackingFormData = PackingFormatInput;
export type IPackingFormatName = PackingFormatName;
export type IAwardFormData = Omit<AwardInput, 'awardId'>;


export const brandInitialState: IBrandFormData = Object.freeze({
  listed: false,
  name: '',
  style: '',
  description: '',
  category: [],
  ibu: 0,
  abv: 0,
  uniqueStyle: false,
  volumeAvailability: 'unknown',
  brandImage: '',
  bannerImage: '',
  graphics: [],
  formats: [],
  ratebeerRating: 0,
  untappdRating: 0,
  untappdId: '',
  awards: [
    {
      name: '',
      organization: '',
      year: new Date().getFullYear().toString()
    }
  ]
});

export function trimBrandFormValues(formValues: IBrandFormData) {
  const formData = _.cloneDeep(formValues);

  formData.graphics = _.compact(formData.graphics);
  formData.awards = _.filter(formData.awards, award => _.every(award, value => !_.isEmpty(value)));

  return formData;
}
