import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { Button } from 'components/shared/Button/Button';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { InviteBreweryInput } from 'generated/apiTypes';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { useAlert } from 'util/hooks';

import styles from './InviteBrewerFormSection.module.css';



export interface InviteBrewerFormSectionProps {
  onSendInvite: (data: InviteBreweryInput) => void;
}

export const InviteBrewerFormSection: React.FC<InviteBrewerFormSectionProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [contactName, setContactName] = React.useState('');
  const [breweryName, setBreweryName] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');

  const {
    AlertModalProps, setAlert
  } = useAlert();

  function handleInviteBrewer() {
    props.onSendInvite({
      contactName,
      breweryName,
      emailAddress
    });

    setContactName('');
    setBreweryName('');
    setEmailAddress('');

    setAlert('An invite will be sent the the provided email address shortly.');
  }

  return (
    <>
      <div className={styles.formSection}>
        <SectionTitle>
          Invite New Brewer Account
        </SectionTitle>

        <Form
          ref={refForm}
          onSuccessfulSubmit={handleInviteBrewer}
          extraClassName={styles.form}>
          <div className={styles.inputs}>
            <TextInput
              onChangeValue={setContactName}
              value={contactName}
              label='CONTACT NAME'
              ref={_.partial(Form.captureElement, refForm, 'contactName')}
              required />

            <TextInput
              onChangeValue={setBreweryName}
              value={breweryName}
              label='BREWERY NAME'
              ref={_.partial(Form.captureElement, refForm, 'breweryName')}
              required />

            <EmailInput
              onChangeValue={setEmailAddress}
              value={emailAddress}
              label='EMAIL ADDRESS'
              ref={_.partial(Form.captureElement, refForm, 'emailAddress')}
              required />
          </div>

          <div className={styles.buttonContainer}>
            <Button
              colorStyle='form'
              type='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </>
  );
};
