import React from 'react';

import { HeaderNav } from '../HeaderNav/HeaderNav';
import { SideNav } from '../SideNav/SideNav';
import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { VersionIndicator } from 'components/shared/VersionIndicator/VersionIndicator';
import { GetCurrentUserHook } from 'util/hooks';

import styles from './PageTemplate.module.css';



export interface IPageTemplateProps {
  headerTitle?: React.ReactNode;

  loading?: boolean;
}

export const PageTemplate: React.FC<IPageTemplateProps> = props => {
  const queryTuple = GetCurrentUserHook();

  return (
    <div className={styles.container}>
      {props.loading && (
        <LoadingOverlay />
      )}

      <SideNav
        hasSuperAccess={queryTuple.data.currentUser.hasSuperAccess}
        isActing={queryTuple.data.currentUser.isActing} />

      <main className={styles.main}>
        <HeaderNav
          currentUserId={queryTuple.data.currentUser.realUserId}
          accountStatus={queryTuple.data.currentUser.status}
          isActing={queryTuple.data.currentUser.isActing}
          actingAsBreweryName={queryTuple.data.currentUser.actingAs.breweryName} />

        {props.headerTitle}

        <div className={styles.content}>
          {props.children}
        </div>
      </main>

      <VersionIndicator />
    </div>
  );
};
