import React from 'react';
import _ from 'lodash';
import currency from 'currency.js';

import { IBrandFormSectionProps } from '../IBrandFormSectionProps';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { NumberInput } from 'components/shared/form/TextInput/NumberInput';
import { Button } from 'components/shared/Button/Button';
import { Checkbox } from 'components/shared/form/Checkbox/Checkbox';
import { Select } from 'components/shared/form/Select/Select';
import { getStateNameById, getStateNameOptions } from 'util/stateNames';
import * as optionsUtil from 'util/options';
import * as arrayUtil from 'util/array';

import { IPackingFormData, IPackingFormatName } from '../../dataService';

import styles from './PackagingFormSection.module.css';



export interface IPackagingFormSectionProps extends IBrandFormSectionProps {
  availablePackingFormats: optionsUtil.IOption[];
  canAlterListingStatus: boolean;
}

export const PackagingFormSection: React.FC<IPackagingFormSectionProps> = props => {
  function handleChange(fieldName: keyof IPackingFormData, value: any, format: IPackingFormData) {
    const formats = _.cloneDeep(props.formValues.formats);
    const index = formats.findIndex(f => format.name === f.name && format.state === f.state);
    formats[index] = {
      ...formats[index],
      [fieldName]: value
    };

    props.onChangeFormValue({formats});
  }

  function handlePackingFormatChange(newFormatName: IPackingFormatName, prevFormatName: IPackingFormatName) {
    const newFormats = _.map(props.formValues.formats, format => {
      if (format.name === prevFormatName) {
        format.name = newFormatName;
      }
      return format;
    });
    props.onChangeFormValue({ formats: newFormats });
  }

  function handleStateChange(newStates: string[], oldStates: string[], formatName: IPackingFormatName) {
    const addedState = arrayUtil.diff(newStates, oldStates);
    const removedState = arrayUtil.diff(oldStates, newStates);

    let formats = _.cloneDeep(props.formValues.formats);
    if (removedState.length > 0) {
      formats = formats.filter(f => {
        return f.name !== formatName || !removedState.includes(f.state);
      });
    }
    if (addedState.length > 0) {
      const addedFormats = addedState.map(s => {
        return {
          name: formatName,
          lowTaxMinPtr: 0,
          listed: false,
          state: s
        };
      });
      formats = formats.concat(addedFormats);
    }
    props.onChangeFormValue({ formats });
  }

  function handlePTRChange(ptr: any, format: IPackingFormData) { // TODO: separate PTR displaying string and actual number
    const formats = _.cloneDeep(props.formValues.formats);
    const index = formats.findIndex(f => format.name === f.name && format.state === f.state);
    formats[index] = {
      ...formats[index],
      lowTaxMinPtr: ptr
    };
    props.onChangeFormValue({formats});
  }

  function handleFormatNumber(format: IPackingFormData) {
    const formats = _.cloneDeep(props.formValues.formats);
    const index = formats.findIndex(f => format.name === f.name && format.state === f.state);
    const finalPtr = currency(formats[index].lowTaxMinPtr, { precision: 0 }).value;

    formats[index] = {
      ...formats[index],
      lowTaxMinPtr: finalPtr
    };

    props.onChangeFormValue({formats});
  }

  function addPackaging() {
    if (props.formValues.formats.find(f => f.name === 'unknown' || f.state === '')) {
      return;
    }

    props.onChangeFormValue({
      formats: _.concat(props.formValues.formats, [
        {
          name: 'unknown',
          lowTaxMinPtr: 0,
          listed: false,
          state: ''
        }
      ])
    });
  }

  const groupedFormats = React.useMemo(() => {
    return _.groupBy(props.formValues.formats, format => format.name);
  }, [props.formValues.formats]);

  return (
    <>
      <SectionTitle>
        Packaging & Pricing
      </SectionTitle>

      {_.map(Object.keys(groupedFormats), (formatName: IPackingFormatName, index: number) => (
        <div
          key={index}
          className={styles.packagingRow}>

          <div className={styles.packagingColumn}>
            <Select
              required
              options={props.availablePackingFormats}
              value={optionsUtil.getOptionFromValue(props.availablePackingFormats, formatName)}
              onChange={option => handlePackingFormatChange(optionsUtil.getValueFromOption(option), formatName)}
              label={`PACKAGING FORMAT ${index + 1}`} />
          </div>

          <div className={styles.packagingColumn}>
            <Select
              required
              isMulti
              options={getStateNameOptions()}
              value={optionsUtil.getOptionsFromValues(getStateNameOptions(), _.map(groupedFormats[formatName], format => format.state)) as any}
              onChange={options => {
                handleStateChange(optionsUtil.getValuesFromOptions(options), _.map(groupedFormats[formatName], format => format.state), formatName);
              }}
              label='AVAILABLE IN' />
          </div>

          {_.map(groupedFormats[formatName], (format, index) => {
            return format.state === ''
              ? null
              : (
                <div key={`${format.name}-${index}`} className={styles.packagingColumn}>
                  <div className={styles.ptrBlock}>
                    <div className={styles.ptrInputs}>
                      <NumberInput
                        required
                        value={format.lowTaxMinPtr.toString()}
                        onChangeValue={ptr => handlePTRChange(ptr, format)}
                        onBlur={() => handleFormatNumber(format)}
                        prefix="$"
                        className={styles.ptrInput}
                        label={`PTR in ${getStateNameById(format.state)}`} />
                    </div>

                    <div
                      className={styles.availabilityContainer}
                      style={{flexBasis: 200}}>
                      <span className={styles.label}>
                    Available On Marketplace
                      </span>

                      <div className={styles.availability}>
                        <Checkbox
                          className={styles.availabilityCheckbox}
                          disabled={!props.canAlterListingStatus}
                          onChangeValue={value => handleChange('listed', value, format)}
                          checked={format.listed} />
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}

        </div>
      ))}

      <Button
        type='button'
        colorStyle='form'
        leftIcon='plus'
        style={{width: 400}}
        onClick={addPackaging}>
        ADD ANOTHER PACKAGING FORMAT
      </Button>
    </>
  );
};
