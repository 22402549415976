import _ from 'lodash';

import {
  useGetSuperEmployeesQuery as useBaseGetSuperEmployeesQuery,
  useInviteEmployeeMutation as useBaseInviteEmployeeMutation,
  useDeleteUserMutation as useBaseDeleteUserMutation,
  InviteEmployeeInput,
  DeleteUserInput
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';



export function useGetSuperEmployeesQuery() {
  const tuple = useBaseGetSuperEmployeesQuery();

  return {
    ...tuple,
    data: {
      employees: _.map(tuple.data?.appData?.superEmployees ?? [], employee => {
        return {
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          email: employee.email
        };
      })
    }
  };
}



export function useInviteEmployeeMutation() {
  const [
    fn,
    tuple
  ] = useBaseInviteEmployeeMutation();

  const fnWrappedMutation = (input: IInviteEmployeeInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useDeleteUserMutation() {
  const [
    fn,
    tuple
  ] = useBaseDeleteUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetSuperEmployeesRefetchQuery({})]
  });

  const fnWrappedMutation = (input: IDeleteUserInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}


export type IInviteEmployeeInput = InviteEmployeeInput;
export type IDeleteUserInput = DeleteUserInput;
