import React from 'react';

import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';

import styles from './DistributorSubmittedPage.module.css';



export const DistributorSubmittedPage: React.FC = () => {


  return (
    <OnboardingPageTemplate>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Oh no,
            <br />
            Sorry!
          </h1>

          <span className={styles.subtitle}>
            <br />
            <br />
            We can&apos;t process your application at the moment.
            <br />
            <br />
            A copy of the information you submitted has been sent to our team, someone will be in touch as soon as we have verified the provided distributor information.
            <br />
          </span>
        </div>


      </div>
    </OnboardingPageTemplate>
  );
};
