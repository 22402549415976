import _ from 'lodash';

import firebase from 'firebase';
import * as env from './environment';



let __firebaseInitialized = false;

export function init() {
  if (__firebaseInitialized) {
    return;
  }

  const config = env.getConfig();

  if (!_.isEmpty(firebase.apps)) {
    return;
  }

  const firebaseApp = firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId
  }, "brew-pipline-dev");

  if (!firebaseApp) {
    throw new Error("FIREBASE_APP_MISSING");
  }

  __firebaseInitialized = true;
}



export function getApp() {
  if (_.isEmpty(firebase.apps)) {
    throw new Error('NOT_INITIALIZED');
  }

  return firebase.apps[0];
}
