import React from 'react';
import _ from 'lodash';

import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { BreweryInfoForm } from './BreweryInfoForm/BreweryInfoForm';
import { GetCurrentUserHook } from 'util/hooks';
import { useToasts } from 'react-toast-notifications';

import * as dataService from './dataService';




export const ProfilePage: React.FC = () => {
  const { addToast } = useToasts();
  const currentUserQueryTuple = GetCurrentUserHook();
  const queryTuple = dataService.useMyBrewerProfileQuery(currentUserQueryTuple.data.currentUser.actingAs.breweryId ?? currentUserQueryTuple.data.currentUser.breweryId);
  const [fnUpdateBrewery, updateBreweryTuple] = dataService.useUpdateBreweryInformationMutation(currentUserQueryTuple.data.currentUser.actingAs.breweryId ?? currentUserQueryTuple.data.currentUser.breweryId);

  const isLoading = _.some([
    currentUserQueryTuple,
    queryTuple,
    updateBreweryTuple
  ], tuple => tuple.loading);

  return (
    <PageTemplate
      loading={isLoading}
      headerTitle={(
        <HeaderTitle
          title="My Brewer Profile"
          icon="profile" />
      )}>

      <BreweryInfoForm
        formData={queryTuple.data}
        onSaveFormData={async values => {
          try {
            await fnUpdateBrewery({
              id: queryTuple.data.id,
              ...values
            });

            addToast('My Brewer Profile saved!', {appearance: 'success'});
          }
          catch (error) {
            addToast(error, {appearance: 'error'});
          }
        }} />

    </PageTemplate>
  );
};
