import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import {
  useMyRetailersPageQuery as useBaseMyRetailersPageQuery,
  useInviteRetailerMutation as useBaseInviteRetailerMutation,
  InviteRetailerInput
} from 'generated/apiTypes';
import {
  getOrderStatus,
  getOrderStatusEnum
} from 'util/orderStatus';

import { IExportColumnDef } from 'util/csv';
import { phoneNumber2ReadableFormat } from 'util/format';
import { sumBy } from 'util/currency';



export function useMyRetailersPageQuery(breweryId: string) {
  const tuple = useBaseMyRetailersPageQuery({variables: {breweryId}});

  const retailers = _.map(tuple.data?.retailersForBrewery);

  return {
    ...tuple,
    data: {
      retailers: _.map(retailers, retailer => {
        const totalMinPtr = sumBy(_.flatten(_.map(retailer.retailerInformation?.placedOrders, order => _.map(order.lines, line => line.minPtr))));

        const orders = _.orderBy(retailer.retailerInformation?.placedOrders, order => order.placedAt, 'desc');
        const lastOrder = _.first(orders);
        const recentOrders = _.take(orders, 2);

        const lastOrderMinPtr = sumBy(_.map(lastOrder?.lines, line => line.minPtr));

        const orderedBrands = _.map(_.uniqBy(_.flatMap(_.map(orders, order => {
          return _.map(order.lines, line => line.brand);
        })), 'name'), x => {return x.name;});

        return {
          id: retailer.id ?? '',
          name: retailer.retailerInformation?.chainInformation?.name ?? retailer.retailerInformation?.individualInformation?.locationName ?? '',
          totalOrderValue: `${totalMinPtr}`,
          lastOrderDate: lastOrder ? moment(lastOrder.placedAt).format('MM-DD-YYYY') : '',
          lastBrandsOrdered: _.toString(_.take(orderedBrands, 2)),
          lastOrderValue: `${lastOrderMinPtr}`,
          purchaseStatesArray: retailer.retailerInformation?.chainInformation?.purchaseStates ?? [retailer.retailerInformation?.individualInformation?.state ?? ''],
          purchaseStates: _.toString(retailer.retailerInformation?.chainInformation?.purchaseStates) ?? retailer.retailerInformation?.individualInformation?.state ?? '',
          contactName: `${retailer.firstName} ${retailer.lastName}`,
          cellNumber: retailer.cell ?? '',
          email: retailer.email ?? '',
          address: retailer.retailerInformation?.individualInformation ? {
            locationName: retailer.retailerInformation?.individualInformation?.locationName ?? '',
            street: retailer.retailerInformation?.individualInformation?.street ?? '',
            city: retailer.retailerInformation?.individualInformation?.city ?? '',
            state: retailer.retailerInformation?.individualInformation?.state ?? '',
            zip: retailer.retailerInformation?.individualInformation?.zip ?? ''
          } : undefined,
          orders: _.map(recentOrders, order => {
            return {
              id: order.id ?? '',
              readableId: order.readableId ?? '',
              placedAt: moment(order.placedAt).format('MM-DD-YYYY') ?? '',
              totalMinPtr: sumBy(_.map(lastOrder?.lines, line => line.minPtr)),
              statusName: getOrderStatusEnum(_.map(order.lines, line => line.status.name)),
              statusDisplayName: getOrderStatus(_.map(order.lines, line => line.status.name))
            };
          })
        };
      })
    }
  };
}



export function useInviteRetailerMutation() {
  const [
    fn,
    tuple
  ] = useBaseInviteRetailerMutation();


  const fnWrappedMutation = (input: InviteRetailerInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}

export interface IRetailer {
  id: string;
  name: string;
  totalOrderValue: string;
  lastOrderDate: string;
  lastBrandsOrdered: string;
  lastOrderValue: string;
  purchaseStatesArray: string[]
  purchaseStates: string;
  contactName: string;
  cellNumber: string;
  email: string;
  orders: {
    id: string;
    readableId: string;
    placedAt: string;
    totalMinPtr: string;
    statusName: string;
    statusDisplayName: string;
  }[];
  address?: {
    locationName: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  }
}

export const inviteIndividualRetailerSchema = yup.object({individualEmailAddress: yup.string().email().required().label("Individual Invite Email Address")}).defined();

export const inviteBatchRetailersSchema = yup.object({batchEmailAddresses: yup.array().of(yup.string().email().required().label("Bulk Invite Email Addresses")).required()}).defined();

export const exportCsvColumnDefs: IExportColumnDef<IRetailer>[] = [
  {
    label: 'Retailer Name',
    value: d => d.name
  }, {
    label: 'Contact Name',
    value: d => d.contactName
  }, {
    label: 'Contact Phone Number',
    value: d => phoneNumber2ReadableFormat(d.cellNumber)
  }, {
    label: 'States',
    value: d => _.join(d.purchaseStatesArray, '; ')
  }
];
