import React from 'react';
import _ from 'lodash';

import {
  default as ReactCreatableSelect,
  Props as ReactSelectProps
} from 'react-select/creatable';

import { IOption } from 'util/options';
import { SelectStyles } from './SelectStyles';

import styles from './Select.module.css';



export interface ICreatableSelectProps extends ReactSelectProps<IOption, true> {
  label?: string;
}

export const CreatableSelect: React.FC<ICreatableSelectProps> = React.forwardRef<ReactCreatableSelect<IOption>, ICreatableSelectProps>((props, ref) => (
  <div className={styles.container}>
    {props.label && (
      <label className={styles.label}>
        {props.label}
      </label>
    )}

    <ReactCreatableSelect
      {..._.omit(props, ['label'])}
      ref={ref}
      styles={SelectStyles} />
  </div>
));



CreatableSelect.defaultProps = {};
