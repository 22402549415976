import React from 'react';

import styles from './SummaryCard.module.css';



export interface ISummaryCardProps {
  value: string;
  large?: boolean;
}

export const SummaryCard: React.FC<ISummaryCardProps> = props => {
  return (
    <div className={`${styles.container} ${props.large ? styles.large : ''}`}>
      <div className={styles.value}>
        {props.value}
      </div>

      <div className={styles.title}>
        {props.children}
      </div>
    </div>
  );
};
