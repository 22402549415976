import {
  GetCurrentUserQueryVariables,
  GetCurrentUserDocument,
  GetSuperEmployeesQueryVariables,
  GetSuperEmployeesDocument,
  PendingApplicationsPageQueryVariables,
  PendingApplicationsPageDocument,
  MyBrewerProfileQueryVariables,
  MyBrewerProfileDocument,
  MyDistributorsQueryVariables,
  MyDistributorsDocument,
  BrandFormPageOptionsQueryVariables,
  BrandFormPageOptionsDocument,
  BrandsPageQueryVariables,
  BrandsPageDocument,
  ApprovedBreweriesPageQueryVariables,
  ApprovedBreweriesPageDocument,
  GetAllDistributorsQueryVariables,
  GetAllDistributorsDocument,
  OrdersSuperAdminPageQueryVariables,
  OrdersSuperAdminPageDocument,
  SuperAdminRetailersPageQueryVariables,
  SuperAdminRetailersPageDocument,
  MyBrewPipelinePageQueryVariables,
  MyBrewPipelinePageDocument,
  MyNotificationsQueryVariables,
  MyNotificationsDocument,
  GetSubscriptionQueryVariables,
  GetSubscriptionDocument
} from 'generated/apiTypes';

import { DocumentNode } from 'graphql';



export interface IRefetchQuery<TVariables> {
  query: DocumentNode;
  variables: TVariables;
}

export type FnGenerateRefetchQuery<TVariables> = (variables: TVariables) => IRefetchQuery<TVariables>;



export const GetCurrentUserRefetchQuery: FnGenerateRefetchQuery<GetCurrentUserQueryVariables> = variables => ({
  query: GetCurrentUserDocument,
  variables
});




export const GetSuperEmployeesRefetchQuery: FnGenerateRefetchQuery<GetSuperEmployeesQueryVariables> = variables => ({
  query: GetSuperEmployeesDocument,
  variables
});



export const PendingApplicationsPageRefetchQuery: FnGenerateRefetchQuery<PendingApplicationsPageQueryVariables> = variables => ({
  query: PendingApplicationsPageDocument,
  variables
});



export const MyBrewerProfileRefetchQuery: FnGenerateRefetchQuery<MyBrewerProfileQueryVariables> = variables => ({
  query: MyBrewerProfileDocument,
  variables
});



export const MyDistributorsRefetchQuery: FnGenerateRefetchQuery<MyDistributorsQueryVariables> = variables => ({
  query: MyDistributorsDocument,
  variables
});



export const BrandFormPageOptionsRefetchQuery: FnGenerateRefetchQuery<BrandFormPageOptionsQueryVariables> = variables => ({
  query: BrandFormPageOptionsDocument,
  variables
});



export const BrandsPageRefetchQuery: FnGenerateRefetchQuery<BrandsPageQueryVariables> = variables => ({
  query: BrandsPageDocument,
  variables
});



export const ApprovedBreweriesPageRefetchQuery: FnGenerateRefetchQuery<ApprovedBreweriesPageQueryVariables> = variables => ({
  query: ApprovedBreweriesPageDocument,
  variables
});



export const GetDistributorsRefetchQuery: FnGenerateRefetchQuery<GetAllDistributorsQueryVariables> = variables => ({
  query: GetAllDistributorsDocument,
  variables
});


export const OrdersSuperAdminPageRefetchQuery: FnGenerateRefetchQuery<OrdersSuperAdminPageQueryVariables> = variables => ({
  query: OrdersSuperAdminPageDocument,
  variables
});



export const SuperAdminRetailersPageRefetchQuery: FnGenerateRefetchQuery<SuperAdminRetailersPageQueryVariables> = variables => ({
  query: SuperAdminRetailersPageDocument,
  variables
});


export const MyBrewPipelinePageRefetchQuery: FnGenerateRefetchQuery<MyBrewPipelinePageQueryVariables> = variables => ({
  query: MyBrewPipelinePageDocument,
  variables
});



export const MyNotificationsRefetchQuery: FnGenerateRefetchQuery<MyNotificationsQueryVariables> = variables => ({
  query: MyNotificationsDocument,
  variables
});



export const GetSubscriptionRefetchQuery: FnGenerateRefetchQuery<GetSubscriptionQueryVariables> = variables => ({
  query: GetSubscriptionDocument,
  variables
});
