import _ from 'lodash';
import * as yup from 'yup';

import {
  useSuperAdminRetailersPageQuery as useBaseSuperAdminRetailersPageQuery,
  useToggleLoginPermittedMutation as useBaseToggleLoginPermittedMutation,
  useUpdateRetailerInformationMutation as useBaseUpdateRetailerInformationMutation,
  UpdateRetailerInput
} from 'generated/apiTypes';

import {  phoneNumberSchema } from 'util/validation';
import {
  availableRetailerChainTypes,
  availableRetailerTypes
} from 'util/enums';

import * as refetchQueries from 'util/refetchQueries';



export function useSuperAdminRetailersPageQuery(filter: string) {
  const tuple = useBaseSuperAdminRetailersPageQuery({variables: {filter}});

  const retailers = tuple.data?.platformRetailers;

  return {
    ...tuple,
    data: {
      retailers: _.map(retailers, retailer => {
        return {
          id: retailer.id ?? '',
          loginPermitted: retailer.loginPermitted ?? false,
          firstName: retailer.firstName ?? '',
          lastName: retailer.lastName ?? '',
          retailerName: retailer.retailerInformation?.chainInformation?.name ?? retailer.retailerInformation?.individualInformation?.locationName ?? '',
          address: _.join(_.compact([
            retailer.retailerInformation?.individualInformation?.street ?? null,
            retailer.retailerInformation?.individualInformation?.city ?? null,
            retailer.retailerInformation?.individualInformation?.state ?? null,
            retailer.retailerInformation?.individualInformation?.zip ?? null
          ]),
          ', '),
          purchaseStates: _.toString(retailer.retailerInformation?.chainInformation?.purchaseStates) ?? '',
          emailAddress: retailer.email ?? '',
          cellNumber: retailer.cell ?? '',
          type: retailer.retailerInformation?.retailerType.name ?? 'unknown',
          chainType: retailer.retailerInformation?.retailerChainStatus.name ?? 'unknown',
          website: retailer.retailerInformation?.individualInformation?.website ?? retailer.retailerInformation?.chainInformation?.website ?? '',
          updateInput: {
            siteUserId: retailer.id ?? '',
            firstName: retailer.firstName ?? '',
            lastName: retailer.lastName ?? '',
            cellNumber: retailer.cell ?? '',
            retailerType: retailer.retailerInformation?.retailerType.name ?? 'unknown',
            retailerChainStatus: retailer.retailerInformation?.retailerChainStatus.name ?? 'unknown',
            chainInformation: {
              name: retailer.retailerInformation?.chainInformation?.name ?? '',
              purchaseStates: retailer.retailerInformation?.chainInformation?.purchaseStates ?? [],
              website: retailer.retailerInformation?.chainInformation?.website ?? ''
            },
            individualInformation: {
              locationName: retailer.retailerInformation?.individualInformation?.locationName ?? '',
              street: retailer.retailerInformation?.individualInformation?.street ?? '',
              city: retailer.retailerInformation?.individualInformation?.city ?? '',
              state: retailer.retailerInformation?.individualInformation?.state ?? '',
              zip: retailer.retailerInformation?.individualInformation?.zip ?? '',
              website: retailer.retailerInformation?.individualInformation?.website ?? ''
            }
          }
        };
      })
    }
  };
}



export function useToggleLoginPermittedMutation() {
  const [
    fn,
    tuple
  ] = useBaseToggleLoginPermittedMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.SuperAdminRetailersPageRefetchQuery({})]
  });


  const fnWrappedMutation = (siteUserId: string) => {
    return fn({variables: {siteUserId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useUpdateRetailerInformationMutation() {
  const [
    fn,
    tuple
  ] = useBaseUpdateRetailerInformationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.SuperAdminRetailersPageRefetchQuery({})]
  });


  const fnWrappedMutation = (input: UpdateRetailerInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export interface IRetailer {
  id: string;
  loginPermitted: boolean;
  firstName: string;
  lastName: string;
  retailerName: string;
  address: string;
  purchaseStates: string;
  emailAddress: string;
  cellNumber: string;
  chainName: string;
  chainType: string;
  type: string;
  website: string;
  updateInput: IRetailerUpdate;
}


export type IRetailerUpdate = Omit<UpdateRetailerInput, "profilePicture">


export const retailerSchema = yup.object().shape({
  siteUserId: yup.string().required(),
  firstName:  yup.string().required(),
  lastName:  yup.string().required(),
  cellNumber: phoneNumberSchema.required(),
  retailerType: yup.string().oneOf(_.map(availableRetailerTypes, 'value')).required(),
  retailerChainStatus: yup.string().oneOf(_.map(availableRetailerChainTypes, 'value')).required(),

  chainInformation: yup.object().when('retailerChainStatus', {
    is: retailerType => retailerType === 'chain',
    then: yup.object().shape({
      name: yup.string().required(),
      purchaseStates: yup.array().of(yup.string()).required(),
      website: yup.string().required()
    }).required(),
    otherwise: null
  }).nullable(),

  individualInformation: yup.object().when('retailerChainStatus', {
    is: retailerType => retailerType === 'individual',
    then: yup.object().shape({
      locationName: yup.string().required(),
      street: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      zip: yup.string().required(),
      website: yup.string().required()
    }).required(),
    otherwise: null
  }).nullable()
}).defined();
