import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Button } from 'components/shared/Button/Button';
import { TextAreaInput } from 'components/shared/form/TextAreaInput/TextAreaInput';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { validator } from 'util/validation';
import { useAlert } from 'util/hooks';
import { readCsv } from 'util/csv';

import * as dataService from '../dataService';

import styles from './InviteRetailersModal.module.css';



export interface IInviteRetailersModalProps extends IModalTemplateProps {
  retailer: dataService.IRetailer | undefined;
  onSendInvite: (retailerEmails: string[]) => void;
}

export const InviteRetailersModal: React.FC<IInviteRetailersModalProps> = props => {
  const refIndividualForm = React.useRef<Form>(null);
  const refBulkForm = React.useRef<Form>(null);
  const refFileInput = React.useRef<HTMLInputElement>(null);
  const {
    AlertModalProps, alertOnError
  } = useAlert();
  const [individualInvite, setIndividualInvite] = React.useState('');
  const [batchInvites, setBatchInvites] = React.useState('');

  function handleUploadCsvFile() {
    if (!refFileInput.current) {
      return;
    }

    refFileInput.current.click();
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || _.isEmpty(event.target.files)) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (!_.isString(reader.result)) {
        return;
      }

      const emailAddresses = readCsv(reader.result)[0] as string[];
      setBatchInvites(_.join(emailAddresses, ','));
    }, false);

    reader.readAsDataURL(file);
  }

  function handleFormValidate(type: "individual" | "bulk") {
    try {
      if (type === "individual") {
        return !!validator(dataService.inviteIndividualRetailerSchema, {individualEmailAddress: individualInvite});

      } else if (type === "bulk") {
        return !!validator(dataService.inviteBatchRetailersSchema, {batchEmailAddresses: _.split(batchInvites, /(?:,|;|\n|\r|\t|\s)+/)});

      }
      return false;
    } catch (err) {
      if ('errors' in err) {
        alertOnError(err);
      }
      return false;
    }
  }

  function handleSubmit(type: "individual" | "bulk") {
    if (type === "individual") {
      props.onSendInvite([individualInvite]);
    }
    else {
      props.onSendInvite(_.split(batchInvites, /(?:,|;|\n|\r|\t|\s)+/));
    }
  }

  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={() => {
        setIndividualInvite('');
        setBatchInvites('');
        props.onRequestClose();
      }}
      modalTitle="Invite Retailer Contacts">
      {props.retailer && (
        <div className={styles.retailer}>
          Inviting to Retailer: {props.retailer.name}
        </div>
      )}

      <Form
        extraClassName={styles.individualForm}
        ref={refIndividualForm}
        fnValidateForm={() => handleFormValidate("individual")}
        onSuccessfulSubmit={() => handleSubmit("individual")}>
        <EmailInput
          ref={_.partial(Form.captureElement, refIndividualForm, 'individualEmailAddress')}
          value={individualInvite}
          onChangeValue={setIndividualInvite}
          placeholder="Enter Email Address Here…"
          label="INDIVIDUAL INVITE" />

        <Button
          className={styles.submitButton}
          sizeStyle="small"
          type="submit">
          SEND INVITE
        </Button>
      </Form>

      <Form
        extraClassName={styles.bulkForm}
        ref={refBulkForm}
        fnValidateForm={() => handleFormValidate("bulk")}
        onSuccessfulSubmit={() => handleSubmit("bulk")}>
        <Button
          type="button"
          className={styles.uploadButton}
          colorStyle="secondary"
          sizeStyle="small"
          onClick={handleUploadCsvFile}>
          UPLOAD CSV FILE
        </Button>

        <input
          className={styles.fileInput}
          ref={refFileInput}
          type='file'
          accept="text/csv"
          onChange={handleFileUpload} />

        <TextAreaInput
          ref={_.partial(Form.captureElement, refBulkForm, 'batchEmailAddresses')}
          onChangeValue={setBatchInvites}
          value={batchInvites}
          placeholder="Paste Email Address Here…"
          label="BULK INVITE" />

        <Button
          className={styles.submitButton}
          sizeStyle="small"
          type="submit">
          SEND INVITE
        </Button>
      </Form>

      <AlertModal {...AlertModalProps} />
    </ModalTemplate>
  );
};
