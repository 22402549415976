import _ from 'lodash';
import currency from 'currency.js';



export function sumBy(values: number[]): string {
  return _.reduce<number, currency>(values, (accu, value) => accu.add(currency(value, {fromCents: true})), currency(0, {fromCents: true})).format();
}

export function cents2ReadableDollarsAndCents(amountCents: number, noDecimals = false): string {
  if (noDecimals) {
    return currency(currency(amountCents, {fromCents: true}).dollars(), {precision: 0}).format();
  }
  return currency(amountCents, {fromCents: true}).format();
}

export function cents2EditableDollarsAndCents(amountCents: number, noDecimals = false): string {
  if (noDecimals) {
    return currency(currency(amountCents, {
      fromCents: true,
      symbol: '',
      separator: ''
    }).dollars(), {precision: 0}).format();
  }
  return currency(amountCents, {
    fromCents: true,
    symbol: '',
    separator: ''
  }).format();
}
