import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import App from './App';
import * as apolloService from 'services/apolloService';
import * as authService from 'services/authService';
import * as firebaseService from 'services/firebaseService';

import * as environmentUtil from 'services/environment';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './index.css';



declare global {
  interface Window {
    markerIODestination: string | undefined;
  }
}

try {
  environmentUtil.init();
  firebaseService.init();
  authService.init();
  apolloService.init(authService.getFirebaseUser);
  const config = environmentUtil.getConfig();
  const stripePromise = loadStripe(config.stripe.publishableKey);

  firebaseService.getApp()
    .auth()
    .onAuthStateChanged(() => {
      ReactDOM.render(
        <ApolloProvider client={apolloService.getClient()}>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </ApolloProvider>,
        document.getElementById('root')
      );
      ReactModal.setAppElement('#root');
    });

} catch (err) {
  if (err instanceof Error) {
    ReactDOM.render(
      <div>{err.message}</div>,
      document.getElementById('root'));
  }
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
