import React from 'react';
import _ from 'lodash';

import styles from './ContactDetails.module.css';

export interface IRetailerDetail {
  name: string;
  contactName: string;
  email: string;
  cellNumber: string;
  purchaseStates: string;
  address?: {
    locationName: string;
    street: string;
    city: string;
    state: string;
    zip: string;
  }
}

export interface IDistributorDetail {
  name: string;
  contactEmails: string[];
}
export interface IContactDetailsProps {
  noStyle?: boolean;
  retailer?: IRetailerDetail;
  distributor?: IDistributorDetail;
}

export const ContactDetails: React.FC<IContactDetailsProps> = props => {
  return (
    <>
      {props.retailer && (
        <div className={styles.container}>
          <div className={`${styles.details} ${props.noStyle ? styles.noStyle : ''}`}>
            <h3>RETAILER INFO</h3>

            <h4>
              {props.retailer.name}
            </h4>

            <p>
              Contact: {props.retailer.contactName}
              <br />
              Email: <a href={`mailto:${props.retailer.email}`}>{props.retailer.email}</a>
              <br />
              Cell: {props.retailer.cellNumber}

              {!props.retailer.address && (
                <>
                  Purchase States: {props.retailer.purchaseStates}
                </>
              )}
            </p>
          </div>

          {props.retailer.address && (
            <div className={styles.details}>
              <h3>LOCATION</h3>

              <p>
                {props.retailer.address.locationName} <br />
                {props.retailer.address.street} <br />
                {props.retailer.address.city} <br />
                {props.retailer.address.state} {props.retailer.address.zip}
              </p>
            </div>
          )}
        </div>
      )}

      {props.distributor && (
        <div className={styles.container}>
          <div className={`${styles.details} ${props.noStyle ? styles.noStyle : ''}`}>
            <h3>DISTRIBUTOR INFO</h3>

            <h4>
              {props.distributor.name}
            </h4>

            <p>
              Emails:
              {_.map(props.distributor.contactEmails, (email, index, array) => (
                <>
                  <a href={`mailto:${email}`}>{email}</a>
                  {index !== array.length - 1 && ", "}
                </>
              ))}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
