import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import {
  Link,
  useHistory
} from 'react-router-dom';

import { routes } from 'routes';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Button } from 'components/shared/Button/Button';
import { Icon } from 'components/shared/Icon/Icon';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { useAlert } from 'util/hooks';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import * as authService from 'services/authService';

import styles from './SignInPage.module.css';



export const SignInPage: React.FC = () => {
  const refForm = React.useRef<Form>(null);
  const history = useHistory();
  const {
    AlertModalProps, setAlert
  } = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  async function handleSignIn() {
    try{
      setLoading(true);
      await authService.logInWithEmail(email, password);
    } catch(err) {
      setAlert("We are unable to log you in with the provided credentials. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <OnboardingPageTemplate loading={loading}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Welcome to the
            <br />
            BevNomad App
          </h1>

          <span className={styles.subtitle}>
            FOR BREWER SALES & GROWTH
          </span>
        </div>

        <Form
          ref={refForm}
          extraClassName={styles.form}
          onSuccessfulSubmit={handleSignIn}>
          <EmailInput
            label="USER EMAIL"
            ref={_.partial(Form.captureElement, refForm, 'email')}
            required
            value={email}
            onChangeValue={setEmail} />

          <PasswordInput
            label="PASSWORD"
            ref={_.partial(Form.captureElement, refForm, 'password')}
            required
            value={password}
            onChangeValue={setPassword} />

          <Link
            className={styles.forgotPassword}
            to={routes.onboarding.forgotPassword.path()}>
            FORGOT PASSWORD?
          </Link>

          <Button
            className={styles.formButton}
            type="submit">
            Login
          </Button>

          <Button
            colorStyle="secondary"
            className={classnames(styles.formButton, styles.signUpButton)}
            onClick={() => history.push(routes.onboarding.signUp.path())}>
            Don’t have an account? Sign up today!
          </Button>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </OnboardingPageTemplate>
  );
};
