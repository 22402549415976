import React from 'react';

import {
  useLocation,
  useParams
} from 'react-router';
import _ from 'lodash';



export const routes = {

  onboarding: {
    signIn: {
      rawPath: '/onboarding/signin',
      path: () => '/onboarding/signin'
    },

    signUp: {
      rawPath: '/onboarding/signup',
      path: () => '/onboarding/signup'
    },

    distributorSubmitted: {
      rawPath: '/onboarding/distributorsubmitted',
      path: () => '/onboarding/distributorsubmitted'
    },

    forgotPassword: {
      rawPath: '/onboarding/forgot',
      path: () => '/onboarding/forgot'
    },

    resetPassword: {
      rawPath: '/onboarding/reset/:requestId',
      path: (requestId: string) => `/onboarding/reset/${requestId}`,
      usePathParams: () => useParams<{requestId: string}>()
    },

    completeEmployeeRegistration: {
      rawPath: '/onboarding/completeemployeeregistration/:requestId',
      path: (requestId: string) => `/onboarding/completeemployeeregistration/${requestId}`,
      usePathParams: () => useParams<{requestId: string}>()
    },

    accountTypeNotPermitted: {
      rawPath: '/onboarding/accounttypenotpermitted',
      path: () => '/onboarding/accounttypenotpermitted'
    },

    accountDisabled: {
      rawPath: '/onboarding/accountdisabled',
      path: () => '/onboarding/accountdisabled'
    }
  },

  // Protected Routes
  home: {
    rawPath: '/',
    path: () => '/',
    useQueryParams: () => useQueryParams<{retailerName?: string, orderId?: string}>()
  },

  brands: {
    rawPath: '/brands',
    path: () => '/brands',
    routes: {
      new: {
        rawPath: '/brands/new',
        path: () => '/brands/new'
      },
      edit: {
        rawPath: '/brands/:brandId/edit',
        path: (brandId: string) => `/brands/${brandId}/edit`,
        usePathParams: () => useParams<{brandId: string}>()
      }
    }
  },

  distributors: {
    rawPath: '/distributors',
    path: () => '/distributors'
  },

  retailers: {
    rawPath: '/retailers',
    path: () => '/retailers'
  },

  profile: {
    rawPath: '/profile',
    path: () => '/profile'
  },

  settings: {
    rawPath: '/settings',
    path: () => '/settings'
  }

};



export const superAdminRoutes = {
  accounts: {
    rawPath: '/superadmin/accounts',
    path: () => '/superadmin/accounts'
  },

  applications: {
    rawPath: '/superadmin/applications',
    path: () => '/superadmin/applications'
  },

  breweries: {
    rawPath: '/superadmin/breweries',
    path: () => '/superadmin/breweries'
  },

  distributors: {
    rawPath: '/superadmin/distributors',
    path: () => '/superadmin/distributors'
  },

  retailers: {
    rawPath: '/superadmin/retailers',
    path: () => '/superadmin/retailers'
  },

  reports: {
    rawPath: '/superadmin/reports',
    path: () => '/superadmin/reports'
  },

  orders: {
    rawPath: '/superadmin/orders',
    path: () => '/superadmin/orders'
  }
};



function useQueryParams<T extends Partial<{[key: string]: string}>>() {
  const location = useLocation();
  const [queryParams, setQueryParams] = React.useState<T>({} as any);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.forEach((value, key) => {
      setQueryParams(state => ({
        ...state,
        [key]: value
      }));
    });
  }, [location, setQueryParams]);

  return queryParams;
}



export function getUrlWithQueryString(url: string, params: {[key: string]: string | number | boolean}) {
  const query = _.map(params, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);

  return `${url}?${query.join("&")}`;
}
