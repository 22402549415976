import React from 'react';
import { default as PureDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './BaseDatePicker.module.css';



export interface IBaseDatePickerProps {
  selected: Date;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
  style?: React.CSSProperties;
}

export const BaseDatePicker: React.FC<IBaseDatePickerProps> = props => {
  return (
    <div
      className={styles.container}
      style={props.style}>
      <PureDatePicker
        selected={props.startDate}
        onChange={props.onChange}
        startDate={props.startDate}
        endDate={props.endDate}
        maxDate={new Date()}
        selectsRange />
    </div>
  );
};

