import React from 'react';

import {
  Icon,
  IIconType
} from '../../Icon/Icon';

import styles from './HeaderTitle.module.css';



export interface IHeaderTitleProps {
  icon?: IIconType;
  title: string;
}

export const HeaderTitle: React.FC<IHeaderTitleProps> = props => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {props.icon && (
          <Icon
            size={42}
            className={styles.icon}
            type={props.icon} />
        )}

        <span className={styles.titleLabel}>
          {props.title}
        </span>
      </div>

      {props.children && (
        <div className={styles.children}>
          {props.children}
        </div>
      )}
    </div>
  );
};
