import React from 'react';
import _ from 'lodash';

import {
  default as ReactSelect,
  Props as ReactSelectProps
} from 'react-select';

import { SelectStyles } from './SelectStyles';
import { Icon } from 'components/shared/Icon/Icon';

import styles from './Select.module.css';



export interface ISelectProps extends ReactSelectProps {
  label?: string;
  isMulti?: any;
}

export const Select: React.FC<ISelectProps> = React.forwardRef<ReactSelect, ISelectProps>((props, ref) => (
  <div className={styles.container}>
    {props.label && (
      <label className={styles.label}>
        {props.label}
      </label>
    )}

    <ReactSelect
      {..._.omit(props, ['label'])}
      closeMenuOnSelect={!(props.closeMenuOnSelect ?? props.isMulti)}
      components={{DropdownIndicator: () => <Icon type="bracketDown" />}}
      ref={ref}
      styles={SelectStyles} />
  </div>
));



Select.defaultProps = {placeholder: ""};
