import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { IGenericInputRef } from '../IGenericInputRef';

import styles from './GenericInput.module.css';



export type IGenericInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  type: 'email' | 'text' | 'password' | 'number' | 'date' | 'url' | 'file';

  prefix?: string;
  suffix?: string;
  value?: string;
  label?: string;
  disableValidateOnChange?: boolean;

  onChangeValue: (value: string) => void;
  /**
   * Returns an error message to display to the user based on the input.
   * If the input is valid, returns the empty string.
   */
  fnValidate: (value: string, required?: boolean) => string;
}

/**
 * Intended to be a generic text field control that is simply used as a partial
 * implementation of other controls that require a text input control.
 */
export const GenericInput = React.forwardRef<IGenericInputRef, IGenericInputProps>((props, ref) => {
  const {
    value, fnValidate, required
  } = props;
  const inputType = props.type;

  const refInput = React.useRef<HTMLInputElement>(null);
  const [isPasswordShown, setIsPasswordShown] = React.useState<boolean>(false);

  const validate = React.useCallback((immediateValue?: string) => {
    const elInput = refInput.current;
    if (!elInput) {
      return false;
    }

    const validatingValue = !_.isNil(immediateValue) ?
      immediateValue.trim() :
      !_.isNil(value) ?
        value.trim() :
        '';

    const errorMessage = fnValidate(validatingValue, required);
    elInput.setCustomValidity(errorMessage);
    return !errorMessage;
  }, [
    refInput,
    value,
    fnValidate,
    required
  ]);

  function togglePasswordShown() {
    setIsPasswordShown(!isPasswordShown);

  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;

    if (props.onChange) {
      props.onChange(event);
    }
    props.onChangeValue(value);
  }

  React.useImperativeHandle(ref, () => ({validate}));

  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      validate();
    }
  }, [
    value,
    validate
  ]);

  return (
    <div className={styles.container}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
        </label>
      )}

      <div className={styles.inputContainer}>
        {props.prefix && (
          <span className={classnames(styles.fixtureLabel, styles.prefix)}>{props.prefix}</span>
        )}

        <input
          type={isPasswordShown ? 'text' : inputType}
          {..._.omit(props, [
            'onChangeValue',
            'fnValidate',
            'prefix',
            'suffix',
            'type'
          ])}
          ref={refInput}
          className={classnames(styles.input, props.className, props.type === 'password' ? styles.inputPassword :  '')}
          onChange={handleChange} />

        {props.type === 'password' && (
          <button
            className={styles.endButton}
            type='button'
            onClick={togglePasswordShown}>{isPasswordShown ? 'Hide' : 'Show'}</button>
        )}

        {props.suffix && (
          <span className={classnames(styles.fixtureLabel, styles.suffix)}>{props.suffix}</span>
        )}
      </div>
    </div>
  );
});



export function genInputComponent(
  type: IGenericInputProps['type'],
  fnValidate: IGenericInputProps['fnValidate']
) {
  return React.forwardRef<IGenericInputRef, Omit<IGenericInputProps, 'type' | 'fnValidate'>>((props, ref) => (
    <GenericInput
      {...props}
      ref={ref}
      type={type}
      fnValidate={fnValidate} />
  ));
}
