import React from 'react';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Button } from 'components/shared/Button/Button';

import styles from './ConfirmModal.module.css';



export interface IConfirmModalProps extends IModalTemplateProps {
  title: string;
  content?: string;
  onConfirmed: () => void;
  confirmLabel?: string;
  cancelLabel?: string;
}

export const ConfirmModal: React.FC<IConfirmModalProps> = props => {
  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}>
      <div className={styles.container}>
        <div className={styles.title}>
          {props.title}
        </div>

        <p className={styles.content}>
          {props.content ?? props.children}
        </p>

        <div className={styles.buttons}>
          <Button onClick={props.onConfirmed}>
            {props.confirmLabel ?? 'Confirm'}
          </Button>

          <Button
            colorStyle="secondary"
            onClick={props.onRequestClose}>
            {props.cancelLabel ?? 'Cancel'}
          </Button>
        </div>
      </div>
    </ModalTemplate>
  );
};
