import React from 'react';

import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';
import { Button } from 'components/shared/Button/Button';

import * as authService from 'services/authService';

import styles from './AccountTypeNotPermittedPage.module.css';



export const AccountTypeNotPermittedPage: React.FC = () => {
  return (
    <OnboardingPageTemplate>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Oh no,
            <br />
            Sorry!
          </h1>

          <span className={styles.subtitle}>
            <br />
            <br />
            Your account type cannot access our web platform at this time.
            <br />
            <br />
            If you are a retailer please download our application from Google Play or the App Store.
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              colorStyle='secondary'
              onClick={authService.logOut}>
              Log Out
            </Button>
          </span>
        </div>


      </div>
    </OnboardingPageTemplate>
  );
};
