import React from 'react';
import moment from 'moment';

import { Select } from 'components/shared/form/Select/Select';
import { DatePicker, IDateTuple } from 'components/shared/DatePicker/DatePicker';

import * as optionsUtil from 'util/options';



const __dateFilterOptions: optionsUtil.IOption[] = [
  {
    label: 'Show Month to Date',
    value: 'MTD'
  },
  {
    label: 'Show Year to Date',
    value: 'YTD'
  },
  {
    label: 'Show Last Month',
    value: 'LM'
  },
  {
    label: 'Custom Date Range',
    value: 'custom'
  }
];



interface IProps {
  onSetDateTuple: (dateTuple: IDateTuple) => void;
  onSetDateFilterOption?: (value: string) => void;
}

export const DateRangeSelectField: React.FC<IProps> = props => {
  const [dateFilterOption, setDateFilterOption] = React.useState('');

  React.useEffect(() => {
    const dates = __handleDateFilterChange(dateFilterOption);

    props.onSetDateFilterOption?.(dateFilterOption);
    props.onSetDateTuple(dates);
  }, [dateFilterOption]);

  return (
    <>
      <div style={{ width: 180 }}>
        <Select
          placeholder="Filter Dates"
          options={__dateFilterOptions}
          value={optionsUtil.getOptionFromValue(__dateFilterOptions, dateFilterOption)}
          onChange={option => setDateFilterOption(optionsUtil.getValueFromOption(option))} />
      </div>
      {dateFilterOption === 'custom' && (
        <DatePicker
          style={{ marginLeft: 12 }}
          onSetDateTuple={props.onSetDateTuple} />
      )}
    </>
  );
};



function __handleDateFilterChange(option: string): IDateTuple {
  let toDate: moment.Moment;
  let fromDate: moment.Moment;
  if (!option) return [null, null];

  if (option === 'MTD') {
    fromDate = moment().startOf('month');
    toDate = moment();
  } else if (option === 'YTD') {
    fromDate = moment().startOf('year');
    toDate = moment();
  } else if (option === 'LM') {
    fromDate = moment().subtract(1, 'month').startOf('month');
    toDate = moment().subtract(1, 'month').endOf('month');
  } else {
    // Set default custom range to 7 days
    fromDate = moment().subtract(7, 'days');
    toDate = moment();
  }
  return [fromDate.toDate(), toDate.toDate()];
}
