import React from 'react';
import _ from 'lodash';

import { Badge } from 'components/shared/Badge/Badge';
import { ViewNotificationsModal } from './ViewNotificationsModal/ViewNotificationsModal';
import * as dataService from './dataService';
import { GetCurrentUserHook } from 'util/hooks';
import Bluebird from 'bluebird';

import styles from './Notification.module.css';

import imgBell from 'assets/icons/bell.svg';



export const Notification: React.FC = () => {
  const [showNotificationModal, setShowNotificationModal] = React.useState(false);
  const currentUserQueryTuple = GetCurrentUserHook();
  const queryTuple = dataService.useMyNotificationsQuery(currentUserQueryTuple.data.currentUser.idForQueries);
  const [markNotificationAsRead] = dataService.useMarkNotificationsMutation();
  const unreadNotifications = React.useMemo(() => _.filter(queryTuple.notifications, notification => !notification.isRead), [queryTuple.notifications]);

  async function handleViewNotifications() {
    setShowNotificationModal(true);

    await Bluebird.each(unreadNotifications, async notification => {
      markNotificationAsRead(notification.id);
    });

    queryTuple.refetch();
    currentUserQueryTuple.refetch();
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.toggleButton}
        onClick={handleViewNotifications}>
        <img
          src={imgBell}
          alt="notification" />

        {!_.isEmpty(unreadNotifications) && (
          <Badge className={styles.badge}>
            {unreadNotifications.length}
          </Badge>
        )}
      </button>

      <ViewNotificationsModal
        actingWarning={currentUserQueryTuple.data.currentUser.isActing}
        notifications={queryTuple.notifications}
        isOpen={showNotificationModal}
        onRequestClose={() => setShowNotificationModal(false)} />
    </div>
  );
};
