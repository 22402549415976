import _ from 'lodash';
import * as yup from 'yup';

import {
  UpdateMyDistributorsInput,
  useMyDistributorsQuery as useBaseMyDistributorsQuery,
  useSendMissingDistributorMutation as useBaseSendMissingDistributorMutation,
  useUpdateMyDistributorsMutation as useBaseUpdateMyDistributorsMutation
} from 'generated/apiTypes';

import { stateNameSchema } from 'util/validation';
import * as refetchQueries from 'util/refetchQueries';



export function useMyDistributorsQuery(id: string) {
  const tuple = useBaseMyDistributorsQuery({
    variables: {id},
    fetchPolicy: 'no-cache'
  });

  return {
    ...tuple,
    data: {
      distributors: _.map(tuple.data?.brewery?.distributors ?? [], distro => {
        return ({
          id: distro.id,
          name: distro.name,
          contactEmails: distro.contactEmails,
          states: distro.distributionStates ?? []
        });
      }),
      selfDistributionStates: _.map(tuple.data?.brewery?.selfDistributionStates ?? [], state => state)
    }
  };
}



export function useUpdateMyDistributorsMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseUpdateMyDistributorsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.MyDistributorsRefetchQuery({id})]
  });


  const fnWrappedMutation = (input: UpdateMyDistributorsInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useSendMissingDistributorMutation(id: string, userInfo: ISendMissingDistributorUserType) {
  const [
    fn,
    tuple
  ] = useBaseSendMissingDistributorMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.MyDistributorsRefetchQuery({id})]
  });

  const fnWrappedMutation = (input: IReportMissingDistributorFormType) => {
    return fn({
      variables: {
        input: {
          ...userInfo,
          distributorName: input.name,
          distributorEmail: input.emailAddress
        }
      }
    });
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export interface ISendMissingDistributorUserType {
  firstName: string;
  lastName: string;
  emailAddress: string;
  cellNumber: string;
  breweryName: string;
  breweryState: string;
}

export interface IDistributorType {
  id: string;
  contactEmails: string[];
  states: string[];
}



export const reportMissingDistributorFormSchema = yup.object({
  name: yup.string().required().label('Name'),
  emailAddress: yup.string().email().required().label('Email')
}).defined();

export type IReportMissingDistributorFormType = yup.InferType<typeof reportMissingDistributorFormSchema>;



export const distributorsFormSchema = yup.object({
  selfDistributionStates: yup.array(yup.string()).defined().label('Self Distribution States'),
  distributors: yup.array(yup.object<IDistributorType>({
    id: yup.string().required().label('Distributor Name'),
    states: yup.array(stateNameSchema.required()).required().max(1).label('Distributor State'),
    contactEmails: yup.array(yup.string().email().required()).required().label('Contact Emails')
  })).defined().label('Distributors')
}).defined();
