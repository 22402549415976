import React from 'react';

import styles from './SectionTitleWarning.module.css';



export const SectionTitleWarning: React.FC = props => (
  <div className={styles.title}>
    {props.children}
  </div>
);
