import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { Button } from 'components/shared/Button/Button';
import { useAlert } from 'util/hooks';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import * as authService from 'services/authService';

import styles from './ReauthenticateModal.module.css';



export interface IReauthenticateModalProps extends IModalTemplateProps {
  oldEmailAddress: string;
  newEmailAddress: string;

  onAuthenticated: () => void;
}

export const ReauthenticateModal: React.FC<IReauthenticateModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, setAlert
  } = useAlert();
  const [password, setPassword] = React.useState('');

  async function handleReauthenticate() {
    try {
      await authService.reauthenticateWithEmail(props.oldEmailAddress, password);
      await authService.updateEmail(props.newEmailAddress);

      props.onAuthenticated();
    } catch (err) {
      setAlert("Unable to change your email address.", "error");
    }
  }

  return (
    <ModalTemplate
      modalTitle="Reauthenticate"
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}>
      <Form
        ref={refForm}
        onSuccessfulSubmit={handleReauthenticate}>
        <EmailInput
          label="USER EMAIL"
          ref={_.partial(Form.captureElement, refForm, 'email')}
          required
          readOnly
          value={props.newEmailAddress}
          onChangeValue={_.noop} />

        <PasswordInput
          label="PASSWORD"
          ref={_.partial(Form.captureElement, refForm, 'password')}
          required
          value={password}
          onChangeValue={setPassword} />

        <Button
          type="submit"
          className={styles.formButton}>
          Reauthenticate
        </Button>
      </Form>

      <AlertModal {...AlertModalProps} />
    </ModalTemplate>
  );
};
