import * as yup from 'yup';

import { genInputComponent } from '../GenericInput/GenericInput';
import { extractErrorMessageFromValidation } from 'util/validation';



export const DateInput = genInputComponent('date', (value, required) => {
  let schema = yup.date();
  if (required) {
    schema = schema.required();
  }

  return extractErrorMessageFromValidation(schema, new Date(value));
});

