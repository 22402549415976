import _ from 'lodash';

import {
  useBrandsPageQuery as useBaseBrandsPageQuery,
  useChangeListedStatusMutation as useBaseChangeListedStatusMutation,
  useImportFromUntappdMutation as useBaseImportFromUntappdMutation,
  useRemoveBrandMutation as useBaseRemoveBrandMutation,
  ChangeListedStatusInput
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';

import noImageRect from 'assets/no_image_rect.png';



export function useBrandsPageQuery(id: string, filter: string[]) {
  const tuple = useBaseBrandsPageQuery({
    variables: {
      id,
      filter
    }
  });

  return {
    ...tuple,
    data: {
      brandCategories: _.map(tuple.data?.brewery?.brandCategories ?? [], category => {
        return ({
          name: category.name ?? '',
          count: category.numberOfBrands ?? 0
        });
      }) ?? [],
      activeBrands: _.map(tuple.data?.brewery?.activeBrands ?? [], formatBrandInfo) ?? [],
      brands: _.map(tuple.data?.brewery?.brands ?? [], formatBrandInfo) ?? []
    }
  };
}



export function useChangeListedStatusMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseChangeListedStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.BrandsPageRefetchQuery({id})]
  });

  const fnWrappedMutation = (input: ChangeListedStatusInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}




export function useImportFromUntappdMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseImportFromUntappdMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.BrandsPageRefetchQuery({id})]
  });

  const fnWrappedMutation = (breweryId: string) => {
    return fn({variables: {breweryId }});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useRemoveBrandMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseRemoveBrandMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.BrandsPageRefetchQuery({id})]
  });

  const fnWrappedMutation = (brandId: string) => {
    return fn({variables: {brandId }});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



function formatBrandInfo(brand: any): IBrand {
  return ({
    id: brand.id,
    imageUri: _.isEmpty(brand.brandImage) ? noImageRect : brand.brandImage,
    name: brand.name,
    lastOrdered: "Not Yet Implemented",
    categories: _.map(brand.category, category => category.name),
    listed: brand.listed,
    canListOnMarketplace: !_.isEmpty(brand.formats) && _.some(brand.formats, format => format.listed),
    untappdId: brand.untappdId ?? ''
  });
}



export interface IBrand {
  id: string;
  imageUri: string;
  name: string;
  lastOrdered: string;
  categories: string[];
  listed: boolean;
  untappdId: string;
  canListOnMarketplace: boolean;
}
