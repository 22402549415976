import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import {
  Icon,
  IIconType
} from '../Icon/Icon';

import styles from './Button.module.css';



export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sizeStyle?: 'normal' | 'small' | 'large';
  colorStyle?: 'primary' | 'secondary' | 'form' | 'darken';
  leftIcon?: IIconType;
  rightIcon?: IIconType;
}

export const Button: React.FC<IButtonProps> = props => {
  return (
    <button
      {..._.omit(props, [
        'sizeStyle',
        'colorStyle',
        'leftIcon',
        'rightIcon'
      ])}
      className={classnames([
        props.className,
        styles.button,
        _.get(__colorStyleMap, props.colorStyle ?? 'primary'),
        _.get(__sizeStyleMap, props.sizeStyle ?? 'normal')
      ])}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.leftIcon ? (
        <Icon type={props.leftIcon} />
      ) : (
        <span />
      )}

      <span className={styles.buttonLabel}>
        {props.children}
      </span>

      {props.rightIcon ? (
        <Icon type={props.rightIcon} />
      ) : (
        <span />
      )}
    </button>
  );
};



const __colorStyleMap = {
  primary: styles.colorPrimary,
  secondary: styles.colorSecondary,
  form: styles.colorForm,
  darken: styles.colorDarken
};



const __sizeStyleMap = {
  small: styles.sizeSmall,
  large: styles.sizeLarge
};
