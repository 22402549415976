import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { Button } from 'components/shared/Button/Button';

import styles from './MultipleTextInput.module.css';



export type IMultipleTextInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  values: string[];
  onChangeValues: (values: string[]) => void;
  fnValidateEach?: (value: string) => string;
}

export const MultipleTextInput: React.FC<IMultipleTextInputProps> = props => {
  const refInputs = React.useRef<(HTMLInputElement | null)[]>([]);
  const [values, setValues] = React.useState<string[]>(['']);

  function handleInputBlur(event: React.FocusEvent<HTMLInputElement>, index: number) {
    if (props.onBlur) {
      props.onBlur(event);
    }

    if (_.isEmpty(event.currentTarget.value) && index !== 0) {
      const cloned = _.cloneDeep(values);
      _.pullAt(cloned, index);
      setValues(cloned);
      props.onChangeValues(cloned);
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    if (props.onChange) {
      props.onChange(event);
    }

    const cloned = _.cloneDeep(values);
    if (!_.isEmpty(event.currentTarget.value)) {

      if (props.fnValidateEach) {
        try {
          props.fnValidateEach(event.currentTarget.value);
          refInputs.current[index]?.setCustomValidity('');
        } catch (e) {
          refInputs.current[index]?.setCustomValidity(e.message);
        }
      }
      cloned[index] = event.currentTarget.value;

    } else {
      _.pullAt(cloned, index);
    }
    setValues(cloned);
    props.onChangeValues(cloned);
  }

  function handleNewEntry() {
    if (!_.isArray(values) || _.isEmpty(values)) {
      setValues(['']);

    } else if (!_.isEmpty(_.last(values))) {
      setValues(_.concat(values, ['']));
    }
  }

  React.useEffect(() => {
    if (!_.isArray(props.values) || _.isEmpty(props.values)) {
      setValues(['']);
    } else {
      setValues(props.values);
    }
  }, [props.values, setValues]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {props.label && (
          <label className={styles.label}>
            {props.label}
          </label>
        )}

        {!props.disabled && (
          <Button
            type='button'
            colorStyle='form'
            leftIcon="plus"
            className={styles.addButton}
            onClick={handleNewEntry}>
            ADD ANOTHER
          </Button>
        )}
      </div>

      <div className={styles.inputs}>
        {_.map(values, (value, index) => (
          <div
            key={index}
            className={styles.inputContainer}>
            <input
              {..._.omit(props, [
                'values',
                'onChangeValues',
                'fnValidateEach'
              ])}
              value={value}
              ref={el => {
                refInputs.current[index] = el;
              }}
              className={classnames(styles.input, props.className)}
              onBlur={event => handleInputBlur(event, index)}
              onChange={event => handleInputChange(event, index)} />
          </div>
        ))}
      </div>
    </div>
  );
};
