import React from 'react';
import _ from 'lodash';

import {
  GetCurrentUserHook,
  GetValidSubscriptionHook,
  useAlert
} from 'util/hooks';

import { useHistory } from 'react-router';
import { routes } from 'routes';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { HeaderTitle } from 'components/shared/pages/HeaderTitle/HeaderTitle';
import { ConfirmModal } from 'components/shared/modals/ConfirmModal/ConfirmModal';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { BrandCard } from './BrandCard/BrandCard';
import { BrandCategoryCard } from './BrandCategoryCard/BrandCategoryCard';
import { BrandInfoModal } from './BrandInfoModal/BrandInfoModal';
import { ImportUntappdProfileModal } from './ImportUntappdProfileModal/ImportUntappdProfileModal';
import { Button } from 'components/shared/Button/Button';

import * as dataService from './dataService';

import styles from './BrandListPage.module.css';

import noImageRect from 'assets/no_image_rect.png';



export const BrandListPage: React.FC = () => {
  const [categoryFilters, setCategoryFilters] = React.useState<string[]>([]);
  const [showSyncUntappdProfileModal, setShowSyncUntappdProfileModal] = React.useState(false);
  const currentUserTuple = GetCurrentUserHook();
  const subscriptionQueryTuple = GetValidSubscriptionHook(currentUserTuple.data.currentUser.breweryIdForQueries);
  const queryTuple = dataService.useBrandsPageQuery(currentUserTuple.data.currentUser.breweryIdForQueries, categoryFilters);
  const [fnChangeListedStatus, changeListedStatusTuple] = dataService.useChangeListedStatusMutation(currentUserTuple.data.currentUser.breweryIdForQueries);
  const [fnImportFromUntappd, importFromUntappdResult] = dataService.useImportFromUntappdMutation(currentUserTuple.data.currentUser.breweryIdForQueries);
  const [fnRemoveBrand, removeBrandResult] = dataService.useRemoveBrandMutation(currentUserTuple.data.currentUser.breweryIdForQueries);

  const {
    AlertModalProps, setAlert
  } = useAlert();
  const history = useHistory();
  const [showBrandInfoModal, setShowBrandInfoModal] = React.useState(false);
  const [selectedBrandId, setSelectedBrandId] = React.useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);

  const isLoading = _.some([
    subscriptionQueryTuple,
    currentUserTuple,
    queryTuple,
    changeListedStatusTuple,
    importFromUntappdResult,
    removeBrandResult
  ], tuple => tuple.loading);

  const selectedBrand = React.useMemo(() => _.find(queryTuple.data.brands, brand => brand.id === selectedBrandId), [queryTuple.data.brands, selectedBrandId]);

  function handleViewOrder(brandId: string) {
    setShowBrandInfoModal(false);
    setSelectedBrandId(null);
    history.push(routes.home.path() + `?brandId=${brandId}`);
  }

  function handleClickBrand(brandId: string) {
    setShowBrandInfoModal(true);
    setSelectedBrandId(brandId);
  }

  async function handleImportUntappd() {
    try {
      await fnImportFromUntappd(currentUserTuple.data.currentUser.breweryIdForQueries);
      setAlert("Untappd profile imported successfully", 'success');
    }
    catch (err) {
      setAlert(err.message, 'error');
    }
    handleCloseModal();
  }

  function handleEditBrand(brandId: string) {
    setShowBrandInfoModal(false);
    setSelectedBrandId(null);
    history.push(routes.brands.routes.edit.path(brandId));
  }

  function handleConfirmRemoveBrand() {
    if (selectedBrandId) {
      setShowBrandInfoModal(false);
      setShowConfirmModal(true);
    }
  }

  async function handleRemoveBrand(brandId: string) {
    try {
      await fnRemoveBrand(brandId);
      setAlert("Brand removed successfully", 'success');
    }
    catch(err) {
      setAlert(err.message, 'error');
    }
    setShowConfirmModal(false);
    handleCloseModal();
  }

  function handleCloseModal() {
    setSelectedBrandId(null);
    setShowBrandInfoModal(false);
    setShowSyncUntappdProfileModal(false);
  }

  return (
    <PageTemplate
      loading={isLoading}
      headerTitle={(
        <HeaderTitle
          title="My Brands"
          icon="beer" >
          {!_.isEmpty(currentUserTuple.data.currentUser.untappd) && (
            <span
              onClick={() => setShowSyncUntappdProfileModal(true)}
              className={styles.syncButton}>
              (TAP TO IMPORT FROM UNTAPPD)
            </span>
          )}
        </HeaderTitle>
      )}>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>ACTIVE BRANDS</div>

          {subscriptionQueryTuple.isValidSubscription  ? (
            <div className={styles.brands}>
              {_.map(_.filter(queryTuple.data.activeBrands, brand => brand.listed), brand => (
                <BrandCard
                  key={brand.id}
                  brand={brand}
                  styleType={'normal'}
                  onClick={() => handleClickBrand(brand.id)}
                  onEditBrand={() => handleEditBrand(brand.id)} />
              ))}
            </div>
          ) : (
            <BrandCard
              key="NoSub"
              brand={{
                id: "NoSub",
                imageUri: noImageRect,
                name: "You need a valid subscription to have active brands",
                lastOrdered: "",
                categories: [],
                listed: false,
                canListOnMarketplace: false,
                untappdId: ''
              }}
              styleType='sub'
              onClick={() =>  history.push(routes.settings.path())}
              onEditBrand={_.noop} />
          )}
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            BRAND CATEGORIES

            {!_.isEmpty(categoryFilters) && (
              <Button
                style={{width: 'initial'}}
                sizeStyle='small'
                colorStyle='secondary'
                onClick={() => setCategoryFilters([])}>
                Clear Filters
              </Button>
            )}
          </div>

          <div className={styles.brands}>
            {_.map(queryTuple.data.brandCategories, category => (
              <BrandCategoryCard
                key={category.name}
                title={category.name}
                brandCount={category.count}
                selected={_.includes(categoryFilters, category.name)}
                onClick={() => setCategoryFilters(state => _.xor(state, [category.name]))} />
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <div className={styles.sectionTitle}>{_.isEmpty(categoryFilters) ? "ALL" : _.join(_.map(categoryFilters, _.upperCase), ', ')} BRANDS</div>

          <div className={styles.brands}>
            {_.map(queryTuple.data.brands, brand => (
              <BrandCard
                key={brand.id}
                brand={brand}
                styleType={'normal'}
                onClick={() => handleClickBrand(brand.id)}
                onEditBrand={() => handleEditBrand(brand.id)} />
            ))}
          </div>
        </div>
      </div>

      <BrandInfoModal
        brand={selectedBrand}
        onViewOrders={() => selectedBrandId && handleViewOrder(selectedBrandId)}
        onChangeListedStatus={listed => selectedBrandId && fnChangeListedStatus({
          brandId: selectedBrandId,
          listed
        })}
        canAlterListingStatus={subscriptionQueryTuple.isValidSubscription}
        onEditBrand={() => selectedBrandId && handleEditBrand(selectedBrandId)}
        onRemoveBrand={handleConfirmRemoveBrand}
        onRequestClose={handleCloseModal}
        isOpen={showBrandInfoModal} />

      <ImportUntappdProfileModal
        isOpen={showSyncUntappdProfileModal}
        onStartImport={handleImportUntappd}
        onRequestClose={() => setShowSyncUntappdProfileModal(false)} />

      <ConfirmModal
        title="Are you sure?"
        isOpen={showConfirmModal && !!selectedBrandId}
        onRequestClose={() => setShowConfirmModal(false)}
        onConfirmed={() => selectedBrandId && handleRemoveBrand(selectedBrandId)}>
        Are you sure you wish to remove this brand?
        <br />
        <span style={{fontWeight: 'bold'}}>
          {_.find(queryTuple.data.brands, brand => brand.id === selectedBrandId)?.name ?? ''}
        </span>
        <br />
        Removing a brand cannot be undone.
      </ConfirmModal>

      <AlertModal {...AlertModalProps} />
    </PageTemplate>
  );
};
