import React from 'react';
import classnames from 'classnames';

import styles from './Badge.module.css';



export interface IBadgeProps {
  className?: string;
}

export const Badge: React.FC<IBadgeProps> = props => (
  <span className={classnames(styles.badge, props.className)}>{props.children}</span>
);
