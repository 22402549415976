import {
  extractErrorMessageFromValidation,
  urlSchema
} from 'util/validation';

import { genInputComponent } from '../GenericInput/GenericInput';



export const UrlInput = genInputComponent('text', (value, required) => {
  let schema = urlSchema;
  if (required) {
    schema = schema.required();
  }
  return extractErrorMessageFromValidation(schema, value);
});
