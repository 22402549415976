import React from 'react';

import styles from './Switch.module.css';
import classnames from 'classnames';



export interface ISwitchProps {
  value?: boolean;
  className?: string;
  disabled?: boolean;
  onChangeValue?: (value: boolean) => void;
}

export const Switch: React.FC<ISwitchProps> = props => {
  function handleClick() {
    if (props.onChangeValue && !props.disabled) {
      props.onChangeValue(!props.value);
    }
  }

  return (
    <div className={classnames([styles.container, props.className, {[styles.disabled]: props.disabled}])}>
      <div
        className={classnames(styles.switchContainer, props.value ? styles.isSelected : '')}
        onClick={handleClick}>
        <div className={styles.knob} />
      </div>

      <div className={styles.label}>
        {props.children}
      </div>
    </div>
  );
};
