import React from 'react';
import moment from 'moment';

import { BaseDatePicker } from './BaseDatePicker/BaseDatePicker';



export type IDateTuple = [Date | null, Date | null];

interface IProps {
  onSetDateTuple: (dateTuple: IDateTuple) => void;
  style?: React.CSSProperties;
}

export const DatePicker: React.FC<IProps> = props => {
  const [customDates, setCustomDates] = React.useState<IDateTuple>([null, null]);

  return (
    <BaseDatePicker
      style={props.style}
      selected={moment().subtract(7, 'days').toDate()}
      startDate={customDates[0]}
      endDate={customDates[1]}
      onChange={dates => {
        setCustomDates(dates);
        if (dates.every(d => d)) {
          const fromDateBeginning = dates[0] ? moment(dates[0]).startOf('date').toDate() : null;
          const toDateBeginning = dates[1] ? moment(dates[1]).startOf('date').toDate() : null;
          props.onSetDateTuple([fromDateBeginning, toDateBeginning]);
        }
      }} />
  );
};
