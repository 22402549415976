import _ from 'lodash';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import * as environment from './environment';
import moment from 'moment';
import firebase from 'firebase';



let __client: ApolloClient<NormalizedCacheObject>;

export type FnGetLoggedInUser = () => firebase.User | null;
let __fnGetLoggedInUser: FnGetLoggedInUser;



export function init(fnGetLoggedInUser: FnGetLoggedInUser) {
  if (__client) {
    return;
  }

  __fnGetLoggedInUser = fnGetLoggedInUser;
  refreshClient();
}



export function refreshClient() {
  const config = environment.getConfig();

  const authLink = setContext(async (operation, prevContext) => {
    const user = __fnGetLoggedInUser();

    console.log(`${moment().toISOString(true)} Refreshing client with ${user ? user.email : "no user"}.`);
    let token = '';
    if (user !== null) {
      token = await user.getIdToken();
    }

    return {
      headers: {
        ...prevContext.headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const httpLink = new BatchHttpLink({uri: `${config.api.url}${config.api.graphqlPath}`});
  __client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink)
  });
}



export function getClient() {
  if (_.isNil(__client)) {
    throw new Error('Client not initialized.');
  }

  return __client;
}
