import _ from "lodash";



export interface IOption {
  label: string;
  value: string;
}



export function getValuesFromOptions(options: unknown | unknown[] | null | undefined) {
  return options && _.isArray(options) && _.every(options, option => 'value' in option) ?
    _.map(options, option => option.value) :
    [];
}



export function getValueFromOption(option: unknown | unknown[] | null | undefined) {
  return option && typeof option === 'object' && !_.isArray(option) && !_.isNil(option) && 'value' in option ?
    (option as any).value :
    undefined;
}



export function getOptionsFromValues(options: IOption[], values: any[]) {
  return _.filter(options, option => _.includes(values, option.value));
}



export function getOptionFromValue(options: IOption[], value: any) {
  return _.find(options, option => option.value === value) ?? null;
}
