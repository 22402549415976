import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import {
  confirmPasswordSchema,
  extractErrorMessageFromValidation
} from 'util/validation';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { SectionTitleWarning } from 'components/shared/SectionTitleWarning/SectionTitleWarning';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { Button } from 'components/shared/Button/Button';
import { useAlert } from 'util/hooks';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import * as authService from 'services/authService';

import styles from './FormSections.module.css';



export interface IChangePasswordFormProps {
  isActing: boolean;
}


export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = props => {
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, setAlert
  } = useAlert();
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  function handleFormValidate() {
    const errorMessage = extractErrorMessageFromValidation(__schema, {
      password,
      newPassword,
      confirmPassword
    });

    setAlert(errorMessage, "error");
    return _.isEmpty(errorMessage);
  }

  async function handleChangePassword() {
    try {
      const user = await authService.getFirebaseUser();

      if (user) {
        await authService.reauthenticateWithEmail(user?.email ?? '', password);
        await authService.updatePassword(newPassword);
      }

      setAlert("Success, your password has been changed");
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');

    } catch (err) {
      setAlert("Password could not be changed. Please check your current password and try again.");
    }
  }

  return (
    <div className={styles.formSection}>
      <SectionTitle>
        Change Password
        {props.isActing && (<SectionTitleWarning>Note: You cannot change the password on behalf of the user</SectionTitleWarning>)}
      </SectionTitle>

      <Form
        ref={refForm}
        fnValidateForm={handleFormValidate}
        extraClassName={styles.form}
        onSuccessfulSubmit={handleChangePassword}>
        <div className={styles.inputContainer}>
          <PasswordInput
            disabled={props.isActing}
            label="OLD PASSWORD"
            ref={_.partial(Form.captureElement, refForm, 'oldPassword')}
            required
            value={password}
            onChangeValue={setPassword} />

          <PasswordInput
            disabled={props.isActing}
            label="NEW PASSWORD"
            ref={_.partial(Form.captureElement, refForm, 'newPassword')}
            required
            value={newPassword}
            onChangeValue={setNewPassword} />

          <PasswordInput
            disabled={props.isActing}
            label="CONFIRM NEW PASSWORD"
            ref={_.partial(Form.captureElement, refForm, 'confirmPassword')}
            required
            value={confirmPassword}
            onChangeValue={setConfirmPassword} />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            disabled={props.isActing}
            type='submit'
            colorStyle="form">
            CONFIRM CHANGE PASSWORD
          </Button>
        </div>
      </Form>

      <AlertModal {...AlertModalProps} />
    </div>
  );
};



const __schema = yup.object({
  password: yup.string().required().label('Old password'),
  newPassword: yup.string().required().label('New password'),
  confirmPassword: confirmPasswordSchema(yup.ref('newPassword')).required().label('Confirm password')
}).defined();
