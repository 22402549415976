import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';
import {
  routes,
  getUrlWithQueryString
} from 'routes';

import { useHistory } from 'react-router';
import { Button } from 'components/shared/Button/Button';
import { ContactDetails } from 'components/shared/pages/ContactDetails/ContactDetails';
import { StatusTag } from 'components/shared/StatusTag/StatusTag';

import * as dataService from '../dataService';

import styles from './RetailerDetailModal.module.css';



export interface IRetailerDetailModalProps extends IModalTemplateProps {
  retailer: dataService.IRetailer | undefined;

  onEditRetailer: () => void;
  onDeleteRetailer: () => void;
}

export const RetailerDetailModal: React.FC<IRetailerDetailModalProps> = props => {
  const history = useHistory();

  function handleViewOrder(orderId: string) {
    history.push(getUrlWithQueryString(routes.home.path(), {orderId}));
  }

  function handleViewAllOrders() {
    if (props.retailer) {
      history.push(getUrlWithQueryString(routes.home.path(), {retailerName: props.retailer.name}));
    }
  }

  return props.retailer ? (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      modalTitle={props.retailer.name}>

      <div className={styles.details}>
        <h3>RECENT ORDERS</h3>

        <div className={styles.detailRows}>
          {_.map(props.retailer.orders, order => {
            return (
              <div className={styles.detailRow}>
                <div className={styles.detailBlock}>
                  <span>{order.readableId}</span>

                  <span>{order.placedAt}</span>

                  <span>{order.totalMinPtr}</span>
                </div>

                <StatusTag colorStyle={order.statusName as any}>{order.statusDisplayName}</StatusTag>

                <Button
                  colorStyle="secondary"
                  sizeStyle="small"
                  rightIcon="bracketRight"
                  onClick={() => handleViewOrder(order.id)}>
                VIEW ORDER
                </Button>
              </div>
            );
          })}
        </div>

        <div className={styles.detailButton}>
          <Button onClick={handleViewAllOrders}>
            View All Orders
          </Button>
        </div>
      </div>

      <ContactDetails retailer={props.retailer} />

    </ModalTemplate>
  ) : null;
};
