import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import styles from './StatusTag.module.css';
import { OrderStatusKey } from 'components/shared/Types/Order';



type ColorStyleKey = OrderStatusKey | 'ok' | 'info';

export interface IStatusTagProps {
  className?: string;
  colorStyle?: keyof typeof __colorStyleMap;
  sizeStyle?: 'normal' | 'small'
}

export const StatusTag: React.FC<IStatusTagProps> = props => {
  return (
    <div className={classnames([
      styles.container,
      props.className,
      _.get(__colorStyleMap, props.colorStyle ?? 'info'),
      {[styles.sizeSmall]: props.sizeStyle === 'small'}
    ])}>
      {props.children}
    </div>
  );
};



const __colorStyleMap: { [key in ColorStyleKey]: string } = {
  unknown: styles.colorInfo, // Temporarily use 'info' color as default
  ok: styles.colorOk,
  info: styles.colorInfo,
  newOrder: styles.colorNewOrder,
  shipped: styles.colorShipped,
  partlyReceived: styles.colorPartlyReceived,
  received: styles.colorReceived
};
