import _ from 'lodash';
import React from 'react';

import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { GetCurrentUserHook } from 'util/hooks';
import * as authService from 'services/authService';

import styles from './UserProfile.module.css';

import noImageSq from 'assets/no_image_sq.png';



export const UserProfile: React.FC = () => {
  const queryTuple = GetCurrentUserHook();

  let imageUrl = "";


  if (!_.isEmpty(queryTuple.data.currentUser.actingAs.breweryLogo)) {
    imageUrl = queryTuple.data.currentUser.actingAs.breweryLogo as string;
  }
  else if (!_.isEmpty(queryTuple.data.currentUser.breweryLogo)) {
    imageUrl = queryTuple.data.currentUser.breweryLogo;
  }
  else {
    imageUrl = noImageSq;
  }

  async function handleLogout() {
    await authService.logOut();
  }

  return (
    <div className={styles.container}>
      <div className={styles.profileName}>
        <span className={styles.profileNameLabel}>
          {queryTuple.data.currentUser.isActing && (queryTuple.data.currentUser.actingAs.breweryName)}

          {((queryTuple.data.currentUser.accountType === 'superAdmin'
            || queryTuple.data.currentUser.accountType === 'superEmployee') && !queryTuple.data.currentUser.isActing)
            && (`${queryTuple.data.currentUser.firstName} ${queryTuple.data.currentUser.lastName}`)}

          {(queryTuple.data.currentUser.accountType === 'breweryOwner'
            || queryTuple.data.currentUser.accountType === 'breweryEmployee')
            && (queryTuple.data.currentUser.breweryName)}
        </span>

        <div className={styles.group}>
          <Link
            className={styles.profileLink}
            to={routes.profile.path()}>
            Edit Profile
          </Link>
          <span className={styles.spacer}>|</span>
          <button
            className={styles.nobutton}
            onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>

      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={imageUrl}
          alt="profile" />
      </div>
    </div>
  );
};
