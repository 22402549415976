import _ from 'lodash';
import * as yup from 'yup';

import {
  useMyBrewerProfileQuery as useBaseMyBrewerProfileQuery,
  useUpdateBreweryInformationMutation as useBaseUpdateBreweryInformationMutation,
  UpdateBreweryInformationInput
} from 'generated/apiTypes';
import {
  stateNameSchema,
  urlSchema
} from 'util/validation';

import * as refetchQueries from 'util/refetchQueries';



export function useMyBrewerProfileQuery(id: string) {
  const tuple = useBaseMyBrewerProfileQuery({variables: {id}});

  return {
    ...tuple,
    data: {
      id: tuple.data?.brewery?.id ?? '',
      name: tuple.data?.brewery?.name ?? '',
      city: tuple.data?.brewery?.city ?? '',
      state: tuple.data?.brewery?.state ?? '',
      website: tuple.data?.brewery?.website ?? '',
      untappd: tuple.data?.brewery?.untappd ?? '',
      description: tuple.data?.brewery?.description ?? '',
      logo: tuple.data?.brewery?.logoUrl ?? '',
      images: _.map(tuple.data?.brewery?.images, image => image.url)
    }
  };
}



export function useUpdateBreweryInformationMutation(id: string) {
  const [
    fn,
    tuple
  ] = useBaseUpdateBreweryInformationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.MyBrewerProfileRefetchQuery({id})]
  });


  const fnWrappedMutation = (input: UpdateBreweryInformationInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export interface IProfileFormType {
  name: string;
  city: string;
  state: string;
  website: string;
  untappd: string;
  description: string;
  logo: string;
  images: string[];
}

export const profileSchema = yup.object({
  name: yup.string().required().label('Brewery name'),
  city: yup.string().required().label('Brewery city'),
  state: stateNameSchema.required().label('Brewery state'),
  website: urlSchema.required().label('Website'),
  untappd: yup.string().optional().label('Untappd Profile ID'),
  description: yup.string().max(160).required().label('Brewery description'),
  logo: yup.string().label('Brewery Profile image'),
  images: yup.array(yup.string()).max(6).label('Other Brewery images')
}).defined();
