import React from 'react';

import imgLogo from '../../../assets/logo.svg';



export interface ILogoProps {
  width?: number;
  height?: number;
}

export const Logo: React.FC<ILogoProps> = props => {
  return (
    <img
      width={props.width}
      height={props.height}
      src={imgLogo}
      alt="logo" />
  );
};
