import React from 'react';
import _ from 'lodash';
import * as yup from 'yup';

import { useHistory } from 'react-router';
import { routes } from 'routes';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import {
  confirmPasswordSchema , validator
} from 'util/validation';
import { useAlert } from 'util/hooks';
import { TextInput } from 'components/shared/form/TextInput/TextInput';


import * as authService from 'services/authService';
import * as dataService from './dataService';

import styles from './CompleteEmployeeRegistration.module.css';




export const CompleteEmployeeRegistration: React.FC = () => {
  const pathParams = routes.onboarding.completeEmployeeRegistration.usePathParams();
  const queryTuple = dataService.useGetInviteQuery(pathParams.requestId);
  const [fnAcceptEmployeeInvite, acceptEmployeeInviteTuple] = dataService.useAcceptEmployeeInviteMutation();

  const history = useHistory();
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, clearAlert, alertOnError
  } = useAlert();
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  function handleValidateForm() {
    try {
      validator(__schema, {
        password,
        confirmPassword
      });

      clearAlert();
      return true;
    } catch (err) {
      alertOnError(err);
      return false;
    }
  }

  async function handleCompleteRegistration() {
    try {

      await fnAcceptEmployeeInvite({
        onboardingInviteId: queryTuple.data.inviteId,
        password: password
      });

      const userCredential = await authService.logInWithEmail(queryTuple.data.email, password);

      if (userCredential.user) {
        history.push(routes.home.path());
      }
    }
    catch (err) {
      alertOnError(err);
    }
  }

  return (
    <OnboardingPageTemplate loading={queryTuple.loading || acceptEmployeeInviteTuple.loading}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Welcome to the
            <br />
            BevNomad App
          </h1>

          <span className={styles.subtitle}>
            COMPLETE EMPLOYEE REGISTRATION<br /><br />
            THESE DETAILS HAVE BEEN PROVIDED BY YOUR EMPLOYER, AND CAN BE CHANGED ONCE REGISTRATION HAS BEEN COMPLETED.
          </span>
        </div>

        <Form
          ref={refForm}
          extraClassName={styles.form}
          fnValidateForm={handleValidateForm}
          onSuccessfulSubmit={handleCompleteRegistration}>

          <TextInput
            ref={_.partial(Form.captureElement, refForm, 'email')}
            value={queryTuple.data.email}
            readOnly
            onChangeValue={_.noop}
            label="EMAIL" />

          <TextInput
            ref={_.partial(Form.captureElement, refForm, 'firstName')}
            value={queryTuple.data.firstName}
            readOnly
            onChangeValue={_.noop}
            label="FIRST NAME" />

          <TextInput
            ref={_.partial(Form.captureElement, refForm, 'lastName')}
            value={queryTuple.data.lastName}
            readOnly
            onChangeValue={_.noop}
            label="LAST NAME" />

          <PasswordInput
            ref={_.partial(Form.captureElement, refForm, 'password')}
            value={password}
            onChangeValue={setPassword}
            label="PASSWORD" />

          <PasswordInput
            ref={_.partial(Form.captureElement, refForm, 'confirmPassword')}
            value={confirmPassword}
            onChangeValue={setConfirmPassword}
            label="CONFIRM PASSWORD" />

          <Button
            className={styles.formButton}
            type="submit">
            COMPLETE REGISTRATION
          </Button>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </OnboardingPageTemplate>
  );
};



const __schema = yup.object({
  password: yup.string().required().label("Password"),
  confirmPassword: confirmPasswordSchema(yup.ref('password')).required().label('Confirm password')
}).defined();
