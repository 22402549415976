import React from 'react';

import styles from './StatusLabel.module.css';



export interface IStatusLabelProps {
  listed: boolean;
}

export const StatusLabel: React.FC<IStatusLabelProps> = props => (
  <>
    {props.listed && (
      <span className={styles.colorActive}>
        ACTIVE
      </span>
    )}

    {!props.listed && (
      <span className={styles.colorInactive}>
        INACTIVE
      </span>
    )}
  </>
);
