import React from 'react';

import { useHistory } from 'react-router';
import { Button } from '../../Button/Button';
import { routes } from '../../../../routes';
import { UserProfile } from '../UserProfile/UserProfile';
import { Notification } from '../Notification/Notification';

import * as dataService from './dataService';

import styles from './HeaderNav.module.css';
import {AccountStatusName} from 'generated/apiTypes';




export interface IHeaderNavProps {
  currentUserId: string;
  isActing: boolean;
  accountStatus: AccountStatusName;
  actingAsBreweryName?: string;
}

export const HeaderNav: React.FC<IHeaderNavProps> = props => {
  const [fnStopActing] = dataService.useStopActingAsBreweryOwnerMutation();
  const history = useHistory();

  async function handleStopActing() {
    await fnStopActing(props.currentUserId);
    window.location.reload();
  }

  return (
    <>
      {props.accountStatus === 'pending' && (
        <header className={styles.pendingStatusContainer}>
          <div className={styles.warning}>
            Your application is pending approval, your brands will not be available for purchase yet.
          </div>
        </header>
      )}
      {props.accountStatus === 'rejected' && (
        <header className={styles.rejectedStatusContainer}>
          <div className={styles.warning}>
            Your application has been rejected. Contact &quot;Email TBC&quot; for more information.
          </div>
        </header>
      )}
      {props.isActing && (
        <header className={styles.actingAsContainer}>
          <div className={styles.warning}>
            Warning: You are a super admin acting as the owner of &quot;{props.actingAsBreweryName}&quot;
          </div>
          <div className={styles.right}>
            <button
              className={styles.nobutton}
              onClick={handleStopActing}>
              Tap here to cancel
            </button>
          </div>
        </header>
      )}
      <header className={styles.container}>
        <div className={styles.buttons}>
          <Button onClick={() => history.push(routes.brands.routes.new.path())}>Add New Brand</Button>

          <Button onClick={() => history.push(routes.brands.path())}>View My Brands</Button>
        </div>

        <Notification />

        <UserProfile />
      </header>
    </>
  );
};
