import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { useAlert } from 'util/hooks';
import * as authService from 'services/authService';

import styles from './ForgotPasswordPage.module.css';



export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, setAlert
  } = useAlert();
  const [email, setEmail] = React.useState('');

  async function handleForgotPassword() {
    setAlert("If the user account exists, we will send a password reset link to you shortly.");

    try {
      await authService.sendPasswordResetEmail(email);
    } catch (err) {
      // NOTE: no error should be shown here for security reason
    }
  }

  return (
    <OnboardingPageTemplate>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Welcome to the
            <br />
            BevNomad App
          </h1>

          <span className={styles.subtitle}>
            FORGOTTEN PASSWORD
          </span>
        </div>

        <Form
          ref={refForm}
          extraClassName={styles.form}
          onSuccessfulSubmit={handleForgotPassword}>
          <EmailInput
            label="USER EMAIL"
            ref={_.partial(Form.captureElement, refForm, 'email')}
            value={email}
            onChangeValue={setEmail} />

          <Button
            className={styles.formButton}
            type="submit">
            Send password reset email
          </Button>

          <Button
            colorStyle="secondary"
            className={classnames(styles.formButton, styles.backButton)}
            onClick={() => history.push(routes.onboarding.signIn.path())}>
            Back to login
          </Button>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </OnboardingPageTemplate>
  );
};
