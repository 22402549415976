import _ from 'lodash';
import moment from 'moment';

import {
  PaginationInput,
  UpdateManyOrderLineStatusInput,
  OrderFilterInput,
  useMarkAsShippedMutation as useBaseMarkAsShippedMutation,
  useMyBrewPipelinePageLazyQuery as useBaseMyBrewPipelinePageLazyQuery
} from 'generated/apiTypes';
import {
  cents2ReadableDollarsAndCents,
  sumBy
} from 'util/currency';

import { pageInfo2Pagination } from 'util/pagination';
import { routes } from 'routes';
import * as refetchQueries from 'util/refetchQueries';
import { IOrderBasic, OrderStatusValue } from 'components/shared/Types/Order';



export const TABLE_PAGE_SIZE = 25;

export interface IOrder extends IOrderBasic {
  distributorName: string;
}




export function useMyBrewPipelinePageLazyQuery(breweryId: string) {
  const [fnRunQuery, tuple] = useBaseMyBrewPipelinePageLazyQuery({fetchPolicy: "network-only"});

  const fnWrappedQuery = (filter: OrderFilterInput,  pagination: PaginationInput) => fnRunQuery({
    variables: {
      breweryId,
      pagination,
      filter
    }
  });

  const orders = _.map(tuple.data?.ordersForBrewery.page, (order): IOrder => {
    return {
      id: order.id,
      readableId: order.readableId,
      retailerName: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
      retailerState: order.retailer?.retailerInformation?.individualInformation?.state ?? '',
      distributorName: order.distributor.name ?? '',
      orderDate: moment(order.placedAt).format("MM-DD-YYYY"),
      orderTotal: `${cents2ReadableDollarsAndCents(_.sumBy(_.map(order.lines, line => line.minPtr * line.quantity)))}`,
      minPtr: order.lines ? order.lines?.reduce((acc, line) => acc + line.minPtr * line.quantity, 0) : 0,
      retailer: {
        name: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
        address: order.retailer?.retailerInformation?.individualInformation ? {
          locationName: order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
          street: order.retailer?.retailerInformation?.individualInformation?.street ?? '',
          city: order.retailer?.retailerInformation?.individualInformation?.city ?? '',
          state: order.retailer?.retailerInformation?.individualInformation?.state ?? '',
          zip: order.retailer?.retailerInformation?.individualInformation?.zip ?? ''
        } : undefined,
        purchaseStates: _.toString(order.retailer?.retailerInformation?.chainInformation?.purchaseStates) ?? order.retailer?.retailerInformation?.individualInformation?.state ?? '',
        contactName: `${order.retailer?.firstName} ${order.retailer?.lastName}`,
        cellNumber: order.retailer?.cell ?? '',
        email: order.retailer?.email ?? ''
      },
      distributor: {
        name: order.distributor.name,
        contactEmails: order.distributor.contactEmails ?? []
      },
      lines: _.map(order.lines, line => {
        return {
          orderId: order.id,
          isNew: false,
          orderLineId: line.id ?? '',
          brandName: line.brand.name ?? '',
          format: line.packingFormat.displayName ?? '',
          quantity: line.quantity,
          value: cents2ReadableDollarsAndCents(line.minPtr),
          statusName: line.status.name,
          statusDisplayName: line.status.displayName as OrderStatusValue
        };
      })
    };
  });

  return [
    fnWrappedQuery,
    {
      ...tuple,
      data: {
        pagination: pageInfo2Pagination(tuple.data?.ordersForBrewery.pageInfo),
        callToActions: _.map(_.compact(tuple.data?.brewery?.callToActions) ?? [], action => mapCallToActionType(action)),
        summary: {
          numberOfOrders: orders.length.toString(),
          minPtr: sumBy(orders.map(order => order.minPtr))
        },
        orders: orders
      }
    }
  ] as const;
}



export function useMarkAsShippedMutation(breweryId: string, filter: OrderFilterInput, pagination: PaginationInput) {
  const [
    fn,
    tuple
  ] = useBaseMarkAsShippedMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      refetchQueries.MyBrewPipelinePageRefetchQuery({
        breweryId,
        filter,
        pagination
      })
    ]
  });

  const fnWrappedMutation = (input: UpdateManyOrderLineStatusInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export interface ICallToAction {
  title: string;
  description: string;
  url: string;
}

function mapCallToActionType(type: string) {
  return _.get(__callToActionMap, type);
}

const __callToActionMap: _.Dictionary<ICallToAction> = {
  missingDistributor: {
    title: 'Important Information Required: Distributors',
    description: 'Our records indicate that your brewery does not have any linked distributors. Until distributors are added, your brands will not be available for purchase via our marketplace.',
    url: routes.distributors.path()
  }
};
