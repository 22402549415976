import React from 'react';

import { Icon } from 'components/shared/Icon/Icon';
import { TextInput } from 'components/shared/form/TextInput/TextInput';

import styles from './DebouncedSearchBox.module.css';
import { useDebouncedCallback } from 'util/hooks';



interface IProps {
  debounceMillis: number;
  onSetSearchText: (text: string) => void;
  placeholder?: string;
  defaultSearchText?: string;
}

export const DebouncedSearchBox: React.FC<IProps> = props => {
  const [searchText, setSearchText] = React.useState(props.defaultSearchText ?? '');

  const debouncedSetSearchText = useDebouncedCallback(text => {
    props.onSetSearchText(text);
  }, props.debounceMillis, []);

  React.useEffect(() => {
    if (props.defaultSearchText) {
      setSearchText(props.defaultSearchText);
    }
  }, [props.defaultSearchText, setSearchText]);



  return (
    <div className={styles.container}>
      <Icon
        className={styles.icon}
        type="search" />

      <TextInput
        className={styles.input}
        placeholder={props.placeholder}
        value={searchText}
        onChangeValue={text => {
          setSearchText(text);
          debouncedSetSearchText(text);
        }} />
    </div>
  );
};
