import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Select } from 'components/shared/form/Select/Select';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { EmailInput } from 'components/shared/form/TextInput/EmailInput';
import { PhoneNumberInput } from 'components/shared/form/TextInput/PhoneNumberInput';
import { getStateNameOptions } from 'util/stateNames';
import { IFormSectionProps } from '../IFormSectionProps';
import * as optionsUtil from 'util/options';

import styles from '../FormSection.module.css';



export interface IGeneralFormSectionProps extends IFormSectionProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  cellNumber: string;
  breweryName: string;
  breweryState: string;

  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setEmailAddress: (emailAddress: string) => void;
  setCellNumber: (cellNumber: string) => void;
  setBreweryName: (breweryName: string) => void;
  setBreweryState: (breweryState: string) => void;
}

export const GeneralFormSection: React.FC<IGeneralFormSectionProps> = props => {
  return (
    <div className={styles.formSection}>
      <SectionTitle>
        General
      </SectionTitle>

      <TextInput
        value={props.firstName}
        required
        onChangeValue={props.setFirstName}
        ref={_.partial(Form.captureElement, props.refForm, 'firstName')}
        label="FIRST NAME" />

      <TextInput
        value={props.lastName}
        required
        onChangeValue={props.setLastName}
        ref={_.partial(Form.captureElement, props.refForm, 'lastName')}
        label="LAST NAME" />

      <EmailInput
        value={props.emailAddress}
        required
        onChangeValue={props.setEmailAddress}
        ref={_.partial(Form.captureElement, props.refForm, 'emailAddress')}
        label="YOUR EMAIL" />

      <PhoneNumberInput
        value={props.cellNumber}
        required
        onChangeValue={props.setCellNumber}
        ref={_.partial(Form.captureElement, props.refForm, 'cellNumber')}
        label="YOUR MOBILE NUMBER" />

      <TextInput
        value={props.breweryName}
        required
        onChangeValue={props.setBreweryName}
        ref={_.partial(Form.captureElement, props.refForm, 'breweryName')}
        label="BREWERY NAME" />

      <Select
        label="BREWERY STATE"
        required
        options={getStateNameOptions()}
        value={optionsUtil.getOptionFromValue(getStateNameOptions(), props.breweryState)}
        onChange={option => props.setBreweryState(optionsUtil.getValueFromOption(option))} />
    </div>
  );
};
