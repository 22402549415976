/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import _ from 'lodash';

import { StateNames } from './stateNames';



const phoneNumberRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneNumberSchema = yup.string().matches(phoneNumberRegExp, {
  message: "${path} must be a valid phone number",
  excludeEmptyString: true
});



const urlRegExp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

export const urlSchema = yup.string().matches(urlRegExp, {
  message: '${path} must be a valid URL',
  excludeEmptyString: true
});



export const stateNameSchema = yup.string().oneOf(_.keys(StateNames), "${path} must be a valid US state name");



export function confirmPasswordSchema(ref: yup.Ref) {
  return yup.string().oneOf([ref], "Password must match");
}



export function extractErrorMessageFromValidation(schema: yup.Schema<unknown>, value?: unknown) {
  try {
    schema.validateSync(value);
    return '';
  } catch (e) {
    return _.head<string>(e.errors) ?? '';
  }
}



export function validator(inputSchema: yup.ObjectSchema, input: unknown) {
  try {
    return inputSchema.validateSync(input, {abortEarly: false});
  } catch (error) {
    console.error(error);
    throw error;
  }
}
