import _ from 'lodash';
import moment from 'moment';
import currency from 'currency.js';

import {
  OrderFilterInput,
  useOrdersSuperAdminPageLazyQuery as useBaseOrdersSuperAdminPageLazyQuery,
  useOrdersSuperAdminPageQuery as useBaseOrdersSuperAdminPageQuery,
  useUpdateOrderLineStatusMutation as useBaseUpdateOrderLineStatusMutation,
  UpdateOrderLineStatusInput
} from 'generated/apiTypes';

import * as refetchQueries from 'util/refetchQueries';
import * as currencyUtil from 'util/currency';
import { IOrderBasic, OrderStatusKey, OrderStatusValue } from 'components/shared/Types/Order';



export interface IOrderSuperAdmin extends IOrderBasic {
  breweryName: string;
  breweryState: string;
}

export function useOrdersSuperAdminPageLazyQuery() {
  const [fnRunQuery, tuple] = useBaseOrdersSuperAdminPageLazyQuery();

  const fnWrappedQuery = (filter: OrderFilterInput) => fnRunQuery({variables: {filter}});

  return [
    fnWrappedQuery,
    {
      ...tuple,
      data: {
        orders: _.map(tuple.data?.allOrders ?? [], (order): IOrderSuperAdmin => {
          return {
            id: order.id,
            readableId: order.readableId,
            retailerName: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
            retailerState: order.retailer?.retailerInformation?.individualInformation?.state ?? '',
            breweryName: order.brewery.name,
            breweryState: order.brewery.state,
            orderDate: moment(order.placedAt).format("MM-DD-YYYY"),
            orderTotal: `${currencyUtil.cents2ReadableDollarsAndCents(_.sumBy(_.map(order.lines, line => line.minPtr * line.quantity)))}`,
            minPtr: order.lines ? order.lines?.reduce((acc, line) => acc + line.minPtr * line.quantity, 0) : 0,
            retailer: {
              name: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
              address: order.retailer?.retailerInformation?.individualInformation ? {
                locationName: order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
                street: order.retailer?.retailerInformation?.individualInformation?.street ?? '',
                city: order.retailer?.retailerInformation?.individualInformation?.city ?? '',
                state: order.retailer?.retailerInformation?.individualInformation?.state ?? '',
                zip: order.retailer?.retailerInformation?.individualInformation?.zip ?? ''
              } : undefined,
              purchaseStates: _.toString(order.retailer?.retailerInformation?.chainInformation?.purchaseStates) ?? order.retailer?.retailerInformation?.individualInformation?.state ?? '',
              contactName: `${order.retailer?.firstName} ${order.retailer?.lastName}`,
              cellNumber: order.retailer?.cell ?? '',
              email: order.retailer?.email ?? ''
            },
            distributor: {
              name: order.distributor.name,
              contactEmails: order.distributor.contactEmails ?? []
            },
            lines: _.map(order.lines, line => {
              return {
                orderId: order.id,
                isNew: false,
                orderLineId: line.id ?? '',
                brandName: line.brand.name ?? '',
                format: line.packingFormat.displayName ?? '',
                quantity: line.quantity,
                value: `${currencyUtil.cents2ReadableDollarsAndCents(line.minPtr)}`,
                statusName: line.status.name,
                statusDisplayName: line.status.displayName as OrderStatusValue
              };
            })
          };
        })
      }
    }
  ] as const;
}


export function useOrdersSuperAdminPageQuery(filter: OrderFilterInput) {
  const tuple = useBaseOrdersSuperAdminPageQuery({variables: {filter}});


  return {
    ...tuple,
    data: {
      orderLines: _.flatten(_.map(tuple.data?.allOrders, order => {
        return _.map(order.lines, line => {
          return {
            orderLineId: line.id,
            readableId: `#${order.readableId}-${line.readableId}`,
            retailerName: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
            brandName: line.brand.name,
            orderDate: moment(order.placedAt).format("MM-DD-YYYY"),
            value: `$${currency(line.minPtr, { fromCents: true }).format()}`,
            status: line.status.name
          };
        });
      }))
    }
  };
}



export function useUpdateOrderLineStatusMutation(filter: OrderFilterInput) {
  const [fn, tuple] = useBaseUpdateOrderLineStatusMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.OrdersSuperAdminPageRefetchQuery({filter})]
  });

  const fnWrappedMutation = (input: UpdateOrderLineStatusInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export const __statusOptions: {
  label: OrderStatusValue;
  value: OrderStatusKey;
}[] = [
  {
    label: 'New Order',
    value: 'newOrder'
  },
  {
    label: 'Shipped',
    value: 'shipped'
  },
  {
    label: 'Partly Received',
    value: 'partlyReceived'
  },
  {
    label: 'Received',
    value: 'received'
  }
];
