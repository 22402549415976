import React from 'react';

import styles from './LoadingOverlay.module.css';

import imgSpinner from 'assets/spinner.svg';



export const LoadingOverlay: React.FC = () => (
  <div className={styles.loading}>
    <img
      src={imgSpinner}
      alt="loading" />
  </div>
);
