import React from 'react';
import _ from 'lodash';

import styles from './ImageInput.module.css';

import imgSvg from 'assets/icons/add-to-queue.svg';



export interface IImageInputProps {
  imageUrl: string;

  label?: string;
  required?: boolean;
  size?: number | [number, number];
  fit?: 'cover' | 'contain';

  onChange: (imageUrl: string) => void;
}

export const ImageInput: React.FC<IImageInputProps> = props => {
  const refInput = React.useRef<HTMLInputElement>(null);
  const [
    width,
    height
  ] = _.isArray(props.size) ? props.size : [
    props.size ?? 150,
    props.size ?? 150
  ];

  function handleClick() {
    if (props.imageUrl) {
      props.onChange('');
    } else {
      refInput.current && refInput.current.click();
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files || _.isEmpty(event.target.files)) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (!_.isString(reader.result)) {
        return;
      }

      if (_.gt(reader.result, 10000000)) {
        return;
      }

      props.onChange(reader.result);
    }, false);

    reader.readAsDataURL(file);
  }

  return (
    <div className={styles.container}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
        </label>
      )}

      <input
        className={styles.input}
        type='file'
        ref={refInput}
        onChange={handleChange} />

      <div
        style={{
          width,
          height,
          backgroundColor: props.imageUrl ? '#fff' : '#f0f2f4'
        }}
        className={styles.imageContainer}
        onClick={handleClick}>
        {props.imageUrl ? (
          <>
            <img src={imgSvg} className={styles.icon} />

            <img
              className={styles.preview}
              style={{objectFit: props.fit ?? "contain"}}
              alt="preview"
              src={props.imageUrl}
              width={width}
              height={height} />
          </>
        ) : (
          <>
            <img src={imgSvg} />

            <span className={styles.instruction}>ADD PHOTO</span>
          </>
        )}
      </div>
    </div>
  );
};



ImageInput.defaultProps = {size: 150};
