import React from 'react';

import { LoadingOverlay } from 'components/shared/LoadingOverlay/LoadingOverlay';
import { Logo } from 'components/shared/Logo/Logo';
import { VersionIndicator } from 'components/shared/VersionIndicator/VersionIndicator';

import styles from './OnboardingPageTemplate.module.css';



export interface IOnboardingPageTemplateProps {
  loading?: boolean;
}

export const OnboardingPageTemplate: React.FC<IOnboardingPageTemplateProps> = props => {
  return (
    <div className={styles.container}>
      {props.loading && (
        <LoadingOverlay />
      )}

      <header className={styles.header}>
        <Logo width={225} />
      </header>

      <main className={styles.content}>
        <div className={styles.contentBox}>
          {props.children}
        </div>
      </main>

      <VersionIndicator />
    </div>
  );
};
