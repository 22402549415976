import { IOption } from 'util/options';



export const availableRetailerTypes: IOption[] = [
  {
    label: 'On-Premise',
    value: 'onPremise'
  },
  {
    label: 'Off-Premise',
    value: 'offPremise'
  }
];


export const availableRetailerChainTypes: IOption[] = [
  {
    label: 'Retail Chain',
    value: 'chain'
  },
  {
    label: 'Individual Retail Location',
    value: 'individual'
  }
];
