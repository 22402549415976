import React from 'react';
import classnames from 'classnames';

import { Icon } from 'components/shared/Icon/Icon';

import styles from './BrandCategoryCard.module.css';



export interface IBrandCategoryCardProps {
  title: string;
  brandCount: number;
  selected: boolean;
  onClick: () => void;
}

export const BrandCategoryCard: React.FC<IBrandCategoryCardProps> = props => {
  return (
    <div
      className={classnames([styles.container, {[styles.selected]: props.selected}])}
      onClick={props.onClick}>
      <Icon type='folder' />

      <div className={styles.content}>
        <div className={styles.title}>
          {props.title}
        </div>

        <div className={styles.subtitle}>
          {props.brandCount} brands
        </div>
      </div>
    </div>
  );
};
