import React from 'react';
import _ from 'lodash';

import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { SectionTitleWarning } from 'components/shared/SectionTitleWarning/SectionTitleWarning';
import { Button } from 'components/shared/Button/Button';
import { ConfirmModal } from 'components/shared/modals/ConfirmModal/ConfirmModal';
import { SubscriptionModal } from './SubscriptionModal/SubscriptionModal';
import { getStateNameById } from 'util/stateNames';
import { ISubscriptionInfo } from '../dataService';

import styles from './SubscriptionSection.module.css';



export interface ISubscriptionSectionProps {
  isActing: boolean;
  subscription: ISubscriptionInfo | undefined;

  onSaveAndAgree: (states: string[], stripePayload: string) => void;
  onCancel: () => void;
}

export const SubscriptionSection: React.FC<ISubscriptionSectionProps> = props => {
  const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = React.useState(false);

  async function handleCancelSubscription() {
    await props.onCancel();
    setShowCancelSubscriptionModal(false);
  }

  async function handleSaveAndAgree(states: string[], stripePayload: string) {
    await props.onSaveAndAgree(states, stripePayload);
    setShowSubscriptionModal(false);
  }

  return (
    <div className={styles.container}>
      <SectionTitle>
        Subscription
        {props.isActing && (<SectionTitleWarning>Note: You cannot change the subscription status on behalf of the user</SectionTitleWarning>)}
      </SectionTitle>

      <div className={styles.form}>
        {props.subscription ? (
          <div>
            <p className={styles.paragraph}>
              Billing started from:&nbsp;
              <em>{props.subscription.createdOn}</em>
              <br />
              {props.subscription.cancelAt && (
                <>
                  {props.subscription.hasExpired ? (
                    <>
                      This subscription expired on:&nbsp;
                      <em>{props.subscription.cancelAt}</em>
                    </>
                  ) : (
                    <>
                        This subscription will expire on:&nbsp;
                      <em>{props.subscription.cancelAt}</em>
                    </>
                  )}
                  <br />
                </>
              )}
              Your subscription allows distribution to the following {props.subscription.distributingStates.length} state(s):&nbsp;<br />
              <em>{_.join(_.map(props.subscription.distributingStates, getStateNameById), ', ')}</em>
            </p>
          </div>
        ) : (
          <p className={styles.paragraph}>
          Currently, you do not have a subscription. <br />
          You will not be able to list your brands on our marketplace until your subscription has been created.<br />
          List your brands on our marketplace starting at $49/mo.
          </p>
        )}

        <div className={styles.buttonContainer}>
          <Button
            disabled={props.isActing}
            colorStyle="form"
            onClick={() => setShowSubscriptionModal(true)}>
            {props.subscription ? 'UPDATE SUBSCRIPTION' : 'SUBSCRIBE'}
          </Button>

          {props.subscription && !props.subscription.cancelAt && (
            <Button
              disabled={props.isActing}
              colorStyle="form"
              onClick={() => setShowCancelSubscriptionModal(true)}>
              CANCEL SUBSCRIPTION
            </Button>
          )}
        </div>
      </div>

      <SubscriptionModal
        subscription={props.subscription}
        isOpen={showSubscriptionModal}
        onRequestClose={() => setShowSubscriptionModal(false)}
        onSaveAndAgree={handleSaveAndAgree} />

      <ConfirmModal
        title="Are you sure you want to cancel your subscription?"
        content={`If you continue with the cancellation, all your brands will be unlisted on ${props.subscription?.periodEndDate}, and you will not be able to receive any further orders from this date.`}
        confirmLabel="Yes, cancel my subscription"
        cancelLabel="I’ve changed my mind"
        onConfirmed={handleCancelSubscription}
        isOpen={showCancelSubscriptionModal}
        onRequestClose={() => setShowCancelSubscriptionModal(false)} />
    </div>
  );
};
