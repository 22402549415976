import React from 'react';

import {
  BrowserRouter,
  Switch,
  withRouter
} from 'react-router-dom';
import {
  routes,
  superAdminRoutes
} from './routes';
import {
  AuthProtectedRoute,
  SuperAdminProtectedRoute,
  AnonymousRoute,
  LoggedInUserOnboardingRoute
} from 'components/shared/pages/AuthProtectedRoute/AuthProtectedRoute';

import { ToastProvider } from 'react-toast-notifications';
import { HomePage } from 'components/pages/HomePage/HomePage';
import { AccountTypeNotPermittedPage } from 'components/pages/onboarding/AccountTypeNotPermittedPage/AccountTypeNotPermittedPage';
import { AccountDisabledPage } from 'components/pages/onboarding/AccountDisabledPage/AccountDisabledPage';
import { SignInPage } from 'components/pages/onboarding/SignInPage/SignInPage';
import { CreateAccountPage } from 'components/pages/onboarding/CreateAccountPage/CreateAccountPage';
import { BrandListPage } from 'components/pages/brands/BrandListPage/BrandListPage';
import { BrandFormPage } from 'components/pages/brands/BrandFormPage/BrandFormPage';
import { DistributorsListPage } from 'components/pages/DistributorsListPage/DistributorsListPage';
import { RetailerListPage } from 'components/pages/RetailerListPage/RetailerListPage';
import { ProfilePage } from 'components/pages/ProfilePage/ProfilePage';
import { SettingsPage } from 'components/pages/SettingsPage/SettingsPage';
import { DistributorSubmittedPage } from 'components/pages/onboarding/DistributorSubmittedPage/DistributorSubmittedPage';
import { ForgotPasswordPage } from 'components/pages/onboarding/ForgotPasswordPage/ForgotPasswordPage';
import { ResetPasswordPage } from 'components/pages/onboarding/ResetPasswordPage/ResetPasswordPage';
import { CompleteEmployeeRegistration } from 'components/pages/onboarding/CompleteEmployeeRegistration/CompleteEmployeeRegistration';
import { AccountsPage } from 'components/pages/superadmin/AccountsPage/AccountsPage';
import { DistributorsPage } from 'components/pages/superadmin/DistributorsPage/DistributorsPage';
import { ApplicationsPage } from 'components/pages/superadmin/ApplicationsPage/ApplicationsPage';
import { BreweriesPage } from 'components/pages/superadmin/BreweriesPage/BreweriesPage';
import { RetailersPage } from 'components/pages/superadmin/RetailersPage/RetailersPage';
import { ReportsPage } from 'components/pages/superadmin/ReportsPage/ReportsPage';
import { OrdersPage } from 'components/pages/superadmin/OrdersPage/OrdersPage';



const App: React.FC = () => {
  return (
    <ToastProvider
      transitionDuration={50}
      autoDismiss={true}>
      <BrowserRouter>
        <Switch>
          {/** Onboarding Routes */}

          <AnonymousRoute
            exact
            path={routes.onboarding.signIn.rawPath}
            component={withRouter(SignInPage)} />

          <AnonymousRoute
            exact
            path={routes.onboarding.forgotPassword.rawPath}
            component={withRouter(ForgotPasswordPage)} />

          <AnonymousRoute
            exact
            path={routes.onboarding.signUp.rawPath}
            component={withRouter(CreateAccountPage)} />

          <AnonymousRoute
            exact
            path={routes.onboarding.resetPassword.rawPath}
            component={withRouter(ResetPasswordPage)} />

          <AnonymousRoute
            exact
            path={routes.onboarding.completeEmployeeRegistration.rawPath}
            component={withRouter(CompleteEmployeeRegistration)} />

          <AnonymousRoute
            exact
            path={routes.onboarding.distributorSubmitted.rawPath}
            component={withRouter(DistributorSubmittedPage)} />

          <LoggedInUserOnboardingRoute
            exact
            path={routes.onboarding.accountTypeNotPermitted.rawPath}
            component={withRouter(AccountTypeNotPermittedPage)} />

          <LoggedInUserOnboardingRoute
            exact
            path={routes.onboarding.accountDisabled.rawPath}
            component={withRouter(AccountDisabledPage)} />

          {/** Protected Routes */}

          <AuthProtectedRoute
            exact
            path={routes.home.rawPath}
            component={withRouter(HomePage)} />

          <AuthProtectedRoute
            exact
            path={routes.brands.rawPath}
            component={withRouter(BrandListPage)} />

          <AuthProtectedRoute
            exact
            path={routes.distributors.rawPath}
            component={withRouter(DistributorsListPage)} />

          <AuthProtectedRoute
            exact
            path={routes.brands.routes.new.rawPath}
            component={withRouter(BrandFormPage)} />

          <AuthProtectedRoute
            exact
            path={routes.brands.routes.edit.rawPath}
            component={withRouter(BrandFormPage)} />

          <AuthProtectedRoute
            exact
            path={routes.retailers.rawPath}
            component={withRouter(RetailerListPage)} />

          <AuthProtectedRoute
            exact
            path={routes.profile.rawPath}
            component={withRouter(ProfilePage)} />

          <AuthProtectedRoute
            exact
            path={routes.settings.rawPath}
            component={withRouter(SettingsPage)} />

          {/** SuperAdmin Protected Routes */}

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.accounts.rawPath}
            component={withRouter(AccountsPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.applications.rawPath}
            component={withRouter(ApplicationsPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.breweries.rawPath}
            component={withRouter(BreweriesPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.distributors.rawPath}
            component={withRouter(DistributorsPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.retailers.rawPath}
            component={withRouter(RetailersPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.reports.rawPath}
            component={withRouter(ReportsPage)} />

          <SuperAdminProtectedRoute
            exact
            path={superAdminRoutes.orders.rawPath}
            component={withRouter(OrdersPage)} />
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
