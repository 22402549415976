import * as yup from 'yup';

import { genInputComponent } from '../GenericInput/GenericInput';
import { extractErrorMessageFromValidation } from 'util/validation';



export const TextInput = genInputComponent('text', (value, required) => {
  let schema = yup.string().max(1000);
  if (required) {
    schema = schema.required();
  }

  return extractErrorMessageFromValidation(schema, value);
});

