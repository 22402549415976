import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Select } from 'components/shared/form/Select/Select';
import { MultipleTextInput } from 'components/shared/form/MultipleTextInput/MultipleTextInput';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { getStateNameOptions } from 'util/stateNames';
import { useAlert } from 'util/hooks';
import { validator } from 'util/validation';
import { mergeByExistingKeys } from 'util/object';
import * as optionsUtil from 'util/options';

import * as dataService from '../dataService';



export interface IDistributorFormModalProps extends IModalTemplateProps {
  distributor: dataService.IDistributor | null;
  onSubmit: (data: dataService.IDistributor) => void;
}

export const DistributorFormModal: React.FC<IDistributorFormModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const {
    alertOnError, AlertModalProps
  } = useAlert();
  const [formValues, setFormValues] = React.useState<dataService.IDistributor>({
    id: '',
    name: '',
    distributionStates: [],
    contactEmails: []
  });

  function handleChangeValue(value: Partial<dataService.IDistributor>) {
    setFormValues(originalValue => ({
      ...originalValue,
      ...value
    }));
  }

  function handleClear() {
    setFormValues({
      id: '',
      name: '',
      distributionStates: [],
      contactEmails: []
    });
  }

  function handleFormValidate() {
    try {
      return !!validator(dataService.distributorSchema, formValues);
    } catch (err) {
      alertOnError(err);
      return false;
    }
  }

  function handleSubmit() {
    props.onSubmit(formValues);
  }

  React.useEffect(() => {
    if (!_.isNil(props.distributor)) {
      setFormValues(originalValues => props.distributor ? mergeByExistingKeys(originalValues, props.distributor) : originalValues);
    }
  }, [setFormValues, props.distributor]);

  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      onAfterClose={handleClear}
      modalTitle={_.isNil(props.distributor?.id) ? "Add Distributor" : "Edit Distributor"}>
      <div style={{marginBottom: 38}}>
        <Form
          fnValidateForm={handleFormValidate}
          onSuccessfulSubmit={handleSubmit}
          ref={refForm}>
          <TextInput
            required
            ref={_.partial(Form.captureElement, refForm, 'name')}
            label="DISTRIBUTOR NAME"
            value={formValues.name}
            onChangeValue={name => handleChangeValue({name})} />

          <Select
            isMulti
            required
            label="DISTRIBUTION STATES"
            onChange={options => handleChangeValue({distributionStates: optionsUtil.getValuesFromOptions(options)})}
            value={optionsUtil.getOptionsFromValues(getStateNameOptions(), formValues.distributionStates) as any}
            options={getStateNameOptions()} />

          <MultipleTextInput
            fnValidateEach={value => yup.string().email().required().validateSync(value)}
            label="CONTACT EMAILS"
            values={formValues.contactEmails}
            onChangeValues={contactEmails => handleChangeValue({contactEmails})} />

          <Button
            style={{marginTop: 38}}
            type="submit">Save</Button>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </ModalTemplate>
  );
};
