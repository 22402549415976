import { useStopActingAsBreweryOwnerMutation as useBaseStopActingAsBreweryOwnerMutation } from 'generated/apiTypes';



export function useStopActingAsBreweryOwnerMutation() {
  const [
    fn,
    tuple
  ] = useBaseStopActingAsBreweryOwnerMutation();


  const fnWrappedMutation = (siteUserId: string) => {
    return fn({variables: {siteUserId}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}

