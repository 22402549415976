import { Styles as ReactSelectStyles } from 'react-select';



export const SelectStyles: ReactSelectStyles<any, any> = {
  indicatorSeparator: () => ({display: 'none'}),
  option: (base, state) => ({
    ...base,
    fontSize: 15,
    lineHeight: 1.67,
    fontFamily: '"Rubik", sans-serif',
    color: state.isSelected ? '#fff' : '#9f9f9f'
  }),
  singleValue: base => ({
    ...base,
    fontSize: 15,
    lineHeight: 1.67,
    fontFamily: '"Rubik", sans-serif',
    color: '#9f9f9f'
  }),
  container: base => ({
    ...base,
    borderColor: '#f4f5f7'
  }),
  control: base => ({
    ...base,
    padding: '8px 6px'
  })
};
