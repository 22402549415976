import React from 'react';
import _ from 'lodash';

import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { Select } from 'components/shared/form/Select/Select';
import { UrlInput } from 'components/shared/form/TextInput/UrlInput';
import { ImageInput } from 'components/shared/form/ImageInput/ImageInput';
import { getStateNameOptions } from 'util/stateNames';
import {
  IProfileFormType, profileSchema
} from '../dataService';
import * as optionsUtil from 'util/options';
import { Button } from 'components/shared/Button/Button';

import * as dataService from '../dataService';

import styles from './BreweryInfoForm.module.css';



export interface IBreweryInfoFormProps {
  formData: dataService.IProfileFormType;

  onSaveFormData: (values: IProfileFormType) => void;
}

export const BreweryInfoForm: React.FC<IBreweryInfoFormProps> = props => {
  const refForm = React.useRef<Form>(null);
  const [error, setError] = React.useState('');
  const [formValues, setFormValues] = React.useState<dataService.IProfileFormType>({
    name: '',
    city: '',
    state: '',
    website: '',
    untappd: '',
    description: '',
    logo: '',
    images: []
  });

  React.useEffect(() =>{
    setFormValues(props.formData);
  }, [props.formData]);

  function handleChangeOtherImageUri(index: number, imageUri: string) {
    const images = _.cloneDeep(formValues.images);
    images[index] = imageUri;
    handleChangeFormValues({images});
  }

  function handleChangeFormValues(values: Partial<dataService.IProfileFormType>) {
    setFormValues({
      ...formValues,
      ...values
    });
  }

  function handleFormValidation() {
    try {
      profileSchema.validateSync(formValues);
      return true;
    } catch (e) {
      setError(e.message);
      return false;
    }
  }

  return (
    <div className={styles.container}>
      <Form
        ref={refForm}
        extraClassName={styles.form}
        fnValidateForm={handleFormValidation}
        onSuccessfulSubmit={() => props.onSaveFormData(formValues)}>
        <SectionTitle>
          About My Brewery
        </SectionTitle>

        <div className={styles.inputRow}>
          <TextInput
            required
            label="BREWERY NAME"
            value={formValues.name}
            onChangeValue={name => handleChangeFormValues({name})}
            ref={_.partial(Form.captureElement, refForm, 'name')} />

          <TextInput
            required
            label="BREWERY CITY"
            value={formValues.city}
            onChangeValue={city => handleChangeFormValues({city})}
            ref={_.partial(Form.captureElement, refForm, 'city')} />

          <Select
            required
            options={getStateNameOptions()}
            label="BREWERY STATE"
            value={optionsUtil.getOptionFromValue(getStateNameOptions(), formValues.state)}
            onChange={option => handleChangeFormValues({state: optionsUtil.getValueFromOption(option)})} />
        </div>

        <div className={styles.inputRow}>
          <UrlInput
            required
            label="WEBSITE"
            value={formValues.website}
            onChangeValue={website => handleChangeFormValues({website})}
            ref={_.partial(Form.captureElement, refForm, 'website')} />

          <div>
            <label className={styles.label}>
              UNTAPPD PROFILE ID
            </label>

            <TextInput
              value={formValues.untappd}
              onChangeValue={untappd => handleChangeFormValues({untappd})}
              ref={_.partial(Form.captureElement, refForm, 'untappd')} />
          </div>
        </div>

        <div className={styles.fullWidthInputRow}>
          <TextInput
            required
            label="BREWERY DESCRIPTION (160 CHARACTER LIMIT)"
            value={formValues.description}
            maxLength={160}
            onChangeValue={description => handleChangeFormValues({description})}
            ref={_.partial(Form.captureElement, refForm, 'description')} />
        </div>

        <div className={styles.fullWidthInputRow}>
          <label className={styles.label}>
            BREWER PROFILE IMAGE (RECOMMENDED SIZE: 500 X 500)
          </label>

          <ImageInput
            imageUrl={formValues.logo}
            onChange={logo => handleChangeFormValues({logo})} />
        </div>

        <div className={styles.fullWidthInputRow}>
          <label className={styles.label}>
            BREWERY IMAGES & GRAPHICS (RECOMMENDED SIZE: 500 X 500, MAXIMUM 6 FILES)
          </label>

          <div className={styles.images}>
            {_.chain(formValues.images)
              .concat([''])
              .take(6)
              .map((imageUri, index) => (
                <ImageInput
                  key={index}
                  imageUrl={imageUri}
                  onChange={imageUrl => handleChangeOtherImageUri(index, imageUrl)} />
              ))
              .value()}
          </div>
        </div>

        {error && (<p>{error}</p>)}

        <Button
          type="submit"
          style={{width: 260}}>
          Save
        </Button>
      </Form>
    </div>
  );
};
