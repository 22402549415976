import React from 'react';
import _ from 'lodash';

import { ImageInput } from 'components/shared/form/ImageInput/ImageInput';

import styles from './MultipleImageInput.module.css';



export interface IMultipleImageInputProps {
  imageUrls: string[];

  label?: string;
  required?: boolean;
  max?: number;
  size?: number | [number, number];

  onChange: (imageUrls: string[]) => void;
}

export const MultipleImageInput: React.FC<IMultipleImageInputProps> = props => {
  function handleChange(index:number, imageUrl: string) {
    const imageUrls = _.cloneDeep(props.imageUrls);
    imageUrls[index] = imageUrl;

    props.onChange(_.compact(imageUrls));
  }

  return (
    <div className={styles.container}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
        </label>
      )}

      <div className={styles.images}>
        {_.chain(props.imageUrls)
          .concat([''])
          .take(props.max ?? Infinity)
          .map((imageUrl, index) => (
            <ImageInput
              key={index}
              imageUrl={imageUrl}
              onChange={value => handleChange(index, value)} />
          )).value()}
      </div>
    </div>
  );
};
