import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  getOptionsFromValues,
  getValuesFromOptions
} from 'util/options';

import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { Table } from 'components/shared/Table/Table';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';
import { Button } from 'components/shared/Button/Button';
import { TableCollapsibleSection } from 'components/shared/Table/TableCollapsibleSection/TableCollapsibleSection';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { DateInput } from 'components/shared/form/TextInput/DateInput';
import { Select } from 'components/shared/form/Select/Select';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Pagination } from 'components/shared/Table/Pagination/Pagination';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { validator } from 'util/validation';
import { useAlert } from 'util/hooks';
import { makeCsv } from 'util/csv';

import * as dataService from './dataService';

import styles from './ReportsPage.module.css';



export const ReportsPage: React.FC = () => {
  const refForm = React.useRef<Form>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const queryTuple = dataService.useSuperAdminReportsOptionsQuery();
  const {
    AlertModalProps, alertOnError
  } = useAlert();
  const [filterValues, setFilterValues] = React.useState<dataService.IReportFilterType>({
    fromDate: new Date(),
    toDate: new Date()
  });
  const [fnRunQuery, reportQueryTuple] = dataService.useSuperAdminReportsPageLazyQuery();
  const [isFreshPageLoad, setIsFreshPageLoad] = React.useState(true);

  function handleChangeFilterValues(values: Partial<dataService.IReportFilterType>) {
    setFilterValues(state => ({
      ...state,
      ...values
    }));
  }

  function handleUpdateQuery() {
    console.debug({
      filters: {
        ...filterValues,
        currentPage
      }
    });

    fnRunQuery({
      retailerName: filterValues.retailerName,
      brands: filterValues.brands,
      breweries: filterValues.breweries,
      users: filterValues.users,
      states: filterValues.states,
      fromDate: moment(filterValues.fromDate).toISOString(),
      toDate: moment(filterValues.toDate).toISOString()
    }, {
      pageSize: 20,
      currentPage
    });
  }

  function handleClearFilters() {
    setFilterValues({
      fromDate: new Date(),
      toDate: new Date()
    });
  }

  function handleFormValidation() {
    try {
      return !!validator(dataService.reportFilterSchema, filterValues);
    } catch (err) {
      if ('errors' in err) {
        alertOnError(err);
      }
      return false;
    }
  }

  function handleDownload() {
    makeCsv<dataService.IReportOrder>(dataService.exportColumnDefs, reportQueryTuple.data.orderLines, 'reports');
  }

  React.useEffect(() => {
    if (isFreshPageLoad) {
      fnRunQuery({}, {
        pageSize: 20,
        currentPage
      });
      setIsFreshPageLoad(false);
    }
  }, [isFreshPageLoad, fnRunQuery, setIsFreshPageLoad]);


  const loading = _.some([queryTuple, reportQueryTuple], tuple => tuple.loading);

  return (
    <PageTemplate loading={loading}>
      <TableHeader>
        <TableTitle>Reports</TableTitle>

        <Button
          style={{width: 240}}
          colorStyle="secondary"
          onClick={handleDownload}>
          Download CSV File
        </Button>
      </TableHeader>

      <TableCollapsibleSection openLabel="OPEN FILTERS">
        <Form
          ref={refForm}
          extraClassName={styles.filters}
          fnValidateForm={handleFormValidation}
          onSuccessfulSubmit={handleUpdateQuery}>
          <div className={styles.filterRow}>
            <TextInput
              label="RETAILER NAME"
              ref={_.partial(Form.captureElement, refForm, 'retailerName')}
              value={filterValues.retailerName}
              onChangeValue={retailerName => handleChangeFilterValues({retailerName})} />

            <Select
              isMulti
              isClearable
              label="BREWERIES"
              options={queryTuple.data.breweries}
              value={getOptionsFromValues(queryTuple.data.breweries, filterValues.breweries ?? []) as any}
              onChange={options => handleChangeFilterValues({breweries: getValuesFromOptions(options)})} />

            <Select
              isMulti
              isClearable
              label="BRAND NAMES"
              options={queryTuple.data.brands}
              value={getOptionsFromValues(queryTuple.data.brands, filterValues.brands ?? []) as any}
              onChange={options => handleChangeFilterValues({brands: getValuesFromOptions(options)})} />
          </div>

          <div className={styles.filterRow}>
            <Select
              isMulti
              isClearable
              label="ORDERED BY"
              options={queryTuple.data.users}
              value={getOptionsFromValues(queryTuple.data.users, filterValues.users ?? []) as any}
              onChange={options => handleChangeFilterValues({users: getValuesFromOptions(options)})} />

            <DateInput
              label="FROM DATE"
              ref={_.partial(Form.captureElement, refForm, 'fromDate')}
              value={moment(filterValues.fromDate).format('YYYY-MM-DD')}
              onChangeValue={fromDate => handleChangeFilterValues({fromDate: new Date(fromDate)})} />

            <DateInput
              label="TO DATE"
              ref={_.partial(Form.captureElement, refForm, 'toDate')}
              value={moment(filterValues.toDate).format('YYYY-MM-DD')}
              onChangeValue={toDate => handleChangeFilterValues({toDate: new Date(toDate)})} />
          </div>

          <div className={styles.filterRow}>
            <Select
              isMulti
              isClearable
              label="STATES"
              options={queryTuple.data.states}
              value={getOptionsFromValues(queryTuple.data.states, filterValues.states ?? []) as any}
              onChange={options => handleChangeFilterValues({states: getValuesFromOptions(options)})} />

            <div></div>

            <div className={styles.filterButtons}>
              <Button
                type="button"
                colorStyle="secondary"
                onClick={handleClearFilters}>
                Clear
              </Button>

              <Button
                type="submit"
                onClick={handleUpdateQuery}>
                Apply Filters
              </Button>
            </div>
          </div>
        </Form>
      </TableCollapsibleSection>

      <Table
        columnDefs={[
          {
            label: 'Order ID',
            fnCellContent: d => d.orderId
          },
          {
            label: 'Retailer Name',
            fnCellContent: d => d.retailerName
          },
          {
            label: 'State',
            fnCellContent: d => _.join(_.split(d.states, ','), ', ')
          },
          {
            label: 'Brand Names',
            fnCellContent: d => _.join(d.brandNames, ', ')
          },
          {
            label: 'Order Date',
            fnCellContent: d => moment(d.orderDate).format('MM-DD-YYYY')
          },
          {
            label: 'Status',
            fnCellContent: d => d.status
          },
          {
            label: 'Order Amount',
            fnCellContent: d => `${d.minPtr} - ${d.maxPtr}`
          }
        ]}
        data={reportQueryTuple.data.orderLines} />

      <Pagination
        pageCount={reportQueryTuple.data.pagination.numberOfPages}
        currentPage={currentPage}
        goToPage={pageIndex => {
          setCurrentPage(pageIndex);
          handleUpdateQuery();
        }} />

      <AlertModal {...AlertModalProps} />
    </PageTemplate>
  );
};
