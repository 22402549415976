import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';

import {
  useSuperAdminReportsOptionsQuery as useBaseSuperAdminReportsOptionsQuery,
  useSuperAdminReportsPageLazyQuery as useBaseSuperAdminReportsPageLazyQuery,
  ReportFilterInput,
  PaginationInput
} from 'generated/apiTypes';

import { IExportColumnDef } from 'util/csv';
import { getOrderStatus } from 'util/orderStatus';
import { sumBy } from 'util/currency';



export function useSuperAdminReportsOptionsQuery() {
  const tuple = useBaseSuperAdminReportsOptionsQuery();

  return {
    ...tuple,
    data: {
      users: _.map(tuple.data?.platformRetailers ?? [], user => {
        return {
          value: user.id,
          label: `${user.firstName} ${user.lastName}`
        };
      }),
      brands: _.map(tuple.data?.allBrands ?? [], brand => {
        return {
          value: brand.id,
          label: brand.name
        };
      }),
      breweries: _.map(tuple.data?.allBreweries ?? [], brewery => {
        return {
          value: brewery.id,
          label: brewery.name
        };
      }),
      states: _.chain(tuple.data?.platformRetailers ?? [])
        .flatMap(retailer => {
          const chainType = retailer.retailerInformation?.retailerChainStatus.name;
          if (chainType === 'chain') {
            return retailer.retailerInformation?.chainInformation?.purchaseStates;
          } else if (chainType === 'individual') {
            return retailer.retailerInformation?.individualInformation?.state;
          } else {
            return [];
          }
        })
        .compact()
        .uniq()
        .map(state => ({
          value: state,
          label: state
        }))
        .value()
    }
  };
}



export function useSuperAdminReportsPageLazyQuery() {
  const [fnRunQuery, tuple] = useBaseSuperAdminReportsPageLazyQuery();

  const fnWrappedQuery = (filter: ReportFilterInput,  pagination: PaginationInput) => fnRunQuery({
    variables: {
      pagination,
      filter
    }
  });

  const orders = tuple.data?.report.page;

  return [
    fnWrappedQuery,
    {
      ...tuple,
      data: {
        pagination: {
          totalRecords: tuple.data?.report.pageInfo.total ?? 0,
          pageSize: tuple.data?.report.pageInfo.pageSize ?? 0,
          offset: tuple.data?.report.pageInfo.offset ?? 0,
          numberOfPages: ((tuple.data?.report.pageInfo.total ?? 0) / (tuple.data?.report.pageInfo.pageSize ?? 0) ?? 1)
        },

        orderLines: _.map(orders, order => {
          return {
            orderId: order.readableId,
            retailerName: order.retailer?.retailerInformation?.chainInformation?.name ?? order.retailer?.retailerInformation?.individualInformation?.locationName ?? '',
            states: order.retailer?.retailerInformation?.chainInformation?.purchaseStates ?? order.retailer?.retailerInformation?.individualInformation?.state ?? '',
            brandNames: _.map(_.uniqBy(_.flatMap(_.map(order.lines, line => line.brand)
            ), 'name'), x => {return x.name;}),

            orderDate: moment(order.placedAt).format("MM-DD-YYYY"),
            minPtr: sumBy(_.map(order.lines, line => line.minPtr)),
            status: getOrderStatus(_.map(order.lines, line => line.status.name))
          };
        })
      }
    }
  ] as const;
}




export interface IReportOrder {
  orderId: string;
  retailerName: string;
  brandNames: string[];
  orderDate: string;
  status: string;
  minPtr: string;
}



export interface IReportFilterType {
  retailerName?: string;
  breweries?: string[];
  brands?: string[];
  users?: string[];
  states?: string[];
  fromDate?: Date;
  toDate?: Date;
}



export const reportFilterSchema = yup.object<IReportFilterType>({
  retailerName: yup.string().optional(),
  breweries: yup.array(yup.string().required()),
  brands: yup.array(yup.string().required()),
  users: yup.array(yup.string().required()),
  fromDate: yup.date().default(new Date()),
  toDate: yup.date().default(new Date()).min(yup.ref('fromDate'))
}).defined();




export const exportColumnDefs: IExportColumnDef<IReportOrder>[] = [
  {
    label: 'Order ID',
    value: d => d.orderId
  },
  {
    label: 'Retailer Name',
    value: d => d.retailerName
  },
  {
    label: 'Brand Names',
    value: d => _.join(d.brandNames, ' | ')
  },
  {
    label: 'Order Date',
    value: d => d.orderDate
  },
  {
    label: 'Status',
    value: d => d.status
  },
  {
    label: 'Order Amount',
    value: d => d.minPtr
  }
];
