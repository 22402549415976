import _ from "lodash";



export function mergeByExistingKeys<T extends _.Dictionary<any>>(object: T, mergeWith: _.Dictionary<any>) {
  return _.chain(object)
    .entries()
    .map(([key, value]) => [key, _.get(mergeWith, key, value)])
    .fromPairs()
    .value() as T;
}
