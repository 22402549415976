import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';

import { Select } from 'components/shared/form/Select/Select';
import { ContactDetails } from 'components/shared/pages/ContactDetails/ContactDetails';
import { StatusTag } from 'components/shared/StatusTag/StatusTag';
import * as dataService from 'components/pages/superadmin/OrdersPage/dataService';
import * as optionsUtil from 'util/options';

import styles from './OrderDetailModal.module.css';
import { IOrder } from 'components/pages/HomePage/dataService';
import { OrderStatusKey } from 'components/shared/Types/Order';



interface IOrderDetailModalPropsSuperAdmin extends IModalTemplateProps {
  isSuperAdmin: true;
  order?: dataService.IOrderSuperAdmin;
}
interface IOrderDetailModalPropsGeneral extends IModalTemplateProps {
  isSuperAdmin: false;
  order?: IOrder;
}

type IOrderDetailModalProps =  IOrderDetailModalPropsSuperAdmin | IOrderDetailModalPropsGeneral;

export const OrderDetailModal: React.FC<IOrderDetailModalProps> = props => {
  const [fnUpdateStatus, updateStatusResult] = dataService.useUpdateOrderLineStatusMutation({});

  const isLoading = updateStatusResult.loading;

  async function handleStatusChange(orderLineId: string, status: string) {
    await fnUpdateStatus({
      orderLineId,
      notifyRetailer: true,
      status: status as OrderStatusKey
    });
  }

  if (!props.order) return null;

  return (
    <ModalTemplate
      isLoading={isLoading}
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      modalTitle={(
        <div className={styles.modalTitle}>
          ORDER-{props.order.readableId}
        </div>
      )}>
      <div className={styles.container}>
        <div className={styles.orderMetas}>
          <div className={styles.orderMeta}>
            <span>{props.order.orderDate}</span>
            Order Date
          </div>

          <div className={styles.orderMeta}>
            <span>{props.order.orderTotal}</span>
            Order Total
          </div>
        </div>

        <div className={styles.detail}>
          <span>ORDER DETAILS</span>

          <div className={styles.orderItems}>
            {_.map(props.order.lines, line => (
              <div className={styles.orderItem}>
                <span style={{ flex: 3 }}>
                  {line.quantity} x {line.brandName} {line.format}
                </span>

                <span style={{ flex: 2 }}>
                  {line.value}
                </span>

                <span>
                  {props.isSuperAdmin ? (
                    <Select
                      value={optionsUtil.getOptionFromValue(dataService.__statusOptions, line.statusName)}
                      options={dataService.__statusOptions}
                      onChange={option => handleStatusChange(line.orderLineId, optionsUtil.getValueFromOption(option))} />
                  ) :
                    <StatusTag colorStyle={line.statusName}>{line.statusDisplayName}</StatusTag>
                  }
                </span>
              </div>
            ))}
          </div>
        </div>


        <div className={styles.peopleDetails}>
          <div className={styles.detail}>
            <ContactDetails
              noStyle
              retailer={props.order.retailer} />
          </div>

          <div className={styles.detail}>
            <ContactDetails
              noStyle
              distributor={props.order.distributor} />
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};
