import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO string representation of a timestamp. */
  Timestamp: string;
  /** A base-64 encoded file. */
  EncodedFileInput: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AcceptEmployeeInviteInput = {
  onboardingInviteId: Scalars['String'];
  password: Scalars['String'];
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  id: Scalars['ID'];
  name: AccountStatusName;
  displayName: Scalars['String'];
};

export type AccountStatusName =
  | 'unknown'
  | 'pending'
  | 'rejected'
  | 'approved';

export type AccountType = {
  __typename?: 'AccountType';
  id: Scalars['ID'];
  name: AccountTypeName;
  displayName: Scalars['String'];
};

export type AccountTypeName =
  | 'unknown'
  | 'superAdmin'
  | 'superEmployee'
  | 'breweryOwner'
  | 'breweryEmployee'
  | 'retailer';

export type ActAsBreweryOwnerInput = {
  siteUserId: Scalars['ID'];
  breweryOwnerSiteUserId: Scalars['ID'];
};

export type AppData = {
  __typename?: 'AppData';
  allDistributors: Array<Distributor>;
  superEmployees: Array<SiteUser>;
  packingFormats: Array<PackingFormat>;
  volumeAvailabilities: Array<VolumeAvailability>;
};


export type AppDataAllDistributorsArgs = {
  exceptBreweryId?: Maybe<Scalars['String']>;
};

export type AwardInput = {
  awardId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  organization: Scalars['String'];
  year: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  name: Scalars['String'];
  style: Scalars['String'];
  category: Array<BrandCategory>;
  ibu: Scalars['Int'];
  abv: Scalars['Float'];
  uniqueStyle: Scalars['Boolean'];
  volumeAvailability: VolumeAvailability;
  brandImage: Scalars['String'];
  bannerImage: Scalars['String'];
  graphics: Array<BrandGraphic>;
  formats: Array<BrandFormat>;
  ratebeerRating: Scalars['Int'];
  untappdRating: Scalars['Float'];
  awards: Array<BrandAward>;
  brewery: Brewery;
  numberOfFormats: Scalars['Int'];
  isFavorite: Scalars['Boolean'];
  untappdId: Scalars['String'];
  removed: Scalars['Boolean'];
  description: Scalars['String'];
};


export type BrandFormatsArgs = {
  statesFilter?: Maybe<Array<Scalars['String']>>;
};


export type BrandNumberOfFormatsArgs = {
  statesFilter?: Maybe<Array<Scalars['String']>>;
};

export type BrandAward = {
  __typename?: 'BrandAward';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Scalars['String'];
  year: Scalars['String'];
};

export type BrandCategory = {
  __typename?: 'BrandCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  numberOfBrands: Scalars['Int'];
};

export type BrandFormat = {
  __typename?: 'BrandFormat';
  id: Scalars['ID'];
  packingFormat: PackingFormat;
  lowTaxMinPtr: Scalars['Float'];
  lowTaxMaxPtr: Scalars['Float'];
  highTaxMinPtr: Scalars['Float'];
  highTaxMaxPtr: Scalars['Float'];
  listed: Scalars['Boolean'];
  state: Scalars['String'];
  brand: Brand;
};

export type BrandGraphic = {
  __typename?: 'BrandGraphic';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type Brewery = {
  __typename?: 'Brewery';
  id: Scalars['ID'];
  ownedBy: SiteUser;
  name: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  untappd: Scalars['String'];
  description: Scalars['String'];
  logoUrl: Scalars['String'];
  images: Array<BreweryImage>;
  selfDistributionStates: Array<Scalars['String']>;
  distributors: Array<Distributor>;
  employees: Array<SiteUser>;
  brandCategories: Array<BrandCategory>;
  brands: Array<Brand>;
  activeBrands: Array<Brand>;
  subscriptionPlan?: Maybe<SubscriptionPlan>;
  callToActions: Array<Maybe<Scalars['String']>>;
};


export type BreweryDistributorsArgs = {
  filterByUserStates?: Maybe<Scalars['Boolean']>;
  removeSelfDistributor?: Maybe<Scalars['Boolean']>;
};


export type BreweryBrandsArgs = {
  categoryFilter?: Maybe<Array<Scalars['String']>>;
};

export type BreweryImage = {
  __typename?: 'BreweryImage';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type CacheControlScope =
  | 'PUBLIC'
  | 'PRIVATE';

export type ChangeListedStatusInput = {
  brandId: Scalars['ID'];
  listed: Scalars['Boolean'];
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  initiator: SiteUser;
  otherUser?: Maybe<SiteUser>;
  accepted: Scalars['Boolean'];
  external: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cell?: Maybe<Scalars['String']>;
};

export type CreateBrandCategoryInput = {
  breweryId: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteUserInput = {
  siteUserId: Scalars['String'];
  deleteSiteUserId: Scalars['String'];
};

export type Distributor = {
  __typename?: 'Distributor';
  id: Scalars['ID'];
  name: Scalars['String'];
  contactEmails: Array<Scalars['String']>;
  distributionStates: Array<Scalars['String']>;
  isASelfDistributor: Scalars['Boolean'];
};

export type DistributorInput = {
  id: Scalars['ID'];
  contactEmails: Array<Maybe<Scalars['String']>>;
  states: Array<Scalars['String']>;
};

export type EditContactInput = {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  cell: Scalars['String'];
};


export type FavoriteBrandInput = {
  brandId: Scalars['ID'];
  favorite: Scalars['Boolean'];
};

export type InviteBreweryInput = {
  contactName: Scalars['String'];
  breweryName: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type InviteContactInput = {
  siteUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  cell: Scalars['String'];
  sendInvite: Scalars['Boolean'];
};

export type InviteEmployeeInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  accountType: AccountTypeName;
};

export type InviteRetailerInput = {
  breweryId: Scalars['String'];
  retailerEmails: Array<Scalars['String']>;
};


export type LinkDistributorInput = {
  id: Scalars['ID'];
  contactEmails: Array<Maybe<Scalars['String']>>;
  state: Scalars['String'];
};

export type MarketplaceFilterInput = {
  filterType: MarketPlaceFilterType;
  filterStates: Array<Scalars['String']>;
  filterBrewer: Scalars['String'];
  siteUserId: Scalars['ID'];
};

export type MarketPlaceFilterType =
  | 'allAvailableBrands'
  | 'newBrandsAvailable'
  | 'awardWinningBrands'
  | 'brewPipelineBrands'
  | 'highlyRatedBrands'
  | 'yourFavorites'
  | 'states'
  | 'allAvailableBrewers'
  | 'brewer';

export type Mutation = {
  __typename?: 'Mutation';
  exampleMutation: Scalars['Boolean'];
  changeEmailAddress: Scalars['Boolean'];
  registerSuperAdmin: Scalars['Boolean'];
  registerRetailer: Scalars['Boolean'];
  sendMissingDistributor: Scalars['Boolean'];
  registerBrewery: Scalars['Boolean'];
  updateRetailerInformation: Scalars['Boolean'];
  updateUserInformation: Scalars['Boolean'];
  deleteAccount: Scalars['Boolean'];
  inviteEmployee: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  acceptEmployeeInvite: Scalars['Boolean'];
  updateBreweryInformation: Scalars['Boolean'];
  updateMyDistributors: Scalars['Boolean'];
  upsertBrand: Scalars['Boolean'];
  createBrandCategory: Scalars['Boolean'];
  changeListedStatus: Scalars['Boolean'];
  favoriteBrand: Scalars['Boolean'];
  placeOrder: Scalars['Boolean'];
  updateOrderLineStatus: Scalars['Boolean'];
  updateManyOrderLineStatus: Scalars['Boolean'];
  inviteRetailer: Scalars['Boolean'];
  markNotificationAsSeen: Scalars['Boolean'];
  inviteContact: Scalars['Boolean'];
  editContact: Scalars['Boolean'];
  deleteContact: Scalars['Boolean'];
  acceptContact: Scalars['Boolean'];
  recommendBrand: Scalars['Boolean'];
  upsertSubscription: Scalars['Boolean'];
  cancelSubscription: Scalars['Boolean'];
  importFromUntappd: Scalars['Boolean'];
  removeBrand: Scalars['Boolean'];
  rejectApplication: Scalars['Boolean'];
  approveApplication: Scalars['Boolean'];
  inviteBrewery: Scalars['Boolean'];
  toggleLoginPermitted: Scalars['Boolean'];
  actAsBreweryOwner: Scalars['Boolean'];
  stopActingAsBreweryOwner: Scalars['Boolean'];
  upsertDistributor: Scalars['Boolean'];
  removeDistributor: Scalars['Boolean'];
  ping: Scalars['Boolean'];
};


export type MutationExampleMutationArgs = {
  id: Scalars['ID'];
};


export type MutationChangeEmailAddressArgs = {
  email: Scalars['String'];
};


export type MutationRegisterSuperAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cellNumber: Scalars['String'];
  key: Scalars['String'];
};


export type MutationRegisterRetailerArgs = {
  input: RegisterRetailerInput;
};


export type MutationSendMissingDistributorArgs = {
  input: SendMissingDistributorInput;
};


export type MutationRegisterBreweryArgs = {
  input: RegisterBreweryInput;
};


export type MutationUpdateRetailerInformationArgs = {
  input: UpdateRetailerInput;
};


export type MutationUpdateUserInformationArgs = {
  input: UpdateUserInformationInput;
};


export type MutationInviteEmployeeArgs = {
  input: InviteEmployeeInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationAcceptEmployeeInviteArgs = {
  input: AcceptEmployeeInviteInput;
};


export type MutationUpdateBreweryInformationArgs = {
  input: UpdateBreweryInformationInput;
};


export type MutationUpdateMyDistributorsArgs = {
  input: UpdateMyDistributorsInput;
};


export type MutationUpsertBrandArgs = {
  input: UpsertBrandInput;
};


export type MutationCreateBrandCategoryArgs = {
  input: CreateBrandCategoryInput;
};


export type MutationChangeListedStatusArgs = {
  input: ChangeListedStatusInput;
};


export type MutationFavoriteBrandArgs = {
  input: FavoriteBrandInput;
};


export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};


export type MutationUpdateOrderLineStatusArgs = {
  input: UpdateOrderLineStatusInput;
};


export type MutationUpdateManyOrderLineStatusArgs = {
  input: UpdateManyOrderLineStatusInput;
};


export type MutationInviteRetailerArgs = {
  input: InviteRetailerInput;
};


export type MutationMarkNotificationAsSeenArgs = {
  notificationId: Scalars['ID'];
};


export type MutationInviteContactArgs = {
  input: InviteContactInput;
};


export type MutationEditContactArgs = {
  input: EditContactInput;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
  isExternal: Scalars['Boolean'];
};


export type MutationAcceptContactArgs = {
  id: Scalars['ID'];
};


export type MutationRecommendBrandArgs = {
  input: RecommendBrandInput;
};


export type MutationUpsertSubscriptionArgs = {
  input: UpsertSubscriptionInput;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationImportFromUntappdArgs = {
  breweryId: Scalars['ID'];
};


export type MutationRemoveBrandArgs = {
  brandId: Scalars['ID'];
};


export type MutationRejectApplicationArgs = {
  siteUserId: Scalars['ID'];
};


export type MutationApproveApplicationArgs = {
  siteUserId: Scalars['ID'];
};


export type MutationInviteBreweryArgs = {
  input: InviteBreweryInput;
};


export type MutationToggleLoginPermittedArgs = {
  siteUserId: Scalars['ID'];
};


export type MutationActAsBreweryOwnerArgs = {
  input: ActAsBreweryOwnerInput;
};


export type MutationStopActingAsBreweryOwnerArgs = {
  siteUserId: Scalars['ID'];
};


export type MutationUpsertDistributorArgs = {
  input: UpsertDistributorInput;
};


export type MutationRemoveDistributorArgs = {
  distributorId: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['Timestamp'];
  forUser: SiteUser;
  seen: Scalars['Boolean'];
  type: NotificationType;
  placedOrder?: Maybe<PlacedOrder>;
  placedOrderLine?: Maybe<PlacedOrderLine>;
  brand?: Maybe<Brand>;
  contact?: Maybe<SiteUser>;
  orderStatus?: Maybe<OrderStatusName>;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['ID'];
  name: NotificationTypeName;
  displayName: Scalars['String'];
};

export type NotificationTypeName =
  | 'unknown'
  | 'newOrder'
  | 'orderLineStatusChange'
  | 'newBrandRecommendation'
  | 'newContactRequest'
  | 'staleOrderPrompt';

export type OnboardingInvite = {
  __typename?: 'OnboardingInvite';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  invitedBy: Brewery;
  usedBy?: Maybe<SiteUser>;
  accountType: AccountType;
};

export type OrderFilterInput = {
  keyword?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['Timestamp']>;
  toDate?: Maybe<Scalars['Timestamp']>;
};

export type OrderItemBrand = {
  brandId: Scalars['ID'];
  distributorId: Scalars['ID'];
  formats: Array<OrderItemInput>;
};

export type OrderItemInput = {
  formatName: Scalars['String'];
  quantity: Scalars['Int'];
};

export type OrdersPlacedByMeFilterInput = {
  brandName?: Maybe<Scalars['String']>;
  breweryId?: Maybe<Scalars['String']>;
  placedAt?: Maybe<Scalars['Timestamp']>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['ID'];
  name: OrderStatusName;
  displayName: Scalars['String'];
};

export type OrderStatusName =
  | 'unknown'
  | 'newOrder'
  | 'shipped'
  | 'partlyReceived'
  | 'received';

export type PackingFormat = {
  __typename?: 'PackingFormat';
  id: Scalars['ID'];
  name: PackingFormatName;
  displayName: Scalars['String'];
  formatType: Scalars['String'];
  unit: Scalars['String'];
};

export type PackingFormatInput = {
  name: PackingFormatName;
  lowTaxMinPtr: Scalars['Float'];
  state: Scalars['String'];
  listed: Scalars['Boolean'];
};

export type PackingFormatName =
  | 'unknown'
  | 'packing_1'
  | 'packing_2'
  | 'packing_3'
  | 'packing_4'
  | 'packing_5'
  | 'packing_6'
  | 'packing_7'
  | 'packing_8'
  | 'packing_9'
  | 'packing_10'
  | 'packing_11'
  | 'packing_12'
  | 'packing_13'
  | 'packing_14'
  | 'packing_15'
  | 'packing_16'
  | 'packing_17'
  | 'packing_18';

export type PageInfo = {
  __typename?: 'PageInfo';
  offset: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationInput = {
  pageSize: Scalars['Int'];
  currentPage: Scalars['Int'];
};

export type PaginationResult = {
  pageInfo: PageInfo;
};

export type PlacedOrder = {
  __typename?: 'PlacedOrder';
  id: Scalars['ID'];
  placedAt: Scalars['Timestamp'];
  distributor: Distributor;
  retailer?: Maybe<SiteUser>;
  brewery: Brewery;
  readableId: Scalars['String'];
  lines?: Maybe<Array<PlacedOrderLine>>;
};

export type PlacedOrderLine = {
  __typename?: 'PlacedOrderLine';
  id: Scalars['ID'];
  brand: Brand;
  packingFormat: PackingFormat;
  quantity: Scalars['Int'];
  minPtr: Scalars['Int'];
  maxPtr: Scalars['Int'];
  readableId: Scalars['String'];
  status: OrderStatus;
  canReorder: Scalars['Boolean'];
  order: PlacedOrder;
};

export type PlacedOrderPaginationResult = PaginationResult & {
  __typename?: 'PlacedOrderPaginationResult';
  pageInfo: PageInfo;
  page: Array<PlacedOrder>;
};

export type PlaceOrderInput = {
  brands: Array<OrderItemBrand>;
};

export type Query = {
  __typename?: 'Query';
  version: Scalars['String'];
  currentUser?: Maybe<SiteUser>;
  appData: AppData;
  invite?: Maybe<OnboardingInvite>;
  brewery?: Maybe<Brewery>;
  brand?: Maybe<Brand>;
  user?: Maybe<SiteUser>;
  marketplaceBrands: Array<Brand>;
  ordersPlacedByMe: Array<PlacedOrder>;
  retailersForBrewery: Array<SiteUser>;
  order?: Maybe<PlacedOrder>;
  ordersForBrewery: PlacedOrderPaginationResult;
  notifications: Array<Notification>;
  subscriptionByBrewery?: Maybe<SubscriptionPlan>;
  pendingApplications: Array<SiteUser>;
  approvedBreweries: Array<Brewery>;
  allOrders: Array<PlacedOrder>;
  platformRetailers: Array<SiteUser>;
  allBreweries: Array<Brewery>;
  allBrands: Array<Brand>;
  allUsers: Array<SiteUser>;
  report: PlacedOrderPaginationResult;
};


export type QueryInviteArgs = {
  id: Scalars['ID'];
};


export type QueryBreweryArgs = {
  id: Scalars['ID'];
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryMarketplaceBrandsArgs = {
  filter: MarketplaceFilterInput;
};


export type QueryOrdersPlacedByMeArgs = {
  filter: OrdersPlacedByMeFilterInput;
};


export type QueryRetailersForBreweryArgs = {
  breweryId: Scalars['ID'];
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrdersForBreweryArgs = {
  breweryId: Scalars['ID'];
  filter: OrderFilterInput;
  pagination: PaginationInput;
};


export type QueryNotificationsArgs = {
  site_user_id: Scalars['ID'];
};


export type QuerySubscriptionByBreweryArgs = {
  breweryId: Scalars['ID'];
};


export type QueryAllOrdersArgs = {
  filter: OrderFilterInput;
};


export type QueryPlatformRetailersArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryAllBrandsArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryReportArgs = {
  pagination: PaginationInput;
  filter: ReportFilterInput;
};

export type RecommendBrandInput = {
  siteUserId: Scalars['ID'];
  brandId: Scalars['ID'];
  contactId: Scalars['ID'];
};

export type RegisterBreweryInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  cellNumber: Scalars['String'];
  breweryName: Scalars['String'];
  breweryState: Scalars['String'];
  untappd: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterRetailerInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  password: Scalars['String'];
  cellNumber: Scalars['String'];
  profilePicture?: Maybe<Scalars['EncodedFileInput']>;
  retailerType: RetailerTypeName;
  retailerChainStatus: RetailerChainStatusName;
  chainInformation?: Maybe<RetailerChainInput>;
  individualInformation?: Maybe<RetailerIndividualInput>;
};

export type ReportFilterInput = {
  retailerName?: Maybe<Scalars['String']>;
  breweries?: Maybe<Array<Scalars['ID']>>;
  brands?: Maybe<Array<Scalars['ID']>>;
  users?: Maybe<Array<Scalars['ID']>>;
  states?: Maybe<Array<Scalars['String']>>;
  fromDate?: Maybe<Scalars['Timestamp']>;
  toDate?: Maybe<Scalars['Timestamp']>;
};

export type RetailerChainInformation = {
  __typename?: 'RetailerChainInformation';
  name: Scalars['String'];
  purchaseStates: Array<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type RetailerChainInput = {
  name: Scalars['String'];
  purchaseStates: Array<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type RetailerChainStatus = {
  __typename?: 'RetailerChainStatus';
  id: Scalars['ID'];
  name: RetailerChainStatusName;
  displayName: Scalars['String'];
};

export type RetailerChainStatusName =
  | 'unknown'
  | 'chain'
  | 'individual';

export type RetailerIndividualInformation = {
  __typename?: 'RetailerIndividualInformation';
  locationName: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type RetailerIndividualInput = {
  locationName: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type RetailerInformation = {
  __typename?: 'RetailerInformation';
  id: Scalars['ID'];
  retailerType: RetailerType;
  retailerChainStatus: RetailerChainStatus;
  chainInformation?: Maybe<RetailerChainInformation>;
  individualInformation?: Maybe<RetailerIndividualInformation>;
  placedOrders: Array<PlacedOrder>;
};

export type RetailerType = {
  __typename?: 'RetailerType';
  id: Scalars['ID'];
  name: RetailerTypeName;
  displayName: Scalars['String'];
};

export type RetailerTypeName =
  | 'unknown'
  | 'onPremise'
  | 'offPremise';

export type SendMissingDistributorInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  cellNumber: Scalars['String'];
  breweryName: Scalars['String'];
  breweryState: Scalars['String'];
  distributorName: Scalars['String'];
  distributorEmail: Scalars['String'];
};

export type SiteUser = {
  __typename?: 'SiteUser';
  id: Scalars['ID'];
  firebaseUid: Scalars['ID'];
  email: Scalars['String'];
  cell: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profilePicture: Scalars['String'];
  accountType: AccountType;
  status: AccountStatus;
  linkedBrewery?: Maybe<Brewery>;
  retailerInformation?: Maybe<RetailerInformation>;
  contacts: Array<Contact>;
  loginPermitted: Scalars['Boolean'];
  actingAs?: Maybe<SiteUser>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  id: Scalars['ID'];
  createdOn: Scalars['Timestamp'];
  states: Array<Scalars['String']>;
  cancelAt?: Maybe<Scalars['Timestamp']>;
  billingPeriodEnd: Scalars['Timestamp'];
  hasExpired: Scalars['Boolean'];
  customerId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  brewery: Brewery;
};


export type UpdateBreweryInformationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  untappd: Scalars['String'];
  description: Scalars['String'];
  logo?: Maybe<Scalars['EncodedFileInput']>;
  images: Array<Scalars['EncodedFileInput']>;
};

export type UpdateManyOrderLineStatusInput = {
  lines: Array<UpdateOrderLineStatusInput>;
};

export type UpdateMyDistributorsInput = {
  breweryId: Scalars['String'];
  distributors: Array<DistributorInput>;
  selfDistributionStates: Array<Scalars['String']>;
};

export type UpdateOrderLineStatusInput = {
  orderLineId: Scalars['ID'];
  notifyRetailer: Scalars['Boolean'];
  status: OrderStatusName;
};

export type UpdateRetailerInput = {
  siteUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cellNumber: Scalars['String'];
  profilePicture?: Maybe<Scalars['EncodedFileInput']>;
  retailerType: RetailerTypeName;
  retailerChainStatus: RetailerChainStatusName;
  chainInformation?: Maybe<RetailerChainInput>;
  individualInformation?: Maybe<RetailerIndividualInput>;
};

export type UpdateUserInformationInput = {
  siteUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  cellNumber: Scalars['String'];
  emailAddress: Scalars['String'];
  profilePicture?: Maybe<Scalars['EncodedFileInput']>;
};


export type UpsertBrandInput = {
  breweryId: Scalars['ID'];
  brandId?: Maybe<Scalars['ID']>;
  listed: Scalars['Boolean'];
  name: Scalars['String'];
  style: Scalars['String'];
  category: Array<Scalars['String']>;
  ibu: Scalars['Int'];
  abv: Scalars['Float'];
  uniqueStyle: Scalars['Boolean'];
  volumeAvailability: VolumeAvailabilityName;
  brandImage?: Maybe<Scalars['EncodedFileInput']>;
  bannerImage?: Maybe<Scalars['EncodedFileInput']>;
  graphics: Array<Scalars['EncodedFileInput']>;
  formats: Array<PackingFormatInput>;
  ratebeerRating: Scalars['Int'];
  untappdRating: Scalars['Float'];
  awards: Array<AwardInput>;
  untappdId: Scalars['String'];
  description: Scalars['String'];
};

export type UpsertDistributorInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  contactEmails: Array<Scalars['String']>;
  distributionStates: Array<Scalars['String']>;
};

export type UpsertSubscriptionInput = {
  states: Array<Scalars['String']>;
  stripePayload: Scalars['String'];
};

export type VolumeAvailability = {
  __typename?: 'VolumeAvailability';
  id: Scalars['ID'];
  name: VolumeAvailabilityName;
  displayName: Scalars['String'];
};

export type VolumeAvailabilityName =
  | 'unknown'
  | 'limitedSupply'
  | 'brewToOrder'
  | 'noLimit';

export type UpdateMyDistributorsMutationVariables = Exact<{
  input: UpdateMyDistributorsInput;
}>;


export type UpdateMyDistributorsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyDistributors'>
);

export type SendMissingDistributorMutationVariables = Exact<{
  input: SendMissingDistributorInput;
}>;


export type SendMissingDistributorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendMissingDistributor'>
);

export type MyDistributorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyDistributorsQuery = (
  { __typename?: 'Query' }
  & { brewery?: Maybe<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id' | 'selfDistributionStates'>
    & { distributors: Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name' | 'contactEmails' | 'distributionStates'>
    )> }
  )> }
);

export type MyBrewPipelinePageQueryVariables = Exact<{
  breweryId: Scalars['ID'];
  pagination: PaginationInput;
  filter: OrderFilterInput;
}>;


export type MyBrewPipelinePageQuery = (
  { __typename?: 'Query' }
  & { brewery?: Maybe<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id' | 'callToActions'>
  )>, ordersForBrewery: (
    { __typename?: 'PlacedOrderPaginationResult' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total' | 'offset' | 'pageSize'>
    ), page: Array<(
      { __typename?: 'PlacedOrder' }
      & Pick<PlacedOrder, 'id' | 'readableId' | 'placedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'SiteUser' }
        & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'cell' | 'email'>
        & { retailerInformation?: Maybe<(
          { __typename?: 'RetailerInformation' }
          & { individualInformation?: Maybe<(
            { __typename?: 'RetailerIndividualInformation' }
            & Pick<RetailerIndividualInformation, 'locationName' | 'street' | 'city' | 'state' | 'zip'>
          )>, chainInformation?: Maybe<(
            { __typename?: 'RetailerChainInformation' }
            & Pick<RetailerChainInformation, 'name' | 'purchaseStates'>
          )> }
        )> }
      )>, distributor: (
        { __typename?: 'Distributor' }
        & Pick<Distributor, 'id' | 'name' | 'contactEmails'>
      ), lines?: Maybe<Array<(
        { __typename?: 'PlacedOrderLine' }
        & Pick<PlacedOrderLine, 'id' | 'readableId' | 'quantity' | 'minPtr'>
        & { brand: (
          { __typename?: 'Brand' }
          & Pick<Brand, 'id' | 'name'>
        ), packingFormat: (
          { __typename?: 'PackingFormat' }
          & Pick<PackingFormat, 'id' | 'name' | 'displayName'>
        ), order: (
          { __typename?: 'PlacedOrder' }
          & Pick<PlacedOrder, 'id' | 'readableId'>
        ), status: (
          { __typename?: 'OrderStatus' }
          & Pick<OrderStatus, 'id' | 'name' | 'displayName'>
        ) }
      )>> }
    )> }
  ) }
);

export type MarkAsShippedMutationVariables = Exact<{
  input: UpdateManyOrderLineStatusInput;
}>;


export type MarkAsShippedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateManyOrderLineStatus'>
);

export type UpdateBreweryInformationMutationVariables = Exact<{
  input: UpdateBreweryInformationInput;
}>;


export type UpdateBreweryInformationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBreweryInformation'>
);

export type MyBrewerProfileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MyBrewerProfileQuery = (
  { __typename?: 'Query' }
  & { brewery?: Maybe<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id' | 'name' | 'city' | 'state' | 'website' | 'untappd' | 'description' | 'logoUrl'>
    & { images: Array<(
      { __typename?: 'BreweryImage' }
      & Pick<BreweryImage, 'id' | 'url'>
    )> }
  )> }
);

export type MyRetailersPageQueryVariables = Exact<{
  breweryId: Scalars['ID'];
}>;


export type MyRetailersPageQuery = (
  { __typename?: 'Query' }
  & { retailersForBrewery: Array<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'cell' | 'email'>
    & { retailerInformation?: Maybe<(
      { __typename?: 'RetailerInformation' }
      & Pick<RetailerInformation, 'id'>
      & { chainInformation?: Maybe<(
        { __typename?: 'RetailerChainInformation' }
        & Pick<RetailerChainInformation, 'name' | 'purchaseStates' | 'website'>
      )>, individualInformation?: Maybe<(
        { __typename?: 'RetailerIndividualInformation' }
        & Pick<RetailerIndividualInformation, 'locationName' | 'street' | 'city' | 'state' | 'zip' | 'website'>
      )>, placedOrders: Array<(
        { __typename?: 'PlacedOrder' }
        & Pick<PlacedOrder, 'id' | 'placedAt' | 'readableId'>
        & { lines?: Maybe<Array<(
          { __typename?: 'PlacedOrderLine' }
          & Pick<PlacedOrderLine, 'id' | 'readableId' | 'quantity' | 'minPtr' | 'canReorder'>
          & { status: (
            { __typename?: 'OrderStatus' }
            & Pick<OrderStatus, 'id' | 'name' | 'displayName'>
          ), brand: (
            { __typename?: 'Brand' }
            & Pick<Brand, 'id' | 'name'>
          ), packingFormat: (
            { __typename?: 'PackingFormat' }
            & Pick<PackingFormat, 'id' | 'displayName'>
          ) }
        )>> }
      )> }
    )> }
  )> }
);

export type InviteRetailerMutationVariables = Exact<{
  input: InviteRetailerInput;
}>;


export type InviteRetailerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteRetailer'>
);

export type UpdateUserInformationMutationVariables = Exact<{
  input: UpdateUserInformationInput;
}>;


export type UpdateUserInformationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserInformation'>
);

export type UpsertSubscriptionMutationVariables = Exact<{
  input: UpsertSubscriptionInput;
}>;


export type UpsertSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertSubscription'>
);

export type CancelSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubscription'>
);

export type GetSubscriptionQueryVariables = Exact<{
  breweryId: Scalars['ID'];
}>;


export type GetSubscriptionQuery = (
  { __typename?: 'Query' }
  & { subscriptionByBrewery?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, 'id' | 'createdOn' | 'cancelAt' | 'billingPeriodEnd' | 'states' | 'hasExpired'>
  )> }
);

export type UpsertBrandMutationVariables = Exact<{
  input: UpsertBrandInput;
}>;


export type UpsertBrandMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertBrand'>
);

export type CreateBrandCategoryMutationVariables = Exact<{
  input: CreateBrandCategoryInput;
}>;


export type CreateBrandCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBrandCategory'>
);

export type BrandFormPageOptionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BrandFormPageOptionsQuery = (
  { __typename?: 'Query' }
  & { brewery?: Maybe<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id'>
    & { ownedBy: (
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id'>
      & { status: (
        { __typename?: 'AccountStatus' }
        & Pick<AccountStatus, 'id' | 'name' | 'displayName'>
      ) }
    ), brandCategories: Array<(
      { __typename?: 'BrandCategory' }
      & Pick<BrandCategory, 'id' | 'name'>
    )> }
  )>, appData: (
    { __typename?: 'AppData' }
    & { volumeAvailabilities: Array<(
      { __typename?: 'VolumeAvailability' }
      & Pick<VolumeAvailability, 'id' | 'name' | 'displayName'>
    )>, packingFormats: Array<(
      { __typename?: 'PackingFormat' }
      & Pick<PackingFormat, 'id' | 'name' | 'displayName'>
    )> }
  ) }
);

export type LoadBrandQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LoadBrandQuery = (
  { __typename?: 'Query' }
  & { brand?: Maybe<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'listed' | 'name' | 'style' | 'description' | 'ibu' | 'abv' | 'uniqueStyle' | 'untappdId' | 'brandImage' | 'bannerImage' | 'ratebeerRating' | 'untappdRating'>
    & { category: Array<(
      { __typename?: 'BrandCategory' }
      & Pick<BrandCategory, 'id' | 'name'>
    )>, volumeAvailability: (
      { __typename?: 'VolumeAvailability' }
      & Pick<VolumeAvailability, 'id' | 'name' | 'displayName'>
    ), graphics: Array<(
      { __typename?: 'BrandGraphic' }
      & Pick<BrandGraphic, 'id' | 'url'>
    )>, formats: Array<(
      { __typename?: 'BrandFormat' }
      & Pick<BrandFormat, 'id' | 'lowTaxMinPtr' | 'listed' | 'state'>
      & { packingFormat: (
        { __typename?: 'PackingFormat' }
        & Pick<PackingFormat, 'id' | 'name' | 'displayName'>
      ) }
    )>, awards: Array<(
      { __typename?: 'BrandAward' }
      & Pick<BrandAward, 'id' | 'name' | 'organization' | 'year'>
    )> }
  )> }
);

export type ChangeListedStatusMutationVariables = Exact<{
  input: ChangeListedStatusInput;
}>;


export type ChangeListedStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeListedStatus'>
);

export type ImportFromUntappdMutationVariables = Exact<{
  breweryId: Scalars['ID'];
}>;


export type ImportFromUntappdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'importFromUntappd'>
);

export type RemoveBrandMutationVariables = Exact<{
  brandId: Scalars['ID'];
}>;


export type RemoveBrandMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeBrand'>
);

export type BrandsPageQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BrandsPageQuery = (
  { __typename?: 'Query' }
  & { brewery?: Maybe<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id'>
    & { brandCategories: Array<(
      { __typename?: 'BrandCategory' }
      & Pick<BrandCategory, 'id' | 'name' | 'numberOfBrands'>
    )>, activeBrands: Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'listed' | 'name' | 'style' | 'untappdId' | 'ibu' | 'abv' | 'uniqueStyle' | 'brandImage' | 'ratebeerRating' | 'untappdRating'>
      & { category: Array<(
        { __typename?: 'BrandCategory' }
        & Pick<BrandCategory, 'id' | 'name'>
      )>, volumeAvailability: (
        { __typename?: 'VolumeAvailability' }
        & Pick<VolumeAvailability, 'id' | 'name' | 'displayName'>
      ), graphics: Array<(
        { __typename?: 'BrandGraphic' }
        & Pick<BrandGraphic, 'id' | 'url'>
      )>, formats: Array<(
        { __typename?: 'BrandFormat' }
        & Pick<BrandFormat, 'id' | 'listed' | 'lowTaxMinPtr'>
      )>, awards: Array<(
        { __typename?: 'BrandAward' }
        & Pick<BrandAward, 'id' | 'name' | 'organization' | 'year'>
      )> }
    )>, brands: Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'listed' | 'name' | 'style' | 'untappdId' | 'ibu' | 'abv' | 'uniqueStyle' | 'brandImage' | 'ratebeerRating' | 'untappdRating'>
      & { category: Array<(
        { __typename?: 'BrandCategory' }
        & Pick<BrandCategory, 'id' | 'name'>
      )>, volumeAvailability: (
        { __typename?: 'VolumeAvailability' }
        & Pick<VolumeAvailability, 'id' | 'name' | 'displayName'>
      ), graphics: Array<(
        { __typename?: 'BrandGraphic' }
        & Pick<BrandGraphic, 'id' | 'url'>
      )>, formats: Array<(
        { __typename?: 'BrandFormat' }
        & Pick<BrandFormat, 'id' | 'listed' | 'lowTaxMinPtr'>
      )>, awards: Array<(
        { __typename?: 'BrandAward' }
        & Pick<BrandAward, 'id' | 'name' | 'organization' | 'year'>
      )> }
    )> }
  )> }
);

export type AcceptEmployeeInviteMutationVariables = Exact<{
  input: AcceptEmployeeInviteInput;
}>;


export type AcceptEmployeeInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'acceptEmployeeInvite'>
);

export type GetInviteQueryVariables = Exact<{
  inviteId: Scalars['ID'];
}>;


export type GetInviteQuery = (
  { __typename?: 'Query' }
  & { invite?: Maybe<(
    { __typename?: 'OnboardingInvite' }
    & Pick<OnboardingInvite, 'id' | 'email' | 'firstName' | 'lastName'>
  )> }
);

export type RegisterBreweryMutationVariables = Exact<{
  input: RegisterBreweryInput;
}>;


export type RegisterBreweryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registerBrewery'>
);

export type InviteEmployeeMutationVariables = Exact<{
  input: InviteEmployeeInput;
}>;


export type InviteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteEmployee'>
);

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type GetSuperEmployeesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSuperEmployeesQuery = (
  { __typename?: 'Query' }
  & { appData: (
    { __typename?: 'AppData' }
    & { superEmployees: Array<(
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'email'>
    )> }
  ) }
);

export type RejectApplicationMutationVariables = Exact<{
  siteUserId: Scalars['ID'];
}>;


export type RejectApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectApplication'>
);

export type ApproveApplicationMutationVariables = Exact<{
  siteUserId: Scalars['ID'];
}>;


export type ApproveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveApplication'>
);

export type InviteBreweryMutationVariables = Exact<{
  input: InviteBreweryInput;
}>;


export type InviteBreweryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteBrewery'>
);

export type PendingApplicationsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type PendingApplicationsPageQuery = (
  { __typename?: 'Query' }
  & { pendingApplications: Array<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'cell'>
    & { linkedBrewery?: Maybe<(
      { __typename?: 'Brewery' }
      & Pick<Brewery, 'id' | 'name' | 'state' | 'untappd'>
    )>, status: (
      { __typename?: 'AccountStatus' }
      & Pick<AccountStatus, 'id' | 'name' | 'displayName'>
    ), accountType: (
      { __typename?: 'AccountType' }
      & Pick<AccountType, 'id' | 'name' | 'displayName'>
    ) }
  )> }
);

export type ApprovedBreweriesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ApprovedBreweriesPageQuery = (
  { __typename?: 'Query' }
  & { approvedBreweries: Array<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id' | 'name' | 'state'>
    & { ownedBy: (
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'email' | 'cell' | 'loginPermitted'>
    ) }
  )> }
);

export type ActAsBreweryOwnerMutationVariables = Exact<{
  input: ActAsBreweryOwnerInput;
}>;


export type ActAsBreweryOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actAsBreweryOwner'>
);

export type ToggleLoginPermittedMutationVariables = Exact<{
  siteUserId: Scalars['ID'];
}>;


export type ToggleLoginPermittedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleLoginPermitted'>
);

export type UpsertDistributorMutationVariables = Exact<{
  input: UpsertDistributorInput;
}>;


export type UpsertDistributorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertDistributor'>
);

export type RemoveDistrubtorMutationVariables = Exact<{
  distributorId: Scalars['ID'];
}>;


export type RemoveDistrubtorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeDistributor'>
);

export type GetAllDistributorsQueryVariables = Exact<{
  exceptBreweryId?: Maybe<Scalars['String']>;
}>;


export type GetAllDistributorsQuery = (
  { __typename?: 'Query' }
  & { appData: (
    { __typename?: 'AppData' }
    & { allDistributors: Array<(
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name' | 'contactEmails' | 'distributionStates' | 'isASelfDistributor'>
    )> }
  ) }
);

export type UpdateOrderLineStatusMutationVariables = Exact<{
  input: UpdateOrderLineStatusInput;
}>;


export type UpdateOrderLineStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrderLineStatus'>
);

export type OrdersSuperAdminPageQueryVariables = Exact<{
  filter: OrderFilterInput;
}>;


export type OrdersSuperAdminPageQuery = (
  { __typename?: 'Query' }
  & { allOrders: Array<(
    { __typename?: 'PlacedOrder' }
    & Pick<PlacedOrder, 'id' | 'readableId' | 'placedAt'>
    & { retailer?: Maybe<(
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'cell' | 'email'>
      & { retailerInformation?: Maybe<(
        { __typename?: 'RetailerInformation' }
        & { individualInformation?: Maybe<(
          { __typename?: 'RetailerIndividualInformation' }
          & Pick<RetailerIndividualInformation, 'locationName' | 'street' | 'city' | 'state' | 'zip'>
        )>, chainInformation?: Maybe<(
          { __typename?: 'RetailerChainInformation' }
          & Pick<RetailerChainInformation, 'name' | 'purchaseStates'>
        )> }
      )> }
    )>, distributor: (
      { __typename?: 'Distributor' }
      & Pick<Distributor, 'id' | 'name' | 'contactEmails'>
    ), brewery: (
      { __typename?: 'Brewery' }
      & Pick<Brewery, 'id' | 'name' | 'state'>
    ), lines?: Maybe<Array<(
      { __typename?: 'PlacedOrderLine' }
      & Pick<PlacedOrderLine, 'id' | 'readableId' | 'quantity' | 'minPtr'>
      & { brand: (
        { __typename?: 'Brand' }
        & Pick<Brand, 'id' | 'name'>
      ), packingFormat: (
        { __typename?: 'PackingFormat' }
        & Pick<PackingFormat, 'id' | 'name' | 'displayName'>
      ), order: (
        { __typename?: 'PlacedOrder' }
        & Pick<PlacedOrder, 'id' | 'readableId'>
      ), status: (
        { __typename?: 'OrderStatus' }
        & Pick<OrderStatus, 'id' | 'name' | 'displayName'>
      ) }
    )>> }
  )> }
);

export type SuperAdminReportsOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SuperAdminReportsOptionsQuery = (
  { __typename?: 'Query' }
  & { allBreweries: Array<(
    { __typename?: 'Brewery' }
    & Pick<Brewery, 'id' | 'name'>
  )>, allBrands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'name'>
  )>, platformRetailers: Array<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'firstName' | 'lastName'>
    & { retailerInformation?: Maybe<(
      { __typename?: 'RetailerInformation' }
      & Pick<RetailerInformation, 'id'>
      & { retailerChainStatus: (
        { __typename?: 'RetailerChainStatus' }
        & Pick<RetailerChainStatus, 'id' | 'name'>
      ), individualInformation?: Maybe<(
        { __typename?: 'RetailerIndividualInformation' }
        & Pick<RetailerIndividualInformation, 'state'>
      )>, chainInformation?: Maybe<(
        { __typename?: 'RetailerChainInformation' }
        & Pick<RetailerChainInformation, 'purchaseStates'>
      )> }
    )> }
  )> }
);

export type SuperAdminReportsPageQueryVariables = Exact<{
  pagination: PaginationInput;
  filter: ReportFilterInput;
}>;


export type SuperAdminReportsPageQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'PlacedOrderPaginationResult' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total' | 'offset' | 'pageSize'>
    ), page: Array<(
      { __typename?: 'PlacedOrder' }
      & Pick<PlacedOrder, 'id' | 'readableId' | 'placedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'SiteUser' }
        & Pick<SiteUser, 'id'>
        & { retailerInformation?: Maybe<(
          { __typename?: 'RetailerInformation' }
          & { individualInformation?: Maybe<(
            { __typename?: 'RetailerIndividualInformation' }
            & Pick<RetailerIndividualInformation, 'locationName' | 'state'>
          )>, chainInformation?: Maybe<(
            { __typename?: 'RetailerChainInformation' }
            & Pick<RetailerChainInformation, 'name' | 'purchaseStates'>
          )> }
        )> }
      )>, lines?: Maybe<Array<(
        { __typename?: 'PlacedOrderLine' }
        & Pick<PlacedOrderLine, 'id' | 'readableId' | 'minPtr'>
        & { brand: (
          { __typename?: 'Brand' }
          & Pick<Brand, 'id' | 'name'>
        ), status: (
          { __typename?: 'OrderStatus' }
          & Pick<OrderStatus, 'id' | 'name' | 'displayName'>
        ) }
      )>> }
    )> }
  ) }
);

export type UpdateRetailerInformationMutationVariables = Exact<{
  input: UpdateRetailerInput;
}>;


export type UpdateRetailerInformationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRetailerInformation'>
);

export type SuperAdminRetailersPageQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type SuperAdminRetailersPageQuery = (
  { __typename?: 'Query' }
  & { platformRetailers: Array<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'loginPermitted' | 'firstName' | 'lastName' | 'cell' | 'email'>
    & { retailerInformation?: Maybe<(
      { __typename?: 'RetailerInformation' }
      & { retailerType: (
        { __typename?: 'RetailerType' }
        & Pick<RetailerType, 'id' | 'name' | 'displayName'>
      ), retailerChainStatus: (
        { __typename?: 'RetailerChainStatus' }
        & Pick<RetailerChainStatus, 'id' | 'name' | 'displayName'>
      ), chainInformation?: Maybe<(
        { __typename?: 'RetailerChainInformation' }
        & Pick<RetailerChainInformation, 'name' | 'purchaseStates' | 'website'>
      )>, individualInformation?: Maybe<(
        { __typename?: 'RetailerIndividualInformation' }
        & Pick<RetailerIndividualInformation, 'locationName' | 'street' | 'city' | 'state' | 'zip' | 'website'>
      )> }
    )> }
  )> }
);

export type VersionIndicatorQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionIndicatorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'version'>
);

export type StopActingAsBreweryOwnerMutationVariables = Exact<{
  siteUserId: Scalars['ID'];
}>;


export type StopActingAsBreweryOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stopActingAsBreweryOwner'>
);

export type MarkNotificationsMutationVariables = Exact<{
  notificationId: Scalars['ID'];
}>;


export type MarkNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markNotificationAsSeen'>
);

export type MyNotificationsQueryVariables = Exact<{
  siteUserId: Scalars['ID'];
}>;


export type MyNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: Array<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'createdAt' | 'seen' | 'orderStatus'>
    & { forUser: (
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id' | 'firstName' | 'lastName'>
    ), type: (
      { __typename?: 'NotificationType' }
      & Pick<NotificationType, 'id' | 'name' | 'displayName'>
    ), placedOrderLine?: Maybe<(
      { __typename?: 'PlacedOrderLine' }
      & Pick<PlacedOrderLine, 'id' | 'readableId'>
      & { order: (
        { __typename?: 'PlacedOrder' }
        & Pick<PlacedOrder, 'id' | 'readableId'>
      ) }
    )> }
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'SiteUser' }
    & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'email' | 'cell' | 'loginPermitted'>
    & { accountType: (
      { __typename?: 'AccountType' }
      & Pick<AccountType, 'id' | 'name' | 'displayName'>
    ), status: (
      { __typename?: 'AccountStatus' }
      & Pick<AccountStatus, 'id' | 'name' | 'displayName'>
    ), linkedBrewery?: Maybe<(
      { __typename?: 'Brewery' }
      & Pick<Brewery, 'id' | 'name' | 'logoUrl' | 'state' | 'untappd'>
    )>, actingAs?: Maybe<(
      { __typename?: 'SiteUser' }
      & Pick<SiteUser, 'id' | 'firstName' | 'lastName' | 'email' | 'cell'>
      & { linkedBrewery?: Maybe<(
        { __typename?: 'Brewery' }
        & Pick<Brewery, 'id' | 'name' | 'logoUrl' | 'state' | 'untappd'>
      )> }
    )> }
  )> }
);

export type GetVolumeAvailabilityOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVolumeAvailabilityOptionsQuery = (
  { __typename?: 'Query' }
  & { appData: (
    { __typename?: 'AppData' }
    & { volumeAvailabilities: Array<(
      { __typename?: 'VolumeAvailability' }
      & Pick<VolumeAvailability, 'id' | 'name' | 'displayName'>
    )> }
  ) }
);

export type GetPackingFormatsOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPackingFormatsOptionsQuery = (
  { __typename?: 'Query' }
  & { appData: (
    { __typename?: 'AppData' }
    & { packingFormats: Array<(
      { __typename?: 'PackingFormat' }
      & Pick<PackingFormat, 'id' | 'name' | 'displayName'>
    )> }
  ) }
);

export type GetValidSubscriptionQueryVariables = Exact<{
  breweryId: Scalars['ID'];
}>;


export type GetValidSubscriptionQuery = (
  { __typename?: 'Query' }
  & { subscriptionByBrewery?: Maybe<(
    { __typename?: 'SubscriptionPlan' }
    & Pick<SubscriptionPlan, 'id' | 'hasExpired'>
  )> }
);


export const UpdateMyDistributorsDocument = gql`
    mutation UpdateMyDistributors($input: UpdateMyDistributorsInput!) {
  updateMyDistributors(input: $input)
}
    `;
export type UpdateMyDistributorsMutationFn = Apollo.MutationFunction<UpdateMyDistributorsMutation, UpdateMyDistributorsMutationVariables>;

/**
 * __useUpdateMyDistributorsMutation__
 *
 * To run a mutation, you first call `useUpdateMyDistributorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyDistributorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyDistributorsMutation, { data, loading, error }] = useUpdateMyDistributorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyDistributorsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyDistributorsMutation, UpdateMyDistributorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyDistributorsMutation, UpdateMyDistributorsMutationVariables>(UpdateMyDistributorsDocument, options);
      }
export type UpdateMyDistributorsMutationHookResult = ReturnType<typeof useUpdateMyDistributorsMutation>;
export type UpdateMyDistributorsMutationResult = Apollo.MutationResult<UpdateMyDistributorsMutation>;
export type UpdateMyDistributorsMutationOptions = Apollo.BaseMutationOptions<UpdateMyDistributorsMutation, UpdateMyDistributorsMutationVariables>;
export const SendMissingDistributorDocument = gql`
    mutation SendMissingDistributor($input: SendMissingDistributorInput!) {
  sendMissingDistributor(input: $input)
}
    `;
export type SendMissingDistributorMutationFn = Apollo.MutationFunction<SendMissingDistributorMutation, SendMissingDistributorMutationVariables>;

/**
 * __useSendMissingDistributorMutation__
 *
 * To run a mutation, you first call `useSendMissingDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMissingDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMissingDistributorMutation, { data, loading, error }] = useSendMissingDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMissingDistributorMutation(baseOptions?: Apollo.MutationHookOptions<SendMissingDistributorMutation, SendMissingDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMissingDistributorMutation, SendMissingDistributorMutationVariables>(SendMissingDistributorDocument, options);
      }
export type SendMissingDistributorMutationHookResult = ReturnType<typeof useSendMissingDistributorMutation>;
export type SendMissingDistributorMutationResult = Apollo.MutationResult<SendMissingDistributorMutation>;
export type SendMissingDistributorMutationOptions = Apollo.BaseMutationOptions<SendMissingDistributorMutation, SendMissingDistributorMutationVariables>;
export const MyDistributorsDocument = gql`
    query MyDistributors($id: ID!) {
  brewery(id: $id) {
    id
    distributors(removeSelfDistributor: true) {
      id
      name
      contactEmails
      distributionStates
    }
    selfDistributionStates
  }
}
    `;

/**
 * __useMyDistributorsQuery__
 *
 * To run a query within a React component, call `useMyDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyDistributorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyDistributorsQuery(baseOptions: Apollo.QueryHookOptions<MyDistributorsQuery, MyDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyDistributorsQuery, MyDistributorsQueryVariables>(MyDistributorsDocument, options);
      }
export function useMyDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyDistributorsQuery, MyDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyDistributorsQuery, MyDistributorsQueryVariables>(MyDistributorsDocument, options);
        }
export type MyDistributorsQueryHookResult = ReturnType<typeof useMyDistributorsQuery>;
export type MyDistributorsLazyQueryHookResult = ReturnType<typeof useMyDistributorsLazyQuery>;
export type MyDistributorsQueryResult = Apollo.QueryResult<MyDistributorsQuery, MyDistributorsQueryVariables>;
export const MyBrewPipelinePageDocument = gql`
    query MyBrewPipelinePage($breweryId: ID!, $pagination: PaginationInput!, $filter: OrderFilterInput!) {
  brewery(id: $breweryId) {
    id
    callToActions
  }
  ordersForBrewery(breweryId: $breweryId, pagination: $pagination, filter: $filter) {
    pageInfo {
      total
      offset
      pageSize
    }
    page {
      id
      readableId
      placedAt
      retailer {
        id
        firstName
        lastName
        cell
        email
        retailerInformation {
          individualInformation {
            locationName
            street
            city
            state
            zip
          }
          chainInformation {
            name
            purchaseStates
          }
        }
      }
      distributor {
        id
        name
        contactEmails
      }
      lines {
        id
        readableId
        brand {
          id
          name
        }
        packingFormat {
          id
          name
          displayName
        }
        order {
          id
          readableId
        }
        quantity
        minPtr
        status {
          id
          name
          displayName
        }
      }
    }
  }
}
    `;

/**
 * __useMyBrewPipelinePageQuery__
 *
 * To run a query within a React component, call `useMyBrewPipelinePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBrewPipelinePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBrewPipelinePageQuery({
 *   variables: {
 *      breweryId: // value for 'breweryId'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMyBrewPipelinePageQuery(baseOptions: Apollo.QueryHookOptions<MyBrewPipelinePageQuery, MyBrewPipelinePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBrewPipelinePageQuery, MyBrewPipelinePageQueryVariables>(MyBrewPipelinePageDocument, options);
      }
export function useMyBrewPipelinePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBrewPipelinePageQuery, MyBrewPipelinePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBrewPipelinePageQuery, MyBrewPipelinePageQueryVariables>(MyBrewPipelinePageDocument, options);
        }
export type MyBrewPipelinePageQueryHookResult = ReturnType<typeof useMyBrewPipelinePageQuery>;
export type MyBrewPipelinePageLazyQueryHookResult = ReturnType<typeof useMyBrewPipelinePageLazyQuery>;
export type MyBrewPipelinePageQueryResult = Apollo.QueryResult<MyBrewPipelinePageQuery, MyBrewPipelinePageQueryVariables>;
export const MarkAsShippedDocument = gql`
    mutation MarkAsShipped($input: UpdateManyOrderLineStatusInput!) {
  updateManyOrderLineStatus(input: $input)
}
    `;
export type MarkAsShippedMutationFn = Apollo.MutationFunction<MarkAsShippedMutation, MarkAsShippedMutationVariables>;

/**
 * __useMarkAsShippedMutation__
 *
 * To run a mutation, you first call `useMarkAsShippedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsShippedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsShippedMutation, { data, loading, error }] = useMarkAsShippedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAsShippedMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsShippedMutation, MarkAsShippedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAsShippedMutation, MarkAsShippedMutationVariables>(MarkAsShippedDocument, options);
      }
export type MarkAsShippedMutationHookResult = ReturnType<typeof useMarkAsShippedMutation>;
export type MarkAsShippedMutationResult = Apollo.MutationResult<MarkAsShippedMutation>;
export type MarkAsShippedMutationOptions = Apollo.BaseMutationOptions<MarkAsShippedMutation, MarkAsShippedMutationVariables>;
export const UpdateBreweryInformationDocument = gql`
    mutation UpdateBreweryInformation($input: UpdateBreweryInformationInput!) {
  updateBreweryInformation(input: $input)
}
    `;
export type UpdateBreweryInformationMutationFn = Apollo.MutationFunction<UpdateBreweryInformationMutation, UpdateBreweryInformationMutationVariables>;

/**
 * __useUpdateBreweryInformationMutation__
 *
 * To run a mutation, you first call `useUpdateBreweryInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBreweryInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBreweryInformationMutation, { data, loading, error }] = useUpdateBreweryInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBreweryInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBreweryInformationMutation, UpdateBreweryInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBreweryInformationMutation, UpdateBreweryInformationMutationVariables>(UpdateBreweryInformationDocument, options);
      }
export type UpdateBreweryInformationMutationHookResult = ReturnType<typeof useUpdateBreweryInformationMutation>;
export type UpdateBreweryInformationMutationResult = Apollo.MutationResult<UpdateBreweryInformationMutation>;
export type UpdateBreweryInformationMutationOptions = Apollo.BaseMutationOptions<UpdateBreweryInformationMutation, UpdateBreweryInformationMutationVariables>;
export const MyBrewerProfileDocument = gql`
    query MyBrewerProfile($id: ID!) {
  brewery(id: $id) {
    id
    name
    city
    state
    website
    untappd
    description
    logoUrl
    images {
      id
      url
    }
  }
}
    `;

/**
 * __useMyBrewerProfileQuery__
 *
 * To run a query within a React component, call `useMyBrewerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBrewerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBrewerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyBrewerProfileQuery(baseOptions: Apollo.QueryHookOptions<MyBrewerProfileQuery, MyBrewerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyBrewerProfileQuery, MyBrewerProfileQueryVariables>(MyBrewerProfileDocument, options);
      }
export function useMyBrewerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyBrewerProfileQuery, MyBrewerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyBrewerProfileQuery, MyBrewerProfileQueryVariables>(MyBrewerProfileDocument, options);
        }
export type MyBrewerProfileQueryHookResult = ReturnType<typeof useMyBrewerProfileQuery>;
export type MyBrewerProfileLazyQueryHookResult = ReturnType<typeof useMyBrewerProfileLazyQuery>;
export type MyBrewerProfileQueryResult = Apollo.QueryResult<MyBrewerProfileQuery, MyBrewerProfileQueryVariables>;
export const MyRetailersPageDocument = gql`
    query MyRetailersPage($breweryId: ID!) {
  retailersForBrewery(breweryId: $breweryId) {
    id
    firstName
    lastName
    cell
    email
    retailerInformation {
      id
      chainInformation {
        name
        purchaseStates
        website
      }
      individualInformation {
        locationName
        street
        city
        state
        zip
        website
      }
      placedOrders {
        id
        placedAt
        readableId
        lines {
          id
          readableId
          quantity
          minPtr
          canReorder
          status {
            id
            name
            displayName
          }
          brand {
            id
            name
          }
          packingFormat {
            id
            displayName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMyRetailersPageQuery__
 *
 * To run a query within a React component, call `useMyRetailersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRetailersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRetailersPageQuery({
 *   variables: {
 *      breweryId: // value for 'breweryId'
 *   },
 * });
 */
export function useMyRetailersPageQuery(baseOptions: Apollo.QueryHookOptions<MyRetailersPageQuery, MyRetailersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRetailersPageQuery, MyRetailersPageQueryVariables>(MyRetailersPageDocument, options);
      }
export function useMyRetailersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRetailersPageQuery, MyRetailersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRetailersPageQuery, MyRetailersPageQueryVariables>(MyRetailersPageDocument, options);
        }
export type MyRetailersPageQueryHookResult = ReturnType<typeof useMyRetailersPageQuery>;
export type MyRetailersPageLazyQueryHookResult = ReturnType<typeof useMyRetailersPageLazyQuery>;
export type MyRetailersPageQueryResult = Apollo.QueryResult<MyRetailersPageQuery, MyRetailersPageQueryVariables>;
export const InviteRetailerDocument = gql`
    mutation InviteRetailer($input: InviteRetailerInput!) {
  inviteRetailer(input: $input)
}
    `;
export type InviteRetailerMutationFn = Apollo.MutationFunction<InviteRetailerMutation, InviteRetailerMutationVariables>;

/**
 * __useInviteRetailerMutation__
 *
 * To run a mutation, you first call `useInviteRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteRetailerMutation, { data, loading, error }] = useInviteRetailerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteRetailerMutation(baseOptions?: Apollo.MutationHookOptions<InviteRetailerMutation, InviteRetailerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteRetailerMutation, InviteRetailerMutationVariables>(InviteRetailerDocument, options);
      }
export type InviteRetailerMutationHookResult = ReturnType<typeof useInviteRetailerMutation>;
export type InviteRetailerMutationResult = Apollo.MutationResult<InviteRetailerMutation>;
export type InviteRetailerMutationOptions = Apollo.BaseMutationOptions<InviteRetailerMutation, InviteRetailerMutationVariables>;
export const UpdateUserInformationDocument = gql`
    mutation UpdateUserInformation($input: UpdateUserInformationInput!) {
  updateUserInformation(input: $input)
}
    `;
export type UpdateUserInformationMutationFn = Apollo.MutationFunction<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;

/**
 * __useUpdateUserInformationMutation__
 *
 * To run a mutation, you first call `useUpdateUserInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInformationMutation, { data, loading, error }] = useUpdateUserInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>(UpdateUserInformationDocument, options);
      }
export type UpdateUserInformationMutationHookResult = ReturnType<typeof useUpdateUserInformationMutation>;
export type UpdateUserInformationMutationResult = Apollo.MutationResult<UpdateUserInformationMutation>;
export type UpdateUserInformationMutationOptions = Apollo.BaseMutationOptions<UpdateUserInformationMutation, UpdateUserInformationMutationVariables>;
export const UpsertSubscriptionDocument = gql`
    mutation UpsertSubscription($input: UpsertSubscriptionInput!) {
  upsertSubscription(input: $input)
}
    `;
export type UpsertSubscriptionMutationFn = Apollo.MutationFunction<UpsertSubscriptionMutation, UpsertSubscriptionMutationVariables>;

/**
 * __useUpsertSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpsertSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSubscriptionMutation, { data, loading, error }] = useUpsertSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSubscriptionMutation, UpsertSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSubscriptionMutation, UpsertSubscriptionMutationVariables>(UpsertSubscriptionDocument, options);
      }
export type UpsertSubscriptionMutationHookResult = ReturnType<typeof useUpsertSubscriptionMutation>;
export type UpsertSubscriptionMutationResult = Apollo.MutationResult<UpsertSubscriptionMutation>;
export type UpsertSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpsertSubscriptionMutation, UpsertSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription($id: ID!) {
  cancelSubscription(id: $id)
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const GetSubscriptionDocument = gql`
    query GetSubscription($breweryId: ID!) {
  subscriptionByBrewery(breweryId: $breweryId) {
    id
    createdOn
    cancelAt
    billingPeriodEnd
    states
    hasExpired
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      breweryId: // value for 'breweryId'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const UpsertBrandDocument = gql`
    mutation UpsertBrand($input: UpsertBrandInput!) {
  upsertBrand(input: $input)
}
    `;
export type UpsertBrandMutationFn = Apollo.MutationFunction<UpsertBrandMutation, UpsertBrandMutationVariables>;

/**
 * __useUpsertBrandMutation__
 *
 * To run a mutation, you first call `useUpsertBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBrandMutation, { data, loading, error }] = useUpsertBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpsertBrandMutation, UpsertBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertBrandMutation, UpsertBrandMutationVariables>(UpsertBrandDocument, options);
      }
export type UpsertBrandMutationHookResult = ReturnType<typeof useUpsertBrandMutation>;
export type UpsertBrandMutationResult = Apollo.MutationResult<UpsertBrandMutation>;
export type UpsertBrandMutationOptions = Apollo.BaseMutationOptions<UpsertBrandMutation, UpsertBrandMutationVariables>;
export const CreateBrandCategoryDocument = gql`
    mutation CreateBrandCategory($input: CreateBrandCategoryInput!) {
  createBrandCategory(input: $input)
}
    `;
export type CreateBrandCategoryMutationFn = Apollo.MutationFunction<CreateBrandCategoryMutation, CreateBrandCategoryMutationVariables>;

/**
 * __useCreateBrandCategoryMutation__
 *
 * To run a mutation, you first call `useCreateBrandCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandCategoryMutation, { data, loading, error }] = useCreateBrandCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandCategoryMutation, CreateBrandCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandCategoryMutation, CreateBrandCategoryMutationVariables>(CreateBrandCategoryDocument, options);
      }
export type CreateBrandCategoryMutationHookResult = ReturnType<typeof useCreateBrandCategoryMutation>;
export type CreateBrandCategoryMutationResult = Apollo.MutationResult<CreateBrandCategoryMutation>;
export type CreateBrandCategoryMutationOptions = Apollo.BaseMutationOptions<CreateBrandCategoryMutation, CreateBrandCategoryMutationVariables>;
export const BrandFormPageOptionsDocument = gql`
    query BrandFormPageOptions($id: ID!) {
  brewery(id: $id) {
    id
    ownedBy {
      id
      status {
        id
        name
        displayName
      }
    }
    brandCategories {
      id
      name
    }
  }
  appData {
    volumeAvailabilities {
      id
      name
      displayName
    }
    packingFormats {
      id
      name
      displayName
    }
  }
}
    `;

/**
 * __useBrandFormPageOptionsQuery__
 *
 * To run a query within a React component, call `useBrandFormPageOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandFormPageOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandFormPageOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandFormPageOptionsQuery(baseOptions: Apollo.QueryHookOptions<BrandFormPageOptionsQuery, BrandFormPageOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandFormPageOptionsQuery, BrandFormPageOptionsQueryVariables>(BrandFormPageOptionsDocument, options);
      }
export function useBrandFormPageOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandFormPageOptionsQuery, BrandFormPageOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandFormPageOptionsQuery, BrandFormPageOptionsQueryVariables>(BrandFormPageOptionsDocument, options);
        }
export type BrandFormPageOptionsQueryHookResult = ReturnType<typeof useBrandFormPageOptionsQuery>;
export type BrandFormPageOptionsLazyQueryHookResult = ReturnType<typeof useBrandFormPageOptionsLazyQuery>;
export type BrandFormPageOptionsQueryResult = Apollo.QueryResult<BrandFormPageOptionsQuery, BrandFormPageOptionsQueryVariables>;
export const LoadBrandDocument = gql`
    query LoadBrand($id: ID!) {
  brand(id: $id) {
    id
    listed
    name
    style
    description
    category {
      id
      name
    }
    ibu
    abv
    uniqueStyle
    untappdId
    volumeAvailability {
      id
      name
      displayName
    }
    brandImage
    bannerImage
    graphics {
      id
      url
    }
    formats {
      id
      packingFormat {
        id
        name
        displayName
      }
      lowTaxMinPtr
      listed
      state
    }
    ratebeerRating
    untappdRating
    awards {
      id
      name
      organization
      year
    }
  }
}
    `;

/**
 * __useLoadBrandQuery__
 *
 * To run a query within a React component, call `useLoadBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadBrandQuery(baseOptions: Apollo.QueryHookOptions<LoadBrandQuery, LoadBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadBrandQuery, LoadBrandQueryVariables>(LoadBrandDocument, options);
      }
export function useLoadBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadBrandQuery, LoadBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadBrandQuery, LoadBrandQueryVariables>(LoadBrandDocument, options);
        }
export type LoadBrandQueryHookResult = ReturnType<typeof useLoadBrandQuery>;
export type LoadBrandLazyQueryHookResult = ReturnType<typeof useLoadBrandLazyQuery>;
export type LoadBrandQueryResult = Apollo.QueryResult<LoadBrandQuery, LoadBrandQueryVariables>;
export const ChangeListedStatusDocument = gql`
    mutation ChangeListedStatus($input: ChangeListedStatusInput!) {
  changeListedStatus(input: $input)
}
    `;
export type ChangeListedStatusMutationFn = Apollo.MutationFunction<ChangeListedStatusMutation, ChangeListedStatusMutationVariables>;

/**
 * __useChangeListedStatusMutation__
 *
 * To run a mutation, you first call `useChangeListedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeListedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeListedStatusMutation, { data, loading, error }] = useChangeListedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeListedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeListedStatusMutation, ChangeListedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeListedStatusMutation, ChangeListedStatusMutationVariables>(ChangeListedStatusDocument, options);
      }
export type ChangeListedStatusMutationHookResult = ReturnType<typeof useChangeListedStatusMutation>;
export type ChangeListedStatusMutationResult = Apollo.MutationResult<ChangeListedStatusMutation>;
export type ChangeListedStatusMutationOptions = Apollo.BaseMutationOptions<ChangeListedStatusMutation, ChangeListedStatusMutationVariables>;
export const ImportFromUntappdDocument = gql`
    mutation ImportFromUntappd($breweryId: ID!) {
  importFromUntappd(breweryId: $breweryId)
}
    `;
export type ImportFromUntappdMutationFn = Apollo.MutationFunction<ImportFromUntappdMutation, ImportFromUntappdMutationVariables>;

/**
 * __useImportFromUntappdMutation__
 *
 * To run a mutation, you first call `useImportFromUntappdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportFromUntappdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importFromUntappdMutation, { data, loading, error }] = useImportFromUntappdMutation({
 *   variables: {
 *      breweryId: // value for 'breweryId'
 *   },
 * });
 */
export function useImportFromUntappdMutation(baseOptions?: Apollo.MutationHookOptions<ImportFromUntappdMutation, ImportFromUntappdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportFromUntappdMutation, ImportFromUntappdMutationVariables>(ImportFromUntappdDocument, options);
      }
export type ImportFromUntappdMutationHookResult = ReturnType<typeof useImportFromUntappdMutation>;
export type ImportFromUntappdMutationResult = Apollo.MutationResult<ImportFromUntappdMutation>;
export type ImportFromUntappdMutationOptions = Apollo.BaseMutationOptions<ImportFromUntappdMutation, ImportFromUntappdMutationVariables>;
export const RemoveBrandDocument = gql`
    mutation RemoveBrand($brandId: ID!) {
  removeBrand(brandId: $brandId)
}
    `;
export type RemoveBrandMutationFn = Apollo.MutationFunction<RemoveBrandMutation, RemoveBrandMutationVariables>;

/**
 * __useRemoveBrandMutation__
 *
 * To run a mutation, you first call `useRemoveBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBrandMutation, { data, loading, error }] = useRemoveBrandMutation({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useRemoveBrandMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBrandMutation, RemoveBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBrandMutation, RemoveBrandMutationVariables>(RemoveBrandDocument, options);
      }
export type RemoveBrandMutationHookResult = ReturnType<typeof useRemoveBrandMutation>;
export type RemoveBrandMutationResult = Apollo.MutationResult<RemoveBrandMutation>;
export type RemoveBrandMutationOptions = Apollo.BaseMutationOptions<RemoveBrandMutation, RemoveBrandMutationVariables>;
export const BrandsPageDocument = gql`
    query BrandsPage($id: ID!, $filter: [String!]) {
  brewery(id: $id) {
    id
    brandCategories {
      id
      name
      numberOfBrands
    }
    activeBrands {
      id
      listed
      name
      style
      untappdId
      category {
        id
        name
      }
      ibu
      abv
      uniqueStyle
      volumeAvailability {
        id
        name
        displayName
      }
      brandImage
      graphics {
        id
        url
      }
      formats {
        id
        listed
        lowTaxMinPtr
      }
      ratebeerRating
      untappdRating
      awards {
        id
        name
        organization
        year
      }
    }
    brands(categoryFilter: $filter) {
      id
      listed
      name
      style
      untappdId
      category {
        id
        name
      }
      ibu
      abv
      uniqueStyle
      volumeAvailability {
        id
        name
        displayName
      }
      brandImage
      graphics {
        id
        url
      }
      formats {
        id
        listed
        lowTaxMinPtr
      }
      ratebeerRating
      untappdRating
      awards {
        id
        name
        organization
        year
      }
    }
  }
}
    `;

/**
 * __useBrandsPageQuery__
 *
 * To run a query within a React component, call `useBrandsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBrandsPageQuery(baseOptions: Apollo.QueryHookOptions<BrandsPageQuery, BrandsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsPageQuery, BrandsPageQueryVariables>(BrandsPageDocument, options);
      }
export function useBrandsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsPageQuery, BrandsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsPageQuery, BrandsPageQueryVariables>(BrandsPageDocument, options);
        }
export type BrandsPageQueryHookResult = ReturnType<typeof useBrandsPageQuery>;
export type BrandsPageLazyQueryHookResult = ReturnType<typeof useBrandsPageLazyQuery>;
export type BrandsPageQueryResult = Apollo.QueryResult<BrandsPageQuery, BrandsPageQueryVariables>;
export const AcceptEmployeeInviteDocument = gql`
    mutation AcceptEmployeeInvite($input: AcceptEmployeeInviteInput!) {
  acceptEmployeeInvite(input: $input)
}
    `;
export type AcceptEmployeeInviteMutationFn = Apollo.MutationFunction<AcceptEmployeeInviteMutation, AcceptEmployeeInviteMutationVariables>;

/**
 * __useAcceptEmployeeInviteMutation__
 *
 * To run a mutation, you first call `useAcceptEmployeeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptEmployeeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptEmployeeInviteMutation, { data, loading, error }] = useAcceptEmployeeInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptEmployeeInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptEmployeeInviteMutation, AcceptEmployeeInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptEmployeeInviteMutation, AcceptEmployeeInviteMutationVariables>(AcceptEmployeeInviteDocument, options);
      }
export type AcceptEmployeeInviteMutationHookResult = ReturnType<typeof useAcceptEmployeeInviteMutation>;
export type AcceptEmployeeInviteMutationResult = Apollo.MutationResult<AcceptEmployeeInviteMutation>;
export type AcceptEmployeeInviteMutationOptions = Apollo.BaseMutationOptions<AcceptEmployeeInviteMutation, AcceptEmployeeInviteMutationVariables>;
export const GetInviteDocument = gql`
    query GetInvite($inviteId: ID!) {
  invite(id: $inviteId) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useGetInviteQuery__
 *
 * To run a query within a React component, call `useGetInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useGetInviteQuery(baseOptions: Apollo.QueryHookOptions<GetInviteQuery, GetInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteQuery, GetInviteQueryVariables>(GetInviteDocument, options);
      }
export function useGetInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteQuery, GetInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteQuery, GetInviteQueryVariables>(GetInviteDocument, options);
        }
export type GetInviteQueryHookResult = ReturnType<typeof useGetInviteQuery>;
export type GetInviteLazyQueryHookResult = ReturnType<typeof useGetInviteLazyQuery>;
export type GetInviteQueryResult = Apollo.QueryResult<GetInviteQuery, GetInviteQueryVariables>;
export const RegisterBreweryDocument = gql`
    mutation RegisterBrewery($input: RegisterBreweryInput!) {
  registerBrewery(input: $input)
}
    `;
export type RegisterBreweryMutationFn = Apollo.MutationFunction<RegisterBreweryMutation, RegisterBreweryMutationVariables>;

/**
 * __useRegisterBreweryMutation__
 *
 * To run a mutation, you first call `useRegisterBreweryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBreweryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBreweryMutation, { data, loading, error }] = useRegisterBreweryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterBreweryMutation(baseOptions?: Apollo.MutationHookOptions<RegisterBreweryMutation, RegisterBreweryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterBreweryMutation, RegisterBreweryMutationVariables>(RegisterBreweryDocument, options);
      }
export type RegisterBreweryMutationHookResult = ReturnType<typeof useRegisterBreweryMutation>;
export type RegisterBreweryMutationResult = Apollo.MutationResult<RegisterBreweryMutation>;
export type RegisterBreweryMutationOptions = Apollo.BaseMutationOptions<RegisterBreweryMutation, RegisterBreweryMutationVariables>;
export const InviteEmployeeDocument = gql`
    mutation InviteEmployee($input: InviteEmployeeInput!) {
  inviteEmployee(input: $input)
}
    `;
export type InviteEmployeeMutationFn = Apollo.MutationFunction<InviteEmployeeMutation, InviteEmployeeMutationVariables>;

/**
 * __useInviteEmployeeMutation__
 *
 * To run a mutation, you first call `useInviteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteEmployeeMutation, { data, loading, error }] = useInviteEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<InviteEmployeeMutation, InviteEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteEmployeeMutation, InviteEmployeeMutationVariables>(InviteEmployeeDocument, options);
      }
export type InviteEmployeeMutationHookResult = ReturnType<typeof useInviteEmployeeMutation>;
export type InviteEmployeeMutationResult = Apollo.MutationResult<InviteEmployeeMutation>;
export type InviteEmployeeMutationOptions = Apollo.BaseMutationOptions<InviteEmployeeMutation, InviteEmployeeMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetSuperEmployeesDocument = gql`
    query GetSuperEmployees {
  appData {
    superEmployees {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetSuperEmployeesQuery__
 *
 * To run a query within a React component, call `useGetSuperEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuperEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuperEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSuperEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetSuperEmployeesQuery, GetSuperEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuperEmployeesQuery, GetSuperEmployeesQueryVariables>(GetSuperEmployeesDocument, options);
      }
export function useGetSuperEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuperEmployeesQuery, GetSuperEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuperEmployeesQuery, GetSuperEmployeesQueryVariables>(GetSuperEmployeesDocument, options);
        }
export type GetSuperEmployeesQueryHookResult = ReturnType<typeof useGetSuperEmployeesQuery>;
export type GetSuperEmployeesLazyQueryHookResult = ReturnType<typeof useGetSuperEmployeesLazyQuery>;
export type GetSuperEmployeesQueryResult = Apollo.QueryResult<GetSuperEmployeesQuery, GetSuperEmployeesQueryVariables>;
export const RejectApplicationDocument = gql`
    mutation RejectApplication($siteUserId: ID!) {
  rejectApplication(siteUserId: $siteUserId)
}
    `;
export type RejectApplicationMutationFn = Apollo.MutationFunction<RejectApplicationMutation, RejectApplicationMutationVariables>;

/**
 * __useRejectApplicationMutation__
 *
 * To run a mutation, you first call `useRejectApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApplicationMutation, { data, loading, error }] = useRejectApplicationMutation({
 *   variables: {
 *      siteUserId: // value for 'siteUserId'
 *   },
 * });
 */
export function useRejectApplicationMutation(baseOptions?: Apollo.MutationHookOptions<RejectApplicationMutation, RejectApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectApplicationMutation, RejectApplicationMutationVariables>(RejectApplicationDocument, options);
      }
export type RejectApplicationMutationHookResult = ReturnType<typeof useRejectApplicationMutation>;
export type RejectApplicationMutationResult = Apollo.MutationResult<RejectApplicationMutation>;
export type RejectApplicationMutationOptions = Apollo.BaseMutationOptions<RejectApplicationMutation, RejectApplicationMutationVariables>;
export const ApproveApplicationDocument = gql`
    mutation ApproveApplication($siteUserId: ID!) {
  approveApplication(siteUserId: $siteUserId)
}
    `;
export type ApproveApplicationMutationFn = Apollo.MutationFunction<ApproveApplicationMutation, ApproveApplicationMutationVariables>;

/**
 * __useApproveApplicationMutation__
 *
 * To run a mutation, you first call `useApproveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveApplicationMutation, { data, loading, error }] = useApproveApplicationMutation({
 *   variables: {
 *      siteUserId: // value for 'siteUserId'
 *   },
 * });
 */
export function useApproveApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ApproveApplicationMutation, ApproveApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveApplicationMutation, ApproveApplicationMutationVariables>(ApproveApplicationDocument, options);
      }
export type ApproveApplicationMutationHookResult = ReturnType<typeof useApproveApplicationMutation>;
export type ApproveApplicationMutationResult = Apollo.MutationResult<ApproveApplicationMutation>;
export type ApproveApplicationMutationOptions = Apollo.BaseMutationOptions<ApproveApplicationMutation, ApproveApplicationMutationVariables>;
export const InviteBreweryDocument = gql`
    mutation inviteBrewery($input: InviteBreweryInput!) {
  inviteBrewery(input: $input)
}
    `;
export type InviteBreweryMutationFn = Apollo.MutationFunction<InviteBreweryMutation, InviteBreweryMutationVariables>;

/**
 * __useInviteBreweryMutation__
 *
 * To run a mutation, you first call `useInviteBreweryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteBreweryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteBreweryMutation, { data, loading, error }] = useInviteBreweryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteBreweryMutation(baseOptions?: Apollo.MutationHookOptions<InviteBreweryMutation, InviteBreweryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteBreweryMutation, InviteBreweryMutationVariables>(InviteBreweryDocument, options);
      }
export type InviteBreweryMutationHookResult = ReturnType<typeof useInviteBreweryMutation>;
export type InviteBreweryMutationResult = Apollo.MutationResult<InviteBreweryMutation>;
export type InviteBreweryMutationOptions = Apollo.BaseMutationOptions<InviteBreweryMutation, InviteBreweryMutationVariables>;
export const PendingApplicationsPageDocument = gql`
    query PendingApplicationsPage {
  pendingApplications {
    id
    firstName
    lastName
    cell
    linkedBrewery {
      id
      name
      state
      untappd
    }
    status {
      id
      name
      displayName
    }
    accountType {
      id
      name
      displayName
    }
  }
}
    `;

/**
 * __usePendingApplicationsPageQuery__
 *
 * To run a query within a React component, call `usePendingApplicationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingApplicationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingApplicationsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingApplicationsPageQuery(baseOptions?: Apollo.QueryHookOptions<PendingApplicationsPageQuery, PendingApplicationsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PendingApplicationsPageQuery, PendingApplicationsPageQueryVariables>(PendingApplicationsPageDocument, options);
      }
export function usePendingApplicationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PendingApplicationsPageQuery, PendingApplicationsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PendingApplicationsPageQuery, PendingApplicationsPageQueryVariables>(PendingApplicationsPageDocument, options);
        }
export type PendingApplicationsPageQueryHookResult = ReturnType<typeof usePendingApplicationsPageQuery>;
export type PendingApplicationsPageLazyQueryHookResult = ReturnType<typeof usePendingApplicationsPageLazyQuery>;
export type PendingApplicationsPageQueryResult = Apollo.QueryResult<PendingApplicationsPageQuery, PendingApplicationsPageQueryVariables>;
export const ApprovedBreweriesPageDocument = gql`
    query ApprovedBreweriesPage {
  approvedBreweries {
    id
    name
    state
    ownedBy {
      id
      firstName
      lastName
      email
      cell
      loginPermitted
    }
  }
}
    `;

/**
 * __useApprovedBreweriesPageQuery__
 *
 * To run a query within a React component, call `useApprovedBreweriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedBreweriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedBreweriesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useApprovedBreweriesPageQuery(baseOptions?: Apollo.QueryHookOptions<ApprovedBreweriesPageQuery, ApprovedBreweriesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovedBreweriesPageQuery, ApprovedBreweriesPageQueryVariables>(ApprovedBreweriesPageDocument, options);
      }
export function useApprovedBreweriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovedBreweriesPageQuery, ApprovedBreweriesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovedBreweriesPageQuery, ApprovedBreweriesPageQueryVariables>(ApprovedBreweriesPageDocument, options);
        }
export type ApprovedBreweriesPageQueryHookResult = ReturnType<typeof useApprovedBreweriesPageQuery>;
export type ApprovedBreweriesPageLazyQueryHookResult = ReturnType<typeof useApprovedBreweriesPageLazyQuery>;
export type ApprovedBreweriesPageQueryResult = Apollo.QueryResult<ApprovedBreweriesPageQuery, ApprovedBreweriesPageQueryVariables>;
export const ActAsBreweryOwnerDocument = gql`
    mutation ActAsBreweryOwner($input: ActAsBreweryOwnerInput!) {
  actAsBreweryOwner(input: $input)
}
    `;
export type ActAsBreweryOwnerMutationFn = Apollo.MutationFunction<ActAsBreweryOwnerMutation, ActAsBreweryOwnerMutationVariables>;

/**
 * __useActAsBreweryOwnerMutation__
 *
 * To run a mutation, you first call `useActAsBreweryOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActAsBreweryOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actAsBreweryOwnerMutation, { data, loading, error }] = useActAsBreweryOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActAsBreweryOwnerMutation(baseOptions?: Apollo.MutationHookOptions<ActAsBreweryOwnerMutation, ActAsBreweryOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActAsBreweryOwnerMutation, ActAsBreweryOwnerMutationVariables>(ActAsBreweryOwnerDocument, options);
      }
export type ActAsBreweryOwnerMutationHookResult = ReturnType<typeof useActAsBreweryOwnerMutation>;
export type ActAsBreweryOwnerMutationResult = Apollo.MutationResult<ActAsBreweryOwnerMutation>;
export type ActAsBreweryOwnerMutationOptions = Apollo.BaseMutationOptions<ActAsBreweryOwnerMutation, ActAsBreweryOwnerMutationVariables>;
export const ToggleLoginPermittedDocument = gql`
    mutation ToggleLoginPermitted($siteUserId: ID!) {
  toggleLoginPermitted(siteUserId: $siteUserId)
}
    `;
export type ToggleLoginPermittedMutationFn = Apollo.MutationFunction<ToggleLoginPermittedMutation, ToggleLoginPermittedMutationVariables>;

/**
 * __useToggleLoginPermittedMutation__
 *
 * To run a mutation, you first call `useToggleLoginPermittedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLoginPermittedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLoginPermittedMutation, { data, loading, error }] = useToggleLoginPermittedMutation({
 *   variables: {
 *      siteUserId: // value for 'siteUserId'
 *   },
 * });
 */
export function useToggleLoginPermittedMutation(baseOptions?: Apollo.MutationHookOptions<ToggleLoginPermittedMutation, ToggleLoginPermittedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleLoginPermittedMutation, ToggleLoginPermittedMutationVariables>(ToggleLoginPermittedDocument, options);
      }
export type ToggleLoginPermittedMutationHookResult = ReturnType<typeof useToggleLoginPermittedMutation>;
export type ToggleLoginPermittedMutationResult = Apollo.MutationResult<ToggleLoginPermittedMutation>;
export type ToggleLoginPermittedMutationOptions = Apollo.BaseMutationOptions<ToggleLoginPermittedMutation, ToggleLoginPermittedMutationVariables>;
export const UpsertDistributorDocument = gql`
    mutation UpsertDistributor($input: UpsertDistributorInput!) {
  upsertDistributor(input: $input)
}
    `;
export type UpsertDistributorMutationFn = Apollo.MutationFunction<UpsertDistributorMutation, UpsertDistributorMutationVariables>;

/**
 * __useUpsertDistributorMutation__
 *
 * To run a mutation, you first call `useUpsertDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertDistributorMutation, { data, loading, error }] = useUpsertDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertDistributorMutation(baseOptions?: Apollo.MutationHookOptions<UpsertDistributorMutation, UpsertDistributorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertDistributorMutation, UpsertDistributorMutationVariables>(UpsertDistributorDocument, options);
      }
export type UpsertDistributorMutationHookResult = ReturnType<typeof useUpsertDistributorMutation>;
export type UpsertDistributorMutationResult = Apollo.MutationResult<UpsertDistributorMutation>;
export type UpsertDistributorMutationOptions = Apollo.BaseMutationOptions<UpsertDistributorMutation, UpsertDistributorMutationVariables>;
export const RemoveDistrubtorDocument = gql`
    mutation RemoveDistrubtor($distributorId: ID!) {
  removeDistributor(distributorId: $distributorId)
}
    `;
export type RemoveDistrubtorMutationFn = Apollo.MutationFunction<RemoveDistrubtorMutation, RemoveDistrubtorMutationVariables>;

/**
 * __useRemoveDistrubtorMutation__
 *
 * To run a mutation, you first call `useRemoveDistrubtorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDistrubtorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDistrubtorMutation, { data, loading, error }] = useRemoveDistrubtorMutation({
 *   variables: {
 *      distributorId: // value for 'distributorId'
 *   },
 * });
 */
export function useRemoveDistrubtorMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDistrubtorMutation, RemoveDistrubtorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDistrubtorMutation, RemoveDistrubtorMutationVariables>(RemoveDistrubtorDocument, options);
      }
export type RemoveDistrubtorMutationHookResult = ReturnType<typeof useRemoveDistrubtorMutation>;
export type RemoveDistrubtorMutationResult = Apollo.MutationResult<RemoveDistrubtorMutation>;
export type RemoveDistrubtorMutationOptions = Apollo.BaseMutationOptions<RemoveDistrubtorMutation, RemoveDistrubtorMutationVariables>;
export const GetAllDistributorsDocument = gql`
    query GetAllDistributors($exceptBreweryId: String) {
  appData {
    allDistributors(exceptBreweryId: $exceptBreweryId) {
      id
      name
      contactEmails
      distributionStates
      isASelfDistributor
    }
  }
}
    `;

/**
 * __useGetAllDistributorsQuery__
 *
 * To run a query within a React component, call `useGetAllDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDistributorsQuery({
 *   variables: {
 *      exceptBreweryId: // value for 'exceptBreweryId'
 *   },
 * });
 */
export function useGetAllDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDistributorsQuery, GetAllDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDistributorsQuery, GetAllDistributorsQueryVariables>(GetAllDistributorsDocument, options);
      }
export function useGetAllDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDistributorsQuery, GetAllDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDistributorsQuery, GetAllDistributorsQueryVariables>(GetAllDistributorsDocument, options);
        }
export type GetAllDistributorsQueryHookResult = ReturnType<typeof useGetAllDistributorsQuery>;
export type GetAllDistributorsLazyQueryHookResult = ReturnType<typeof useGetAllDistributorsLazyQuery>;
export type GetAllDistributorsQueryResult = Apollo.QueryResult<GetAllDistributorsQuery, GetAllDistributorsQueryVariables>;
export const UpdateOrderLineStatusDocument = gql`
    mutation UpdateOrderLineStatus($input: UpdateOrderLineStatusInput!) {
  updateOrderLineStatus(input: $input)
}
    `;
export type UpdateOrderLineStatusMutationFn = Apollo.MutationFunction<UpdateOrderLineStatusMutation, UpdateOrderLineStatusMutationVariables>;

/**
 * __useUpdateOrderLineStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderLineStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderLineStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderLineStatusMutation, { data, loading, error }] = useUpdateOrderLineStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderLineStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderLineStatusMutation, UpdateOrderLineStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderLineStatusMutation, UpdateOrderLineStatusMutationVariables>(UpdateOrderLineStatusDocument, options);
      }
export type UpdateOrderLineStatusMutationHookResult = ReturnType<typeof useUpdateOrderLineStatusMutation>;
export type UpdateOrderLineStatusMutationResult = Apollo.MutationResult<UpdateOrderLineStatusMutation>;
export type UpdateOrderLineStatusMutationOptions = Apollo.BaseMutationOptions<UpdateOrderLineStatusMutation, UpdateOrderLineStatusMutationVariables>;
export const OrdersSuperAdminPageDocument = gql`
    query OrdersSuperAdminPage($filter: OrderFilterInput!) {
  allOrders(filter: $filter) {
    id
    readableId
    placedAt
    retailer {
      id
      firstName
      lastName
      cell
      email
      retailerInformation {
        individualInformation {
          locationName
          street
          city
          state
          zip
        }
        chainInformation {
          name
          purchaseStates
        }
      }
    }
    distributor {
      id
      name
      contactEmails
    }
    brewery {
      id
      name
      state
    }
    lines {
      id
      readableId
      brand {
        id
        name
      }
      packingFormat {
        id
        name
        displayName
      }
      order {
        id
        readableId
      }
      quantity
      minPtr
      status {
        id
        name
        displayName
      }
    }
  }
}
    `;

/**
 * __useOrdersSuperAdminPageQuery__
 *
 * To run a query within a React component, call `useOrdersSuperAdminPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersSuperAdminPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersSuperAdminPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersSuperAdminPageQuery(baseOptions: Apollo.QueryHookOptions<OrdersSuperAdminPageQuery, OrdersSuperAdminPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersSuperAdminPageQuery, OrdersSuperAdminPageQueryVariables>(OrdersSuperAdminPageDocument, options);
      }
export function useOrdersSuperAdminPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersSuperAdminPageQuery, OrdersSuperAdminPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersSuperAdminPageQuery, OrdersSuperAdminPageQueryVariables>(OrdersSuperAdminPageDocument, options);
        }
export type OrdersSuperAdminPageQueryHookResult = ReturnType<typeof useOrdersSuperAdminPageQuery>;
export type OrdersSuperAdminPageLazyQueryHookResult = ReturnType<typeof useOrdersSuperAdminPageLazyQuery>;
export type OrdersSuperAdminPageQueryResult = Apollo.QueryResult<OrdersSuperAdminPageQuery, OrdersSuperAdminPageQueryVariables>;
export const SuperAdminReportsOptionsDocument = gql`
    query SuperAdminReportsOptions {
  allBreweries {
    id
    name
  }
  allBrands {
    id
    name
  }
  platformRetailers {
    id
    firstName
    lastName
    retailerInformation {
      id
      retailerChainStatus {
        id
        name
      }
      individualInformation {
        state
      }
      chainInformation {
        purchaseStates
      }
    }
  }
}
    `;

/**
 * __useSuperAdminReportsOptionsQuery__
 *
 * To run a query within a React component, call `useSuperAdminReportsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminReportsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminReportsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuperAdminReportsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<SuperAdminReportsOptionsQuery, SuperAdminReportsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperAdminReportsOptionsQuery, SuperAdminReportsOptionsQueryVariables>(SuperAdminReportsOptionsDocument, options);
      }
export function useSuperAdminReportsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperAdminReportsOptionsQuery, SuperAdminReportsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperAdminReportsOptionsQuery, SuperAdminReportsOptionsQueryVariables>(SuperAdminReportsOptionsDocument, options);
        }
export type SuperAdminReportsOptionsQueryHookResult = ReturnType<typeof useSuperAdminReportsOptionsQuery>;
export type SuperAdminReportsOptionsLazyQueryHookResult = ReturnType<typeof useSuperAdminReportsOptionsLazyQuery>;
export type SuperAdminReportsOptionsQueryResult = Apollo.QueryResult<SuperAdminReportsOptionsQuery, SuperAdminReportsOptionsQueryVariables>;
export const SuperAdminReportsPageDocument = gql`
    query SuperAdminReportsPage($pagination: PaginationInput!, $filter: ReportFilterInput!) {
  report(pagination: $pagination, filter: $filter) {
    pageInfo {
      total
      offset
      pageSize
    }
    page {
      id
      readableId
      placedAt
      retailer {
        id
        retailerInformation {
          individualInformation {
            locationName
            state
          }
          chainInformation {
            name
            purchaseStates
          }
        }
      }
      lines {
        id
        readableId
        brand {
          id
          name
        }
        minPtr
        status {
          id
          name
          displayName
        }
      }
    }
  }
}
    `;

/**
 * __useSuperAdminReportsPageQuery__
 *
 * To run a query within a React component, call `useSuperAdminReportsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminReportsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminReportsPageQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSuperAdminReportsPageQuery(baseOptions: Apollo.QueryHookOptions<SuperAdminReportsPageQuery, SuperAdminReportsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperAdminReportsPageQuery, SuperAdminReportsPageQueryVariables>(SuperAdminReportsPageDocument, options);
      }
export function useSuperAdminReportsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperAdminReportsPageQuery, SuperAdminReportsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperAdminReportsPageQuery, SuperAdminReportsPageQueryVariables>(SuperAdminReportsPageDocument, options);
        }
export type SuperAdminReportsPageQueryHookResult = ReturnType<typeof useSuperAdminReportsPageQuery>;
export type SuperAdminReportsPageLazyQueryHookResult = ReturnType<typeof useSuperAdminReportsPageLazyQuery>;
export type SuperAdminReportsPageQueryResult = Apollo.QueryResult<SuperAdminReportsPageQuery, SuperAdminReportsPageQueryVariables>;
export const UpdateRetailerInformationDocument = gql`
    mutation UpdateRetailerInformation($input: UpdateRetailerInput!) {
  updateRetailerInformation(input: $input)
}
    `;
export type UpdateRetailerInformationMutationFn = Apollo.MutationFunction<UpdateRetailerInformationMutation, UpdateRetailerInformationMutationVariables>;

/**
 * __useUpdateRetailerInformationMutation__
 *
 * To run a mutation, you first call `useUpdateRetailerInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetailerInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetailerInformationMutation, { data, loading, error }] = useUpdateRetailerInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRetailerInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRetailerInformationMutation, UpdateRetailerInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRetailerInformationMutation, UpdateRetailerInformationMutationVariables>(UpdateRetailerInformationDocument, options);
      }
export type UpdateRetailerInformationMutationHookResult = ReturnType<typeof useUpdateRetailerInformationMutation>;
export type UpdateRetailerInformationMutationResult = Apollo.MutationResult<UpdateRetailerInformationMutation>;
export type UpdateRetailerInformationMutationOptions = Apollo.BaseMutationOptions<UpdateRetailerInformationMutation, UpdateRetailerInformationMutationVariables>;
export const SuperAdminRetailersPageDocument = gql`
    query SuperAdminRetailersPage($filter: String) {
  platformRetailers(filter: $filter) {
    id
    loginPermitted
    firstName
    lastName
    cell
    email
    retailerInformation {
      retailerType {
        id
        name
        displayName
      }
      retailerChainStatus {
        id
        name
        displayName
      }
      chainInformation {
        name
        purchaseStates
        website
      }
      individualInformation {
        locationName
        street
        city
        state
        zip
        website
      }
    }
  }
}
    `;

/**
 * __useSuperAdminRetailersPageQuery__
 *
 * To run a query within a React component, call `useSuperAdminRetailersPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminRetailersPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminRetailersPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSuperAdminRetailersPageQuery(baseOptions?: Apollo.QueryHookOptions<SuperAdminRetailersPageQuery, SuperAdminRetailersPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuperAdminRetailersPageQuery, SuperAdminRetailersPageQueryVariables>(SuperAdminRetailersPageDocument, options);
      }
export function useSuperAdminRetailersPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuperAdminRetailersPageQuery, SuperAdminRetailersPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuperAdminRetailersPageQuery, SuperAdminRetailersPageQueryVariables>(SuperAdminRetailersPageDocument, options);
        }
export type SuperAdminRetailersPageQueryHookResult = ReturnType<typeof useSuperAdminRetailersPageQuery>;
export type SuperAdminRetailersPageLazyQueryHookResult = ReturnType<typeof useSuperAdminRetailersPageLazyQuery>;
export type SuperAdminRetailersPageQueryResult = Apollo.QueryResult<SuperAdminRetailersPageQuery, SuperAdminRetailersPageQueryVariables>;
export const VersionIndicatorDocument = gql`
    query VersionIndicator {
  version
}
    `;

/**
 * __useVersionIndicatorQuery__
 *
 * To run a query within a React component, call `useVersionIndicatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionIndicatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionIndicatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionIndicatorQuery(baseOptions?: Apollo.QueryHookOptions<VersionIndicatorQuery, VersionIndicatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionIndicatorQuery, VersionIndicatorQueryVariables>(VersionIndicatorDocument, options);
      }
export function useVersionIndicatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionIndicatorQuery, VersionIndicatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionIndicatorQuery, VersionIndicatorQueryVariables>(VersionIndicatorDocument, options);
        }
export type VersionIndicatorQueryHookResult = ReturnType<typeof useVersionIndicatorQuery>;
export type VersionIndicatorLazyQueryHookResult = ReturnType<typeof useVersionIndicatorLazyQuery>;
export type VersionIndicatorQueryResult = Apollo.QueryResult<VersionIndicatorQuery, VersionIndicatorQueryVariables>;
export const StopActingAsBreweryOwnerDocument = gql`
    mutation StopActingAsBreweryOwner($siteUserId: ID!) {
  stopActingAsBreweryOwner(siteUserId: $siteUserId)
}
    `;
export type StopActingAsBreweryOwnerMutationFn = Apollo.MutationFunction<StopActingAsBreweryOwnerMutation, StopActingAsBreweryOwnerMutationVariables>;

/**
 * __useStopActingAsBreweryOwnerMutation__
 *
 * To run a mutation, you first call `useStopActingAsBreweryOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopActingAsBreweryOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopActingAsBreweryOwnerMutation, { data, loading, error }] = useStopActingAsBreweryOwnerMutation({
 *   variables: {
 *      siteUserId: // value for 'siteUserId'
 *   },
 * });
 */
export function useStopActingAsBreweryOwnerMutation(baseOptions?: Apollo.MutationHookOptions<StopActingAsBreweryOwnerMutation, StopActingAsBreweryOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopActingAsBreweryOwnerMutation, StopActingAsBreweryOwnerMutationVariables>(StopActingAsBreweryOwnerDocument, options);
      }
export type StopActingAsBreweryOwnerMutationHookResult = ReturnType<typeof useStopActingAsBreweryOwnerMutation>;
export type StopActingAsBreweryOwnerMutationResult = Apollo.MutationResult<StopActingAsBreweryOwnerMutation>;
export type StopActingAsBreweryOwnerMutationOptions = Apollo.BaseMutationOptions<StopActingAsBreweryOwnerMutation, StopActingAsBreweryOwnerMutationVariables>;
export const MarkNotificationsDocument = gql`
    mutation MarkNotifications($notificationId: ID!) {
  markNotificationAsSeen(notificationId: $notificationId)
}
    `;
export type MarkNotificationsMutationFn = Apollo.MutationFunction<MarkNotificationsMutation, MarkNotificationsMutationVariables>;

/**
 * __useMarkNotificationsMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsMutation, { data, loading, error }] = useMarkNotificationsMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsMutation, MarkNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsMutation, MarkNotificationsMutationVariables>(MarkNotificationsDocument, options);
      }
export type MarkNotificationsMutationHookResult = ReturnType<typeof useMarkNotificationsMutation>;
export type MarkNotificationsMutationResult = Apollo.MutationResult<MarkNotificationsMutation>;
export type MarkNotificationsMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsMutation, MarkNotificationsMutationVariables>;
export const MyNotificationsDocument = gql`
    query MyNotifications($siteUserId: ID!) {
  notifications(site_user_id: $siteUserId) {
    id
    createdAt
    seen
    forUser {
      id
      firstName
      lastName
    }
    type {
      id
      name
      displayName
    }
    placedOrderLine {
      id
      readableId
      order {
        id
        readableId
      }
    }
    orderStatus
  }
}
    `;

/**
 * __useMyNotificationsQuery__
 *
 * To run a query within a React component, call `useMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationsQuery({
 *   variables: {
 *      siteUserId: // value for 'siteUserId'
 *   },
 * });
 */
export function useMyNotificationsQuery(baseOptions: Apollo.QueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
      }
export function useMyNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
        }
export type MyNotificationsQueryHookResult = ReturnType<typeof useMyNotificationsQuery>;
export type MyNotificationsLazyQueryHookResult = ReturnType<typeof useMyNotificationsLazyQuery>;
export type MyNotificationsQueryResult = Apollo.QueryResult<MyNotificationsQuery, MyNotificationsQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    firstName
    lastName
    email
    cell
    loginPermitted
    accountType {
      id
      name
      displayName
    }
    status {
      id
      name
      displayName
    }
    linkedBrewery {
      id
      name
      logoUrl
      state
      untappd
    }
    actingAs {
      id
      firstName
      lastName
      email
      cell
      linkedBrewery {
        id
        name
        logoUrl
        state
        untappd
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetVolumeAvailabilityOptionsDocument = gql`
    query GetVolumeAvailabilityOptions {
  appData {
    volumeAvailabilities {
      id
      name
      displayName
    }
  }
}
    `;

/**
 * __useGetVolumeAvailabilityOptionsQuery__
 *
 * To run a query within a React component, call `useGetVolumeAvailabilityOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVolumeAvailabilityOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVolumeAvailabilityOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVolumeAvailabilityOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetVolumeAvailabilityOptionsQuery, GetVolumeAvailabilityOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVolumeAvailabilityOptionsQuery, GetVolumeAvailabilityOptionsQueryVariables>(GetVolumeAvailabilityOptionsDocument, options);
      }
export function useGetVolumeAvailabilityOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVolumeAvailabilityOptionsQuery, GetVolumeAvailabilityOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVolumeAvailabilityOptionsQuery, GetVolumeAvailabilityOptionsQueryVariables>(GetVolumeAvailabilityOptionsDocument, options);
        }
export type GetVolumeAvailabilityOptionsQueryHookResult = ReturnType<typeof useGetVolumeAvailabilityOptionsQuery>;
export type GetVolumeAvailabilityOptionsLazyQueryHookResult = ReturnType<typeof useGetVolumeAvailabilityOptionsLazyQuery>;
export type GetVolumeAvailabilityOptionsQueryResult = Apollo.QueryResult<GetVolumeAvailabilityOptionsQuery, GetVolumeAvailabilityOptionsQueryVariables>;
export const GetPackingFormatsOptionsDocument = gql`
    query GetPackingFormatsOptions {
  appData {
    packingFormats {
      id
      name
      displayName
    }
  }
}
    `;

/**
 * __useGetPackingFormatsOptionsQuery__
 *
 * To run a query within a React component, call `useGetPackingFormatsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackingFormatsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackingFormatsOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPackingFormatsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetPackingFormatsOptionsQuery, GetPackingFormatsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackingFormatsOptionsQuery, GetPackingFormatsOptionsQueryVariables>(GetPackingFormatsOptionsDocument, options);
      }
export function useGetPackingFormatsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackingFormatsOptionsQuery, GetPackingFormatsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackingFormatsOptionsQuery, GetPackingFormatsOptionsQueryVariables>(GetPackingFormatsOptionsDocument, options);
        }
export type GetPackingFormatsOptionsQueryHookResult = ReturnType<typeof useGetPackingFormatsOptionsQuery>;
export type GetPackingFormatsOptionsLazyQueryHookResult = ReturnType<typeof useGetPackingFormatsOptionsLazyQuery>;
export type GetPackingFormatsOptionsQueryResult = Apollo.QueryResult<GetPackingFormatsOptionsQuery, GetPackingFormatsOptionsQueryVariables>;
export const GetValidSubscriptionDocument = gql`
    query GetValidSubscription($breweryId: ID!) {
  subscriptionByBrewery(breweryId: $breweryId) {
    id
    hasExpired
  }
}
    `;

/**
 * __useGetValidSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetValidSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidSubscriptionQuery({
 *   variables: {
 *      breweryId: // value for 'breweryId'
 *   },
 * });
 */
export function useGetValidSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetValidSubscriptionQuery, GetValidSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValidSubscriptionQuery, GetValidSubscriptionQueryVariables>(GetValidSubscriptionDocument, options);
      }
export function useGetValidSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValidSubscriptionQuery, GetValidSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValidSubscriptionQuery, GetValidSubscriptionQueryVariables>(GetValidSubscriptionDocument, options);
        }
export type GetValidSubscriptionQueryHookResult = ReturnType<typeof useGetValidSubscriptionQuery>;
export type GetValidSubscriptionLazyQueryHookResult = ReturnType<typeof useGetValidSubscriptionLazyQuery>;
export type GetValidSubscriptionQueryResult = Apollo.QueryResult<GetValidSubscriptionQuery, GetValidSubscriptionQueryVariables>;