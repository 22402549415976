import * as yup from 'yup';
import moment from 'moment';

import {
  useUpdateUserInformationMutation as useBaseUpdateUserInformationMutation,
  useGetSubscriptionQuery as useBaseGetSubscriptionQuery,
  UpdateUserInformationInput,
  useUpsertSubscriptionMutation as useBaseUpsertSubscriptionMutation,
  UpsertSubscriptionInput,
  useCancelSubscriptionMutation as useBaseCancelSubscriptionMutation
} from 'generated/apiTypes';
import {
  phoneNumberSchema,
  stateNameSchema
} from 'util/validation';

import * as refetchQueries from 'util/refetchQueries';



export interface ISubscriptionInfo {
  id: string;
  distributingStates: string[];
  createdOn: string;
  cancelAt: string;
  hasExpired: boolean;
  periodEndDate: string;
}

export interface ISubscriptionFormType {
  distributingStates: string[];
}


export function useGetSubscriptionQuery(breweryId: string) {
  const tuple = useBaseGetSubscriptionQuery({variables: {breweryId}});

  let cancelAtMoment = null;

  if (tuple.data?.subscriptionByBrewery?.cancelAt) {
    cancelAtMoment = moment(tuple.data?.subscriptionByBrewery?.cancelAt);
  }

  return {
    ...tuple,
    data: tuple.data?.subscriptionByBrewery ? {
      id: tuple.data?.subscriptionByBrewery?.id ?? '',
      distributingStates: tuple.data?.subscriptionByBrewery?.states ?? [],
      createdOn: moment(tuple.data?.subscriptionByBrewery?.createdOn).format('MM-DD-YYYY'),
      cancelAt: cancelAtMoment ? cancelAtMoment.format('MM-DD-YYYY') : null,
      hasExpired: tuple.data?.subscriptionByBrewery.hasExpired ?? false,
      periodEndDate: moment(tuple.data?.subscriptionByBrewery?.billingPeriodEnd).format('MM-DD-YYYY')
    } as ISubscriptionInfo : undefined
  };
}



export function useUpsertSubscriptionMutation(breweryId: string) {
  const [
    fn,
    tuple
  ] = useBaseUpsertSubscriptionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetSubscriptionRefetchQuery({breweryId})]
  });


  const fnWrappedMutation = (input: UpsertSubscriptionInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useCancelSubscriptionMutation(breweryId: string) {
  const [
    fn,
    tuple
  ] = useBaseCancelSubscriptionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetSubscriptionRefetchQuery({breweryId})]
  });


  const fnWrappedMutation = (id: string) => {
    return fn({variables: {id}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export function useUpdateUserInformationMutation() {
  const [
    fn,
    tuple
  ] = useBaseUpdateUserInformationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [refetchQueries.GetCurrentUserRefetchQuery({})]
  });


  const fnWrappedMutation = (input: UpdateUserInformationInput) => {
    return fn({variables: {input}});
  };

  return [
    fnWrappedMutation,
    tuple
  ] as const;
}



export const contactInfoFormSchema = yup.object({
  firstName: yup.string().required().label("First Name"),
  lastName: yup.string().required().label("Last Name"),
  emailAddress: yup.string().email().required().label("Email Address"),
  cellNumber: phoneNumberSchema.required().label("Cell Number")
}).defined();



export const subscriptionFormSchema = yup.object<ISubscriptionFormType>({distributingStates: yup.array(stateNameSchema.required()).min(1).required().label('Distributing states')}).defined();
