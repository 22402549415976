import React from 'react';
import _ from 'lodash';

import { Table } from 'components/shared/Table/Table';
import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { CreateAccountFormSection } from './CreateAccountFormSection/CreateAccountFormSection';
import { SectionTitle } from 'components/shared/SectionTitle/SectionTitle';
import { Button } from 'components/shared/Button/Button';
import { GetCurrentUserHook } from 'util/hooks';

import * as dataService from './dataService';



export const AccountsPage: React.FC = () => {
  const currentUserTuple = GetCurrentUserHook();
  const queryTuple = dataService.useGetSuperEmployeesQuery();
  const [fnInviteEmployee, inviteEmployeeTuple] = dataService.useInviteEmployeeMutation();
  const [fnDeleteUser, deleteUserTuple] = dataService.useDeleteUserMutation();

  const isLoading = _.some([
    currentUserTuple,
    queryTuple,
    inviteEmployeeTuple,
    deleteUserTuple
  ], tuple => tuple.loading);

  async function handleDeleteAccount(siteUserId: string, deleteSiteUserId: string) {
    await fnDeleteUser({
      siteUserId,
      deleteSiteUserId
    });
  }

  return (
    <PageTemplate loading={isLoading}>
      <SectionTitle>
        Employee Accounts
      </SectionTitle>

      <Table
        columnDefs={ [
          {
            label: 'First name',
            fnCellContent: d => d.firstName
          },
          {
            label: 'Last name',
            fnCellContent: d => d.lastName
          },
          {
            label: 'Email',
            fnCellContent: d => d.email
          },
          {
            label: 'Actions',
            width: '35px',
            fnCellContent: d => (
              <Button
                sizeStyle="small"
                colorStyle="secondary"
                onClick={() => handleDeleteAccount(currentUserTuple.data.currentUser.realUserId, d.id)}>
                Delete
              </Button>
            )
          }
        ]}
        data={queryTuple.data.employees} />

      {currentUserTuple.data.currentUser.hasFullSuperAccess && (
        <CreateAccountFormSection onInviteNewAccount={(email: string, firstName: string, lastName: string) => {
          fnInviteEmployee(
            {
              emailAddress: email,
              firstName,
              lastName,
              accountType: 'superEmployee'
            }
          );
        }} />
      )}

    </PageTemplate>
  );
};
