import React from 'react';
import _ from 'lodash';

import { PageTemplate } from 'components/shared/pages/PageTemplate/PageTemplate';
import { Table } from 'components/shared/Table/Table';
import { TableHeader } from 'components/shared/Table/TableHeader/TableHeader';
import { TableTitle } from 'components/shared/Table/TableTitle/TableTitle';
import { TableActions } from 'components/shared/Table/TableActions/TableActions';
import { Button } from 'components/shared/Button/Button';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { GetCurrentUserHook } from 'util/hooks';

import * as dataService from './dataService';



export const BreweriesPage: React.FC = () => {
  const currentUserTuple = GetCurrentUserHook();
  const queryTuple = dataService.useApprovedBreweriesPageQuery();
  const [fnToggleLoginPermitted] = dataService.useToggleLoginPermittedMutation();
  const [fnActAsBrewery] = dataService.useActAsBreweryOwnerMutation();
  const [searchText, setSearchText] = React.useState('');

  function handleToggleLoginStatus(brewery: dataService.IBreweryAccount) {
    fnToggleLoginPermitted(brewery.ownerId);
  }

  async function handleActAsBrewery(brewery: dataService.IBreweryAccount) {
    // TODO: go to brewery page under selected credential
    await fnActAsBrewery({
      siteUserId: currentUserTuple.data.currentUser.realUserId,
      breweryOwnerSiteUserId: brewery.ownerId
    });
    window.location.reload();
  }

  const breweries = React.useMemo(() =>
    _.filter(queryTuple.data.approvedBreweries, brewery => searchText ?
      _.includes(_.toLower(brewery.breweryName), _.toLower(searchText)) : true),
  [queryTuple.data.approvedBreweries, searchText]);

  return (
    <PageTemplate>
      <TableHeader>
        <TableTitle>
          Breweries
        </TableTitle>

        <TextInput
          placeholder="Filter by Brewery Name"
          onChangeValue={setSearchText} />
      </TableHeader>

      <Table
        data={breweries}
        columnDefs={[
          {
            label: 'Name',
            fnCellContent: d => `${d.ownerFirstName} ${d.ownerLastName}`
          },
          {
            label: 'Email',
            fnCellContent: d => d.ownerEmail
          },
          {
            label: 'Cell Number',
            fnCellContent: d => d.ownerCell
          },
          {
            label: 'Brewery Name',
            fnCellContent: d => d.breweryName
          },
          {
            label: 'Brewery State',
            fnCellContent: d => d.breweryState
          },
          {
            label: 'Actions',
            width: '120px',
            fnCellContent: d => (
              <div>
                <TableActions>
                  <Button
                    sizeStyle="small"
                    colorStyle="secondary"
                    onClick={() => handleActAsBrewery(d)}>
                    Act As Brewery Owner
                  </Button>
                </TableActions>

                <TableActions>
                  <Button
                    sizeStyle="small"
                    colorStyle="secondary"
                    onClick={() => handleToggleLoginStatus(d)}>
                    {d.loginPermitted && (<>Disable Brewery</>)}
                    {!d.loginPermitted && (<>Enable Brewery</>)}
                  </Button>
                </TableActions>
              </div>
            )
          }
        ]} />
    </PageTemplate>
  );
};
