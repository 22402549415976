import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { IGenericInputRef } from '../IGenericInputRef';

import styles from './TextAreaInput.module.css';



export type ITextAreaInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  value?: string;
  label?: string;
  disableValidateOnChange?: boolean;

  onChangeValue: (value: string) => void;
  /**
   * Returns an error message to display to the user based on the input.
   * If the input is valid, returns the empty string.
   */
  fnValidate?: (value: string, required?: boolean) => string;
}

/**
 * Intended to be a generic text field control that is simply used as a partial
 * implementation of other controls that require a text input control.
 */
export const TextAreaInput = React.forwardRef<IGenericInputRef, ITextAreaInputProps>((props, ref) => {
  const {
    value, fnValidate, required
  } = props;
  const refInput = React.useRef<HTMLTextAreaElement>(null);

  const validate = React.useCallback((immediateValue?: string) => {
    const elInput = refInput.current;
    if (!elInput) {
      return false;
    }

    const validatingValue = !_.isNil(immediateValue) ?
      immediateValue.trim() :
      !_.isNil(value) ?
        value.trim() :
        '';

    const errorMessage = fnValidate ? fnValidate(validatingValue, required) : "";
    elInput.setCustomValidity(errorMessage);
    return !errorMessage;
  }, [
    refInput,
    value,
    fnValidate,
    required
  ]);

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = event.currentTarget.value;

    if (props.onChange) {
      props.onChange(event);
    }
    props.onChangeValue(value);
  }

  React.useImperativeHandle(ref, () => ({validate}));

  React.useEffect(() => {
    if (!_.isEmpty(value)) {
      validate();
    }
  }, [
    value,
    validate
  ]);

  return (
    <div className={styles.container}>
      {props.label && (
        <label className={styles.label}>
          {props.label}
        </label>
      )}

      <div className={styles.inputContainer}>
        <textarea
          {..._.omit(props, [
            'onChangeValue',
            'fnValidate',
            'prefix',
            'suffix'
          ])}
          ref={refInput}
          className={classnames(styles.input, props.className)}
          onChange={handleChange} />
      </div>
    </div>
  );
});



TextAreaInput.defaultProps = {};
