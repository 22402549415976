import React from 'react';
import _ from 'lodash';
import * as yup from 'yup';

import { routes } from 'routes';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { PasswordInput } from 'components/shared/form/TextInput/PasswordInput';
import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';
import { Button } from 'components/shared/Button/Button';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import { confirmPasswordSchema } from 'util/validation';
import { useAlert } from 'util/hooks';

import styles from './ResetPasswordPage.module.css';



export const ResetPasswordPage: React.FC = () => {
  const pathParams = routes.onboarding.resetPassword.usePathParams();
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, setAlert, clearAlert
  } = useAlert();
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  function handleValidateForm() {
    try {
      __schema.validateSync({
        password,
        confirmPassword
      });
      clearAlert();
      return true;
    }
    catch(e) {
      setAlert(e.message, "error");
      return false;
    }
  }

  function handleResetPassword() {
    console.debug({
      data: {
        password,
        confirmPassword
      },
      requestId: pathParams.requestId
    });
  }

  return (
    <OnboardingPageTemplate>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Welcome to the
            <br />
            BevNomad App
          </h1>

          <span className={styles.subtitle}>
            RESET PASSWORD
          </span>
        </div>

        <Form
          ref={refForm}
          fnValidateForm={handleValidateForm}
          extraClassName={styles.form}
          onSuccessfulSubmit={handleResetPassword}>
          <PasswordInput
            label="PASSWORD"
            value={password}
            onChangeValue={setPassword}
            ref={_.partial(Form.captureElement, refForm, 'password')} />

          <PasswordInput
            label="CONFIRM PASSWORD"
            value={confirmPassword}
            onChangeValue={setConfirmPassword}
            ref={_.partial(Form.captureElement, refForm, 'confirmPassword')} />

          <Button
            className={styles.formButton}
            type="submit">
            Change password
          </Button>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </OnboardingPageTemplate>
  );
};



const __schema = yup.object({
  password: yup.string().required().label('Password'),
  confirmPassword: confirmPasswordSchema(yup.ref('password')).required().label("Confirm Password")
}).defined();
