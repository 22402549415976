import React from 'react';

import { useHistory } from 'react-router';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { OnboardingPageTemplate } from 'components/shared/pages/OnboardingPageTemplate/OnboardingPageTemplate';
import { Icon } from 'components/shared/Icon/Icon';
import { Button } from 'components/shared/Button/Button';
import { GeneralFormSection } from './formSections/GeneralFormSection/GeneralFormSection';
import { AccountFormSection } from './formSections/AccountFormSection/AccountFormSection';
import { routes } from 'routes';
import { validator } from 'util/validation';
import { useAlert } from 'util/hooks';
import { AlertModal } from 'components/shared/modals/AlertModal/AlertModal';
import * as dataService from './dataService';
import * as authService from 'services/authService';

import styles from './CreateAccountPage.module.css';




export const CreateAccountPage: React.FC = () => {
  const [fnRegisterBrewery, registerBreweryMutationTuple] = dataService.useRegisterBreweryMutation();

  const history = useHistory();
  const refForm = React.useRef<Form>(null);
  const {
    AlertModalProps, alertOnError
  } = useAlert();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [untappd, setUntappd] = React.useState('');
  const [cellNumber, setCellNumber] = React.useState('');
  const [breweryName, setBreweryName] = React.useState('');
  const [breweryState, setBreweryState] = React.useState('');

  function fnValidateForm() {
    try {
      return !!validator(dataService.createAccountFormSchema, {
        firstName,
        lastName,
        emailAddress,
        cellNumber,
        breweryName,
        breweryState,
        password,
        confirmPassword,
        untappd
      });

    } catch (err) {
      alertOnError(err);
      return false;
    }
  }

  async function handleCreateAccount() {
    try {
      await fnRegisterBrewery({
        firstName,
        lastName,
        emailAddress,
        cellNumber,
        breweryName,
        breweryState,
        password,
        confirmPassword,
        untappd
      });

      const userCredential = await authService.logInWithEmail(emailAddress, password);

      if (userCredential.user) {
        history.push(routes.home.path());
      }
    }
    catch (err) {
      alertOnError(err);
    }

  }

  return (
    <OnboardingPageTemplate loading={registerBreweryMutationTuple.loading}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Icon
            size={43}
            type="boiler"
            className={styles.headerIcon} />

          <h1 className={styles.title}>
            Create Your Account
          </h1>

          <span className={styles.subtitle}>
            All fields are required except those marked with an Asterisk (*)
          </span>
        </div>

        <Form
          ref={refForm}
          fnValidateForm={fnValidateForm}
          extraClassName={styles.form}
          onSuccessfulSubmit={handleCreateAccount}>
          <GeneralFormSection
            firstName={firstName}
            lastName={lastName}
            emailAddress={emailAddress}
            cellNumber={cellNumber}
            breweryName={breweryName}
            breweryState={breweryState}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmailAddress={setEmailAddress}
            setCellNumber={setCellNumber}
            setBreweryName={setBreweryName}
            setBreweryState={setBreweryState}
            refForm={refForm} />

          <AccountFormSection
            refForm={refForm}
            password={password}
            confirmPassword={confirmPassword}
            untappd={untappd}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            setUntappd={setUntappd} />

          <div className={styles.buttons}>
            <div className={styles.backButton}>
              <Button
                type='button'
                colorStyle='darken'
                onClick={() => history.goBack()}>
                Back
              </Button>
            </div>

            <div className={styles.submitButton}>
              <Button type="submit">
                Create Account
              </Button>
            </div>
          </div>
        </Form>
      </div>

      <AlertModal {...AlertModalProps} />
    </OnboardingPageTemplate>
  );
};
