import React from 'react';

import {
  Route,
  Redirect,
  RouteProps
} from 'react-router';
import {
  routes,
  superAdminRoutes
} from 'routes';

import * as authService from 'services/authService';
import { GetCurrentUserHook } from 'util/hooks';



export const AuthProtectedRoute: React.FC<RouteProps> = props => {
  const currentUser = authService.getFirebaseUser();
  const queryTuple = GetCurrentUserHook();
  const accountType = queryTuple.data.currentUser.accountType;

  if (queryTuple.loading) {
    return <div>loading...</div>;
  }
  if (currentUser) {
    if (!queryTuple.data.currentUser.loginPermitted) {
      return <Redirect to={routes.onboarding.accountDisabled.path()} />;
    }

    if (queryTuple.data.currentUser.isActing || accountType === 'breweryOwner' || accountType === 'breweryEmployee') {
      return <Route {...props} />;
    }
    else if (accountType === 'superAdmin' || accountType === 'superEmployee') {
      return <Redirect to={superAdminRoutes.accounts.path()} />;
    }

    return <Redirect to={routes.onboarding.accountTypeNotPermitted.path()} />;
  }
  return <Redirect to={routes.onboarding.signIn.path()} />;
};



export const SuperAdminProtectedRoute: React.FC<RouteProps> = props => {
  const currentUser = authService.getFirebaseUser();
  const queryTuple = GetCurrentUserHook();

  return (currentUser && queryTuple.data.currentUser.hasSuperAccess) ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.onboarding.signIn.path()} />
  );
};



export const AnonymousRoute: React.FC<RouteProps> = props => {
  return authService.getFirebaseUser() ? (
    <Redirect to={routes.home.path()} />
  ) : (
    <Route {...props} />
  );
};


export const LoggedInUserOnboardingRoute: React.FC<RouteProps> = props => {
  return authService.getFirebaseUser() ? (
    <Route {...props} />
  ) : (
    <Redirect to={routes.onboarding.signIn.path()} />
  );
};
