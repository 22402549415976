import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import {
  useGetCurrentUserQuery,
  useGetAllDistributorsQuery,
  useGetVolumeAvailabilityOptionsQuery,
  useGetPackingFormatsOptionsQuery,
  useGetValidSubscriptionQuery
} from 'generated/apiTypes';

import { IAlertContentType } from 'components/shared/modals/AlertModal/AlertModal';



export function GetCurrentUserHook() {
  const tuple = useGetCurrentUserQuery({});

  const currentUser = tuple.data?.currentUser;

  return {
    ...tuple,
    data: {
      currentUser: {
        realUserId: currentUser?.id ?? '',
        accountType: currentUser?.accountType.name ?? 'Unknown',
        hasSuperAccess: currentUser?.accountType.name === 'superAdmin' || currentUser?.accountType.name === 'superEmployee',
        hasFullSuperAccess: currentUser?.accountType.name === 'superAdmin',
        isActing: !_.isEmpty(currentUser?.actingAs?.id),
        actingAs: {
          id: currentUser?.actingAs?.id,
          firstName: currentUser?.actingAs?.firstName,
          lastName: currentUser?.actingAs?.lastName,
          breweryId: currentUser?.actingAs?.linkedBrewery?.id,
          breweryName: currentUser?.actingAs?.linkedBrewery?.name,
          breweryLogo: currentUser?.actingAs?.linkedBrewery?.logoUrl,
          breweryState: currentUser?.actingAs?.linkedBrewery?.state,
          cellNumber: currentUser?.actingAs?.cell,
          emailAddress: currentUser?.actingAs?.email
        },
        isRetailer: currentUser?.accountType.name === 'retailer',
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
        cellNumber: currentUser?.cell ?? '',
        emailAddress: currentUser?.email ?? '',
        status: currentUser?.status.name ?? 'unknown',
        breweryId: currentUser?.linkedBrewery?.id ?? '',
        breweryName: currentUser?.linkedBrewery?.name ?? '',
        breweryLogo: currentUser?.linkedBrewery?.logoUrl ?? '',
        breweryState: currentUser?.linkedBrewery?.state ?? '',
        idForQueries: currentUser?.actingAs?.id ?? currentUser?.id ?? '',
        breweryIdForQueries: currentUser?.actingAs?.linkedBrewery?.id ?? currentUser?.linkedBrewery?.id ?? '',
        loginPermitted: currentUser?.loginPermitted ?? false,
        untappd:currentUser?.actingAs?.linkedBrewery?.untappd ?? currentUser?.linkedBrewery?.untappd ?? ''
      }
    }
  };
}



export function useAllDistributorsQuery(breweryId: string | undefined) {
  const tuple =  useGetAllDistributorsQuery({
    variables: { exceptBreweryId: breweryId },
    fetchPolicy: 'network-only'
  });

  return {
    ...tuple,
    data: {
      availableDistributorInfo: _.map(tuple?.data?.appData.allDistributors, distro => {
        return {
          id: distro.id,
          name: distro.name,
          contactEmails: distro.contactEmails,
          distributionStates: distro.distributionStates,
          isASelfDistributor: distro.isASelfDistributor
        };
      }) ?? []
    }
  };
}



export function GetVolumeAvailabilityOptionsHook() {
  const tuple = useGetVolumeAvailabilityOptionsQuery();

  return {
    ...tuple,
    data: {
      volumeAvailabilities: _.map(tuple.data?.appData.volumeAvailabilities, vol => {
        return {
          value: vol.name,
          label: vol.displayName
        };
      }) ?? []
    }
  };
}



export function GetPackingFormatsOptionsHook() {
  const tuple = useGetPackingFormatsOptionsQuery();

  return {
    ...tuple,
    data: {
      packingFormats: _.map(tuple.data?.appData.packingFormats, vol => {
        return {
          value: vol.name,
          label: vol.displayName
        };
      }) ?? []
    }
  };
}



export function GetValidSubscriptionHook(breweryId: string) {
  const tuple = useGetValidSubscriptionQuery({variables: {breweryId}});

  let isValid = false;

  if (tuple.data?.subscriptionByBrewery) {
    isValid = !tuple.data?.subscriptionByBrewery.hasExpired;
  }


  return {
    ...tuple,
    isValidSubscription: isValid
  };
}



export function useAlert() {
  const [type, setType] = React.useState<IAlertContentType>('success');
  const [message, setMessage] = React.useState<React.ReactNode>(null);

  function setAlert(message: React.ReactNode, type?: IAlertContentType) {
    setMessage(message);
    setType(type ?? 'success');
  }

  function alertOnError(validationError?: yup.ValidationError) {
    if (!_.isNil(validationError) && 'errors' in validationError) {
      setType('error');
      setMessage(_.map(validationError.errors, (error, index) => (<p key={index}>{error}</p>)));
    } else {
      setMessage(null);
    }
  }

  function clearAlert() {
    setMessage(null);
  }

  return {
    AlertModalProps: {
      type,
      children: React.createElement(React.Fragment, {}, message),
      isOpen: !_.isEmpty(message),
      onRequestClose: clearAlert
    },
    clearAlert,
    setAlert,
    alertOnError
  };
}



export function useAnimationFrame(callback: (deltaTime: number) => void, dependencies: React.DependencyList) {
  // credits: https://css-tricks.com/using-requestanimationframe-with-react-hooks/
  const requestRef = React.useRef<number>();
  const previousTimeRef = React.useRef<number>();

  const animate = React.useCallback((time: number) => {
    if (!_.isNil(previousTimeRef.current)) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }, [requestRef, previousTimeRef, callback]);

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, [dependencies, animate]);
}



export function useDebouncedCallback<T extends (...args: any[]) => any>(callback: T, waitTime: number, dependency: React.DependencyList) {
  return React.useCallback(_.debounce(callback, waitTime), dependency);
}



export function useEffectUntil(callback: () => void, dependencies: React.DependencyList, until: () => boolean) {
  const untilRef = React.useRef(false);

  return React.useEffect(() => {
    if (!untilRef.current) {
      callback();
    }

    untilRef.current = until();
  }, [...dependencies, untilRef]);
}
