import React from 'react';
import _ from 'lodash';

import {
  IModalTemplateProps,
  ModalTemplate
} from 'components/shared/templates/ModalTemplate/ModalTemplate';
import {
  availableRetailerChainTypes,
  availableRetailerTypes
} from 'util/enums';

import { RetailerChainStatusName } from 'generated/apiTypes';
import { Form } from 'metisoft-react-components/dist/components/forms/Form';
import { Select } from 'components/shared/form/Select/Select';
import { PhoneNumberInput } from 'components/shared/form/TextInput/PhoneNumberInput';
import { Button } from 'components/shared/Button/Button';
import { TextInput } from 'components/shared/form/TextInput/TextInput';
import { validator } from 'util/validation';
import { useAlert } from 'util/hooks';
import { getStateNameOptions } from 'util/stateNames';
import { UrlInput } from 'components/shared/form/TextInput/UrlInput';
import * as optionsUtil from 'util/options';
import * as authService from 'services/authService';
import * as dataService from '../dataService';

import styles from './EditAccountModal.module.css';



export interface IEditAccountModalProps extends IModalTemplateProps {
  emailAddress: string | null;
  retailer: dataService.IRetailerUpdate | null;
  onUpdateRetailer: (input: dataService.IRetailerUpdate) => void;
}

export const EditAccountModal: React.FC<IEditAccountModalProps> = props => {
  const refForm = React.useRef<Form>(null);
  const { alertOnError } = useAlert();
  const [formValue, setFormValue] = React.useState<dataService.IRetailerUpdate>({
    siteUserId: '',
    firstName: '',
    lastName: '',
    cellNumber: '',
    retailerType: 'unknown',
    retailerChainStatus: 'unknown',
    chainInformation: {
      name: '',
      purchaseStates:[],
      website: ''
    },
    individualInformation: {
      locationName: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      website: ''
    }
  });

  function handleChangeValue(value: Partial<dataService.IRetailerUpdate>) {
    setFormValue({
      ...formValue,
      ...value
    });
  }

  function handleChangeRetailerInformation(retailerChain: RetailerChainStatusName, value: Partial<dataService.IRetailerUpdate["chainInformation"]> | Partial<dataService.IRetailerUpdate["individualInformation"]>) {
    if (_.isNil(value) || _.isEmpty(value)) {
      return;
    }
    if (retailerChain === 'chain') {
      setFormValue({
        ...formValue,
        chainInformation: {
          ...formValue.chainInformation,
          ...value
        } as dataService.IRetailerUpdate["chainInformation"]
      });
    } else if (retailerChain === 'individual') {
      setFormValue({
        ...formValue,
        individualInformation: {
          ...formValue.individualInformation,
          ...value
        } as dataService.IRetailerUpdate["individualInformation"]
      });
    }
  }

  function handleFormValidate() {
    try {
      validator(dataService.retailerSchema, formValue);
      return true;
    } catch (err) {
      if ('errors' in err) {
        alertOnError(err.errors);
      }
      return false;
    }
  }

  function handleSubmit() {
    console.debug({data: formValue});

    props.onUpdateRetailer(formValue);

    if (props.onRequestClose) {
      props.onRequestClose();
    }
  }

  async function handleResetPassword() {
    try {
      if (props.emailAddress) {
        await authService.sendPasswordResetEmail(props?.emailAddress);
      }
    } catch (err) {
      // NOTE: no error should be shown here for security reason
    }

    if (props.onRequestClose) {
      props.onRequestClose();
    }
  }

  React.useEffect(() => {
    if (!_.isNil(props.retailer)) {
      setFormValue(originalValue => ({
        ...originalValue,
        ...props.retailer
      }));
    }
  }, [setFormValue, props.retailer]);

  return (
    <ModalTemplate
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      modalTitle="Edit Account">
      <Form
        ref={refForm}
        fnValidateForm={handleFormValidate}
        onSuccessfulSubmit={handleSubmit}>
        <TextInput
          required
          ref={_.partial(Form.captureElement, refForm, 'firstName')}
          label="FIRST NAME"
          value={formValue.firstName}
          onChangeValue={firstName => handleChangeValue({firstName})} />

        <TextInput
          required
          ref={_.partial(Form.captureElement, refForm, 'lastName')}
          label="LAST NAME"
          value={formValue.lastName}
          onChangeValue={lastName => handleChangeValue({lastName})} />


        <PhoneNumberInput
          value={formValue.cellNumber}
          required
          onChangeValue={cellNumber => handleChangeValue({cellNumber})}
          ref={_.partial(Form.captureElement, refForm, 'cellNumber')}
          label="CELL NUMBER" />

        <Select
          options={availableRetailerTypes}
          value={optionsUtil.getOptionFromValue(availableRetailerTypes, formValue.retailerType)}
          onChange={option => handleChangeValue({retailerType: optionsUtil.getValueFromOption(option)})}
          label="RETAILER TYPE" />

        <Select
          options={availableRetailerChainTypes}
          value={optionsUtil.getOptionFromValue(availableRetailerChainTypes, formValue.retailerChainStatus)}
          onChange={option => handleChangeValue({retailerChainStatus: optionsUtil.getValueFromOption(option)})}
          label="CHAIN STATUS" />

        {formValue.retailerChainStatus === 'chain' && (
          <>
            <TextInput
              required
              ref={_.partial(Form.captureElement, refForm, 'chainInformation.name')}
              label="CHAIN NAME"
              value={formValue.chainInformation?.name ?? ''}
              onChangeValue={name => handleChangeRetailerInformation('chain', {name})} />

            <Select
              isMulti
              label="STATE"
              value={optionsUtil.getOptionsFromValues(getStateNameOptions(), formValue.chainInformation?.purchaseStates ?? []) as any}
              options={getStateNameOptions()}
              onChange={options => handleChangeRetailerInformation('chain', { purchaseStates: optionsUtil.getValuesFromOptions(options) })} />

            <UrlInput
              required
              ref={_.partial(Form.captureElement, refForm, 'website')}
              label="WEBSITE"
              value={formValue.chainInformation?.website ?? ''}
              onChangeValue={website => handleChangeRetailerInformation('chain', {website})} />
          </>
        )}

        {formValue.retailerChainStatus === 'individual' && (
          <>
            <TextInput
              required
              ref={_.partial(Form.captureElement, refForm, 'individualInformation.locationName')}
              label="LOCATION NAME"
              value={formValue.individualInformation?.locationName ?? ''}
              onChangeValue={locationName => handleChangeRetailerInformation('individual', {locationName})} />

            <TextInput
              required
              ref={_.partial(Form.captureElement, refForm, 'individualInformation.street')}
              label="STREET ADDRESS"
              value={formValue.individualInformation?.street ?? ''}
              onChangeValue={street => handleChangeRetailerInformation('individual', {street})} />

            <TextInput
              required
              ref={_.partial(Form.captureElement, refForm, 'individualInformation.city')}
              label="CITY"
              value={formValue.individualInformation?.city ?? ''}
              onChangeValue={city => handleChangeRetailerInformation('individual', {city})} />

            <Select
              label="STATE"
              value={optionsUtil.getOptionFromValue(getStateNameOptions(), formValue.individualInformation?.state)}
              options={getStateNameOptions()}
              onChange={option => handleChangeRetailerInformation('individual', { state: optionsUtil.getValueFromOption(option) })} />

            <TextInput
              required
              ref={_.partial(Form.captureElement, refForm, 'individualInformation.zip')}
              label="ZIPCODE"
              value={formValue.individualInformation?.zip ?? ''}
              onChangeValue={zip => handleChangeRetailerInformation('individual', {zip})} />

            <UrlInput
              required
              ref={_.partial(Form.captureElement, refForm, 'website')}
              label="WEBSITE"
              value={formValue.individualInformation?.website ?? ''}
              onChangeValue={website => handleChangeRetailerInformation('individual', {website})} />
          </>
        )}

        <div className={styles.buttons}>
          <Button
            colorStyle="form"
            onClick={handleResetPassword}>
            Send reset password email
          </Button>

          <Button type="submit">
            Save
          </Button>
        </div>
      </Form>
    </ModalTemplate>
  );
};
